import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Layout } from "antd";
import { Urls } from "../../config/const";
import Header from "./Header";

import { layout } from "../../config/testIds";

import styled from "styled-components";

import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import SignIn from "../Authentication/SignIn";
import MainTabs from "../MainTabs/MainTabs";
import InfoAlwaysVisibleTop from "../MainTabs/InfoAlwaysVisibleTop";

import imgBg from "../../images/layout/dekra-bg.jpg";
import Notifications from "./Notifications";

const LayoutStyled = styled(Layout)`
	height: 100vh;
	z-index: 1;

	.ant-layout {
		background: #fff;
	}
`;

const ContentStyled = styled(Layout.Content)`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
`;

const InnerContent = styled.div`
	minwidth: 100vw;
	overflow: auto;
	height: calc(100vh - 80px);
	background-image: url(${imgBg});
	background-size: cover;
`;

type Props = {
	children?: JSX.Element | JSX.Element[];
};

const AppLayout: React.FC<Props> = (props: Props) => {
	const { children } = props;

	return (
		<LayoutStyled data-testid={layout.container}>
			<AuthenticatedTemplate>
				<BrowserRouter>
					<Layout>
						<Header />
						<InnerContent>
							<ContentStyled>
								<Routes>
									<Route path={Urls.home} element={<MainTabs />} />
								</Routes>

								<Notifications />
								
								{children}
							</ContentStyled>
						</InnerContent>
					</Layout>
				</BrowserRouter>
			</AuthenticatedTemplate>
			<UnauthenticatedTemplate>
				<SignIn />
			</UnauthenticatedTemplate>
		</LayoutStyled>
	);
};

export default AppLayout;
