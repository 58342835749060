import styled from "styled-components";
import dekra from "./dekra";

export const CellWrapper = styled.div`
    padding: 0;
    // padding: 1em 0;
    &.blocked-practice-row {
        color: #888;
    }
`;

export const CellWrapperWithCursorPointer = styled.div`
    cursor: pointer;
`;

export const ClickableCellWrapper = styled(CellWrapper)`
    cursor: pointer;
    color: ${dekra.primaryColor};
    font-weight: bold;
`;
