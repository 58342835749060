import { useEffect, useState } from "react";
import AssignmentMapFilter, {
  AssignmentMapFilterType,
} from "../AssignmentMapFilter/AssignmentMapFilter";
import List from "../Lists/List/List";
import CustomMap, { CameraOption } from "../Maps/CustomMap";
import { AssignmentMapWrapper } from "../Maps/models";
import {
  AssignmentMapData,
  useLazyGetAssignmentMapQuery,
} from "../../../../redux/apiSpecifications/apiCrud";
import { RootState } from "../../../../redux/store";
import { useSelector } from "react-redux";
import { Tenant } from "../../../../redux/apiSpecifications/apiFesf";
import {
  LoadingInTabWrapper,
  RefreshInTabWrapper,
} from "../../../../style/DashbordWidgetWrappers";
import { IconLoading } from "../../../../config/icons";

interface IPrincipalViewProps {
  filter: AssignmentMapFilterType;
  onSelectAppraiser: (appraiserId: number, idZone: number) => void;
  onSelectPractice: (practiceId: number) => void;
  onChangeFilter: (value: any, field: string) => void;
  onResetFilter: () => void;
}

const PrincipalView = ({
  filter,
  onSelectAppraiser,
  onSelectPractice,
  onChangeFilter,
  onResetFilter,
}: IPrincipalViewProps) => {
  const { authorization, selectedTenants, activeRole } = useSelector(
    (state: RootState) => state.user
  );

  const [assignmentMapData, setAssignmentMapData] =
    useState<AssignmentMapData | null>();

  const [loadingStatus, setLoadingStatus] = useState<
    "idle" | "loading" | "refreshing" | "load-in-progress"
  >("idle");

  const [positions, setPositions] = useState<number[][] | undefined>(undefined);

  const [getAssignmentMap] = useLazyGetAssignmentMapQuery();

  useEffect(() => {
    (async () => {
      await loadData(filter);
    })();
  }, [filter]);

  const loadData = async (filter: AssignmentMapFilterType) => {
    if (!selectedTenants) return;

    setLoadingStatus("refreshing");

    const response = await getAssignmentMap({
      authorization: authorization,
      tenants: selectedTenants.map((t: Tenant) => t.id!),
      practiceStatus:
        filter.practiceStatus === "" ? undefined : filter.practiceStatus,
      isUrgent: filter.isUrgent,
      appraiserType:
        filter.appraiserType === "" ? undefined : filter.appraiserType,
      saturationCluster:
        filter.saturationCluster === "" ? undefined : filter.saturationCluster,
    });

    const data = response.data as AssignmentMapData;

    if (data) {
      setAssignmentMapData(data);
    } else {
      // error to do....
    }

    setLoadingStatus("idle");
  };

  const handleSelectPractice = (
    idPractice: number,
    latitude: number,
    longitude: number
  ) => {
    console.info(idPractice, latitude, longitude);
    setPositions([[longitude, latitude]]);
    //setCameraOptions({ zoom: 10, center: [longitude, latitude] });
  };

  const handleSelectAppraiser = (
    idAppraiser: number,
   positions: number[][],
  ) => {
    console.info(idAppraiser, positions);
    setPositions(positions);
  };



  return (
    <div>
      {loadingStatus === "loading" && (
        <LoadingInTabWrapper>
          <div className="box">{IconLoading}</div>
        </LoadingInTabWrapper>
      )}

      {(loadingStatus === "refreshing" ||
        loadingStatus === "load-in-progress" ||
        loadingStatus === "idle") && (
        <>
          <AssignmentMapWrapper>
            <div className="col-left">
              <CustomMap
                appraisers={assignmentMapData?.appraisers}
                practices={assignmentMapData?.practices}
                positions={positions}
                onSelectAppraiser={onSelectAppraiser}
                onSelectPractice={onSelectPractice}
              />
            </div>

            <div className="col-right">
              <AssignmentMapFilter
                filter={filter}
                onChange={onChangeFilter}
                onResetFilter={onResetFilter}
              />

              <List
                appraisersList={assignmentMapData?.appraisers}
                practicesList={assignmentMapData?.practices}
                onSelectAppraiser={handleSelectAppraiser}
                onSelectPractice={handleSelectPractice}
              />
            </div>
          </AssignmentMapWrapper>

          {(loadingStatus === "refreshing" ||
            loadingStatus === "load-in-progress") && (
            <RefreshInTabWrapper>
              <div className="box">{IconLoading}</div>
            </RefreshInTabWrapper>
          )}
        </>
      )}
    </div>
  );
};
export default PrincipalView;
