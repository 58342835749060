import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { baseUrlDoc } from "../../../config/const";
import { RootState } from "../../store";
import { checkToken } from "./baseApi";


const baseQuery = fetchBaseQuery({
  baseUrl: baseUrlDoc,
  prepareHeaders: (headers, { getState }) => {
      const { authorization } = (getState() as RootState)?.user;

      if (authorization !== "") {
          headers.set("Authorization", authorization);
      }

      return headers;
  },
});

const baseQueryWithReauth: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args, api, extraOptions) => {
    await checkToken(api);

    let result = await baseQuery(args, api, extraOptions);

    return result;
};

export const baseSplitApiDocs = createApi({
  reducerPath: "apiDocs",
  baseQuery: baseQueryWithReauth, // fetchBaseQuery({ baseUrl: baseUrlDoc }),
  endpoints: () => ({}),
});
