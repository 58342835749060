import { baseSplitApiDocs as api } from "./config/baseApiDocs";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    uploadAttachment: build.mutation<
      UploadAttachmentApiResponse,
      UploadAttachmentApiArg
    >({
      query: (queryArg) => ({
        url: `/attachments`,
        method: "POST",
        body: queryArg.documentFiles,
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    downloadAttachmentById: build.query<
      DownloadAttachmentByIdApiResponse,
      DownloadAttachmentByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/attachments/${queryArg.id}`,
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    deleteAttachment: build.mutation<
      DeleteAttachmentApiResponse,
      DeleteAttachmentApiArg
    >({
      query: (queryArg) => ({
        url: `/attachments/${queryArg.id}`,
        method: "DELETE",
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    downloadAttachmentByDocumentCode: build.query<
      DownloadAttachmentByDocumentCodeApiResponse,
      DownloadAttachmentByDocumentCodeApiArg
    >({
      query: (queryArg) => ({
        url: `/all-attachments/${queryArg.documentCode}`,
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    getZipFileList: build.query<
      GetZipFileListApiResponse,
      GetZipFileListApiArg
    >({
      query: (queryArg) => ({
        url: `/unzip/file-list/${queryArg.documentCode}`,
        params: { "file-content-filter": queryArg["file-content-filter"] },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as apiDocs };
export type UploadAttachmentApiResponse =
  /** status 201 The new item is created */ {
    id?: number;
    message?: string;
  };
export type UploadAttachmentApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Alphanumeric Id of each document resource (Unique identifier for all TPA modules) */
  documentCode: string;
  /** Upload a file related to a document */
  documentFiles: DocumentFiles;
};
export type DownloadAttachmentByIdApiResponse = /** status 200 undefined */
  | undefined
  | /** status 204 The request was correct but the item does not exists */ {
      message?: string;
    };
export type DownloadAttachmentByIdApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the resource */
  id: number;
};
export type DeleteAttachmentApiResponse =
  /** status 200 The item is deleted */ {
    id?: number;
    message?: string;
  };
export type DeleteAttachmentApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the resource */
  id: number;
};
export type DownloadAttachmentByDocumentCodeApiResponse =
  /** status 200 undefined */
    | undefined
    | /** status 204 The request was correct but the item does not exists */ {
        message?: string;
      };
export type DownloadAttachmentByDocumentCodeApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Alphanumeric Id of each document resource (Unique identifier for all TPA modules) */
  documentCode: string;
};
export type GetZipFileListApiResponse = /** status 200 undefined */
  | undefined
  | /** status 204 The request was correct but the item does not exists */ {
      message?: string;
    };
export type GetZipFileListApiArg = {
  /** Alphanumeric Id of each document resource (Unique identifier for all TPA modules) */
  documentCode: string;
  /** wildcard string to filter out file content */
  "file-content-filter": string;
};
export type Schema = {
  errorCode?: string;
  errorMessage?: string;
};
export type AttachmentFile = {
  id?: number;
  name?: string;
  originalName?: string;
  documentBasePath?: string;
  base64File?: string;
};
export type DocumentFiles = {
  documentCode?: string;
  documentBasePath?: string;
  attachmentsFiles?: AttachmentFile[];
};
export const {
  useUploadAttachmentMutation,
  useDownloadAttachmentByIdQuery,
  useLazyDownloadAttachmentByIdQuery,
  useDeleteAttachmentMutation,
  useDownloadAttachmentByDocumentCodeQuery,
  useLazyDownloadAttachmentByDocumentCodeQuery,
  useGetZipFileListQuery,
  useLazyGetZipFileListQuery,
} = injectedRtkApi;
