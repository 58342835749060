import { Modal } from "antd";
import WaitingForNetworkDelay from "../../../../Layout/WaitingForNetworkDelay";
import styled from "styled-components";
import { ButtonCancel, ButtonConfirm } from "../../../../Layout/Buttons";
import { useTranslation } from "react-i18next";
import { UserItem } from "../../UserItem";
import UserConfigForm from "./UserConfigForm";
import { ModalTitleWrapper } from "../../../../../style/containers";
import { IconNaturalSubject } from "../../../../../config/icons";
import useUserConfigForm from "./useUserConfigForm";
import { UserConfig } from "../../../../../redux/apiSpecifications/apiFesf";
import { ButtonsWrapper } from "../../style/ButtonsWrapper";

const ModalContent = styled.div`
  padding: 2em;

  .ant-form-item-explain {
    color: red !important;
  }
`;

const UserConfigModalTitleWrapper = styled(ModalTitleWrapper)`
  padding: 0 0 1em 0;
  svg {
    margin-left: 0;
  }
  span {
    margin-left: 1em;
    color: #333;
    letter-spacing: 1px;
  }
`;

interface IUserConfigModalProps {
  user: UserItem;
  onCancel: () => void;
  onConfirm: (userConfig: UserConfig) => void;
}

const UserConfigModal = (props: IUserConfigModalProps) => {
  const { user, onCancel, onConfirm } = props;

  const {
    data,
    canSubmit,
    userUICommunications,
    roleTypes,
    enabledTenants,
    handleDataChange,
  } = useUserConfigForm(user);

  const { t } = useTranslation();

  const handleOnSave = () => {
    const userConfig: UserConfig = {
      roles: [],
      enabledTenants: [],
    };

    data.userRoleIds.forEach((roleId) => {
      userConfig.roles!.push({
        id: Number(roleId),
      });
    });

    data.userTenantIds.forEach((tenantId) => {
      userConfig.enabledTenants!.push({
        id: Number(tenantId),
      });
    });

    onConfirm(userConfig);
  };

  const handleOnCancel = () => {
    onCancel();
  };

  return (
    <Modal
      open={true}
      footer={null}
      closable={false}
      onCancel={handleOnCancel}
      width="600px"
    >
      <ModalContent>
        {userUICommunications.action !== "idle" ? (
          <WaitingForNetworkDelay
            action={userUICommunications.action}
            status={userUICommunications.status}
            result={userUICommunications.result}
            errorDetails={userUICommunications.errorDetails}
            onGoBack={() => {}}
            onDismiss={() => {}}
          />
        ) : (
          <>
            <UserConfigModalTitleWrapper>
              <IconNaturalSubject />
              <span>{user.contact}</span>
            </UserConfigModalTitleWrapper>

            <UserConfigForm
              model={data}
              onChange={handleDataChange}
              roleTypes={roleTypes}
              enabledTenants={enabledTenants}
            />

            <ButtonsWrapper>
              <ButtonCancel onClick={handleOnCancel}>
                {t("button-cancel")!}
              </ButtonCancel>

              <ButtonConfirm onClick={handleOnSave} disabled={!canSubmit}>
                {t("button-confirm")!}
              </ButtonConfirm>
            </ButtonsWrapper>

            {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default UserConfigModal;
