import { MainTabType } from "../../redux/features/mainTabsSlice";
import { GenericTabWrapper } from "../../style/DashbordWidgetWrappers";
import { mainTabs } from "../../config/testIds";
import DashBoard_NoRole from "./Dashboards/Dashboard-NoRole";
import DashBoard_D1 from "./Dashboards/Dashboard-D1";
import DashBoard_D2 from "./Dashboards/Dashboard-D2";
import AppraisalDetails, {
    IAppraisalDetailsProps,
} from "./MainTabContent/AppraisalsDetails/AppraisalDetails";
import DashBoard_E2 from "./Dashboards/Dashboard-E2";
import SearchResultsAppraisals from "../Widgets/SearchResults/SearchResultAppraisals";
import DashBoard_F1 from "./Dashboards/Dashboard-F1";
import DashBoard_NF from "./Dashboards/Dashboard-NF/Dashboard-NF";
import AppraiserDetails from "./MainTabContent/AppraiserDetails/AppraiserDetails";
import DashBoard_E1 from "./Dashboards/Dashboard-E1";
import DashBoard_E4 from "./Dashboards/Dashboard-E4";
import RatesClients from "./MainTabContent/RatesClients/RatesClients";
import UserManagement from "../MenuContent/UserManagement/UserManagement";
import AgendaAppraiser from "./MainTabContent/AgendaAppraiser/AgendaAppraiser";
import AgendaAppraisers from "./MainTabContent/AgendaAppraisers/AgendaAppraisers";
import ConfigurationPanel from "./MainTabContent/ConfigurationPanel/ConfigurationPanel";
import Dashboard_G1 from "./Dashboards/Dashboard-G1";
import FeesClients from "./MainTabContent/FeesClients/FeesClients";
import Dashboard_G2 from "./Dashboards/Dashboard-G2";
import RemindesPanel from "./MainTabContent/RemindersPanel/RemindersPanel";
import ManageReport from "../MenuContent/ManageReport/ManageReport";
import AppraisalExpirationDates from "../MenuContent/AppraisalExpirationDates/AppraisalExpirationDates";
import Dashboard_H1 from "./Dashboards/Dashboard-H1/Dashboard-H1";
import AssignmentMap from "../MenuContent/AssignmentMap/AssignmentMap";
import CoverageLevelConfiguration from "../MenuContent/CoverageLevelConfiguration/CoverageLevelConfiguration";
import ExpertizerScore from "../MenuContent/ExpertizerScore/ExpertizeScore";
import DashBoard_C1 from "./Dashboards/Dashboard-C1/Dashboard-C1";
// import UserManagement from "./MainTabContent/UserManagement/UserManagement";

export const buildTabContent = (
    item: MainTabType,
    disableMaps?: boolean
): React.ReactNode => {
    switch (item.type) {
        case "dashboard-NoRole":
            return <DashBoard_NoRole />;
        case "dashboard-D1":
            return <DashBoard_D1 tabKey={item.key} />;
        case "dashboard-D2":
            return <DashBoard_D2 tabKey={item.key} disableMaps={disableMaps} />;
        case "dashboard-E1":
            return <DashBoard_E1 tabKey={item.key} />;
        case "dashboard-E2":
            return <DashBoard_E2 tabKey={item.key} disableMaps={disableMaps} />;
        case "dashboard-E3":
            return <DashBoard_E2 tabKey={item.key} disableMaps={disableMaps} />;
        case "dashboard-E4":
            return <DashBoard_E4 tabKey={item.key} />;
        case "dashboard-NF":
            return <DashBoard_NF tabKey={item.key} disableMaps={disableMaps} />;
        case "dashboard-F1":
            return <DashBoard_F1 tabKey={item.key} />;
        case "dashboard-G1":
            return <Dashboard_G1 tabKey={item.key} />;
        case "dashboard-G2":
            return <Dashboard_G2 tabKey={item.key} />;
        case "dashboard-H1":
            return <Dashboard_H1 tabKey={item.key} />;
        case "dashboard-A1":
            return <h1>A1</h1>;
        case "dashboard-B1":
            return <h1>B1</h1>;
        case "dashboard-C1":
            return <DashBoard_C1 tabKey={item.key} />;

        case "search-results-appraisals":
            return (
                <GenericTabWrapper>
                    <SearchResultsAppraisals
                        tabItem={{
                            key: item.key,
                            label: item.label,
                            type: item.type,
                            externalData: item.externalData,
                        }}
                    />
                </GenericTabWrapper>
            );
        case "appraisal-details":
            return (
                <AppraisalDetails tabKey={item.key} {...item.externalData} />
            );
        // case "expertize-details":
        //   return <AppraisalDetails tabKey={item.key} {...item.externalData} />;
        case "customer-details":
            return (
                <AppraisalDetails
                    tabKey={item.key}
                    idPractice={item.externalData.idPractice}
                />
            );
        case "appraiser-details":
            return (
                <AppraiserDetails tabKey={item.key} {...item.externalData} />
            );
        case "rates-clients":
            return <RatesClients tabKey={item.key} />;
        case "user-management":
            return <UserManagement tabKey={item.key}></UserManagement>;
        case "my-agenda":
            return <AgendaAppraiser />;
        case "agenda-appraiser":
            return <AgendaAppraiser {...item.externalData} />;
        case "agenda-appraisers":
            return <AgendaAppraisers />;
        case "configuration-panel":
            return <ConfigurationPanel />;
        case "fees-clients":
            return <FeesClients />;
        case "reminders-panel":
            return <RemindesPanel tabKey={item.key} />;
        case "manage-report":
            return <ManageReport tabKey={item.key} />;
        case "appraisal-expiration-dates-configuration":
            return <AppraisalExpirationDates />;
        case "assignment-map":
            return <AssignmentMap tabKey={item.key} />;
        case "coverage-level-configuration":
            return <CoverageLevelConfiguration />;
        case "expert-score":
            return <ExpertizerScore tabKey={item.key} />;
    }
};
