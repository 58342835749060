import styled from "styled-components";
import {
  AppraiserMapData,
  PracticeMapData,
} from "../../../../redux/apiSpecifications/apiCrud";
import { SelectedAppraiser, SelectedPractice } from "./models";
import { useEffect, useState } from "react";
import { getAzureMapAddressConfig } from "../../../../config/azureConfig";
import i18next, { use } from "i18next";
import { useTranslation } from "react-i18next";
import { ButtonConfirm } from "../../../Layout/Buttons";

const MapContainerWrapper = styled.div`
  flex-direction: column;

  display: flex;
  background-color: #fff;
  border-radius: 1em;
  box-shadow: 0 0 5px #aaa;
  overflow: hidden;

  .header {
    color: #333;
    background-color: #ededed;

    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: bold;

    display: flex;

    .title {
      flex: 1 1 0%;
      padding: 0.5em 2em;
    }

    .actions {
      .icon-wrapper {
        font-size: 1.4em;
        margin-top: 6px;
        margin-right: 1em;
        cursor: pointer;
      }
    }
  }

  .body {
    display: flex;
    width: 100%;
    justify-content: space-between;
    aspect-ratio: 1 / 1;

    .col {
      flex: 1;
    }

    .map {
      width: 100%;
    }
  }
`;

const MapBaseWrapper = styled.div`
  width: 100%;

  .popup-close {
    color: white;
  }
`;

const getLanguageCode = (language: string) =>
  language === "it" ? "it-IT" : language === "en" ? "en-US" : "en-US";

const buildHtmlContent = (
  data: MarkerData<SelectedAppraiser | SelectedPractice>
) => {
  if (data.type === "Appraiser") {
    const appraiser = data.info as SelectedAppraiser;

    let imageSrc;
    if (appraiser.isPosition) {
      imageSrc = appraiser.isInternal
        ? `/images/markers/new/appraisers/internal-${appraiser.saturationClusterCode}-live-big.png`
        : `/images/markers/new/appraisers/external-${appraiser.saturationClusterCode}-live-big.png`;
    } else {
      imageSrc = appraiser.isInternal
        ? `/images/markers/new/appraisers/internal-${appraiser.saturationClusterCode}-big.png`
        : `/images/markers/new/appraisers/external-${appraiser.saturationClusterCode}-big.png`;
    }

    return `<img src="${imageSrc}"/>`;
  } else {
    const practice = data.info as SelectedPractice;
    return `<img src="/images/markers/new/practices/out-${practice.status}-big.png"/>`;
  }
};

export type CameraOption = {
    center?: number[];
    zoom?: number;
    positions?: number[][];
};

type MarkerData<T> = {
    type: "Appraiser" | "Practice";
    icon: string;
    info: T;
};

type Marker = {
    longitude: number;
    latitude: number;
    data: MarkerData<SelectedAppraiser | SelectedPractice>;
    onClick?: (m: Marker) => void;
};

interface ICustomMapProps {
    appraisers?: AppraiserMapData[] | undefined;
    practices?: PracticeMapData[] | undefined;
    positions?: number[][] | undefined;
    appraiserPositions?: number[][] | undefined;
    onSelectAppraiser: (appraiserId: number, idZone: number) => void;
    onSelectPractice: (practiceId: number) => void;
}

const CustomMap = ({
    appraisers,
    practices,
    positions,
    appraiserPositions,
    onSelectAppraiser,
    onSelectPractice,
}: ICustomMapProps) => {
  const wrapperId: string = "myMap";

  const { t } = useTranslation();

  const [map, setMap] = useState<any>();
  const [atlas, setAtlas] = useState<any>();

  useEffect(() => {
    const azureMapAddressConfig = getAzureMapAddressConfig();

        import("azure-maps-control").then((_atlas) => {
            const mapInstance = new _atlas.Map(wrapperId, {
                language: getLanguageCode(i18next.language),
                authOptions: {
                    authType: _atlas.AuthenticationType.subscriptionKey,
                    subscriptionKey: azureMapAddressConfig.subscriptionKey,
                },
                showLogo: false,
                showFeedbackLink: false,
                style: "grayscale_light",
            });

      setMap(mapInstance);
      setAtlas(_atlas);
    });
  }, []);

  useEffect(() => {
    if (!map || !atlas || !appraisers || !practices) return;

    map.popups.clear();
    map.markers.clear();

    const markers: Marker[] = [];

        appraisers.forEach((appraiser) => {
            appraiser.coverageDistricts?.forEach((coverageDistrict) => {
                const marker: Marker = {
                    longitude: coverageDistrict.longitude!,
                    latitude: coverageDistrict.latitude!,
                    data: {
                        type: "Appraiser",
                        icon: `Appraiser_${coverageDistrict.saturationClusterCode}`,
                        info: {
                            id: appraiser.id,
                            name: appraiser.name,
                            idZone: coverageDistrict.idzone,
                            longitude: coverageDistrict.longitude,
                            latitude: coverageDistrict.latitude,
                            saturationClusterCode:
                                coverageDistrict.saturationClusterCode,
                            address: appraiser.address,
                            totalManaged: appraiser.totalManagedPractices,
                            dailyCapacity: appraiser.totalDailyCapacity,
                            montlyResidual: appraiser.monthlyResidual,
                            monthlyCapacity: appraiser.totalMonthlyCapacity,
                            score: appraiser.totalScore,
                            isInternal: appraiser.isInternal,
                            districtCode: coverageDistrict.districtCode,
                            isPosition: coverageDistrict.isPosition,
                        } as SelectedAppraiser,
                    },
                };

        markers.push(marker);
      });
    });

    practices.forEach((practice) => {
      const marker: Marker = {
        longitude: practice?.appraisalLocationAddress?.longitude!,
        latitude: practice?.appraisalLocationAddress?.latitude!,
        data: {
          type: "Practice",
          icon: `Practice_Gray_${practice.practiceStatus}`,
          info: {
            id: practice.id,
            number: practice.practiceNumber,
            status: practice.practiceStatus,
            longitude: practice?.appraisalLocationAddress?.longitude,
            latitude: practice?.appraisalLocationAddress?.latitude,
            assignedAppraiser: practice.assignedAppraiser,
            assignedAppraiserId: practice.assignedAppraiserId,
            appraisalLocationAddress: `${practice.appraisalLocationAddress?.street}, ${practice.appraisalLocationAddress?.civic} ${practice.appraisalLocationAddress?.city} (${practice.appraisalLocationAddress?.province})`,
            province: practice.appraisalLocationAddress?.province,
            receiptDate: practice.receiptDate,
            practiceType: practice.practiceTypeCode,
          } as SelectedPractice,
        },
      };

      markers.push(marker);
    });

    map.events.add("ready", () => {
      const popup = new atlas.Popup({
        position: [0, 0],
        pixelOffset: [0, -18],
      });

      markers.forEach((marker) => {
        const htmlMarker = new atlas.HtmlMarker({
          htmlContent: buildHtmlContent(marker.data),
          position: [marker.longitude, marker.latitude],
        });

        map.markers.add(htmlMarker);

                map.events.add("click", htmlMarker, () => {
                    if (marker.data.type === "Practice") {
                        onSelectPractice(marker.data.info.id);
                    } else if (marker.data.type === "Appraiser") {
                        const appraiserInfo = marker.data
                            .info as SelectedAppraiser;

                        onSelectAppraiser(
                            appraiserInfo.id,
                            appraiserInfo.idZone!
                        );
                    }
                });

        map.events.add("mousemove", htmlMarker, () => {
          const html: string[] = ['<div style="padding:10px;">'];

          if (marker.data.type === "Practice") {
            const practice = marker.data.info as SelectedPractice;

            html.push(practice.number);
          } else if (marker.data.type === "Appraiser") {
            const appraiser = marker.data.info as SelectedAppraiser;

            html.push(appraiser.name);
          }

          html.push("</div>");

          popup.setOptions({
            position: [marker.longitude, marker.latitude],
            content: html.join(""),
          });

          popup.open(map);
        });

        map.events.add("mouseout", htmlMarker, () => {
          popup.close();
        });
      });

      const bounds = atlas.data.BoundingBox.fromPositions(
        markers.map((m) => [m.longitude, m.latitude])
      );

            map.setCamera({
                bounds,
                padding: 50,
            });
        });
    }, [map, atlas, appraisers, practices]);

    useEffect(() => {
        if (positions && positions.length > 0) {
            if (positions.length === 1) {
                map.setCamera({
                    center: positions[0],
                    zoom: 15,
                });
            } else {
                const bounds = atlas.data.BoundingBox.fromPositions(positions);

                map.setCamera({
                    bounds,
                    padding: 50,
                });
            }
        }
    }, [positions]);

  return (
    <MapContainerWrapper>
      <div className="header">
        <div className="title">{t("assignment-map-label")}</div>
      </div>

      <div className="body">
        <MapBaseWrapper id={wrapperId}></MapBaseWrapper>
      </div>
    </MapContainerWrapper>
  );
};

export default CustomMap;
