import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Users } from "../../../../redux/apiSpecifications/apiFesf";
import { UserItem } from "../UserItem";
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import { CellWrapper, ClickableCellWrapper } from "../style/CellWrapper";
import styled from "styled-components";
import { SwitchStyled } from "../../../../style/Input";

const AssociatedUsersListWrapper = styled.div`
    display: flex;
    align-items: center;
    overflow: auto;
    margin-top: 1em;
    background-color: #fff;
    border-radius: 1em;
    box-shadow: 0 0 5px #aaa;
    padding: 1em 2em;

    .MuiPaper-root {
        box-shadow: none;
        width: 95%;
    }

    .MuiInputBase-root {
        &:before {
            border-bottom: 1px solid white;
        }
    }

    .MuiToolbar-root > .MuiBox-root {
        justify-content: flex-start;
    }

    .MuiInputBase-input {
        color: #555;
    }

    .MuiTableCell-root {
        padding: 0.5em 1em;
    }
`;

interface IAssociatedUsersListProps {
    users: Users;
    onEditUserContact: (userItem: UserItem) => void;
    onEditUserConfig: (userItem: UserItem) => void;
    onToggleUserActivation: (userItem: UserItem, activation: boolean) => void;
}

const AssociatedUsersList = (props: IAssociatedUsersListProps) => {
    const { t } = useTranslation();

    const {
        users,
        onEditUserContact,
        onEditUserConfig,
        onToggleUserActivation,
    } = props;

    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 5,
    });

    const data = useMemo(
        () =>
            users?.map((user) => {
                return {
                    id: user.id,
                    idSubject: user.idSubject,
                    account: user.userName,
                    email: user.email,
                    contact: user.firstname + " " + user.surname,
                    roles: user.roles,
                    rolesText: user.roles
                        ?.map((r) => t(`role-description-${r.roleCode!}`))
                        .join(", "),
                    tenants: user.tenants,
                    tenantsText: user.tenants
                        ?.map((t) => t.companyName)
                        .join(", "),
                    isActive: user.isActive,
                    userConfigLists: {
                        allRoles: user.roles,
                        allTenants: user.tenants,
                    },
                } as UserItem;
            }) || [],
        [users]
    );

    const handleSelectContanct = (row: UserItem) => {
        onEditUserContact(row);
    };

    const handleSelectConfig = (row: UserItem) => {
        onEditUserConfig(row);
    };

    const columns = useMemo<MRT_ColumnDef<UserItem>[]>(
        () => [
            {
                accessorKey: "account",
                header: t("associated-users-list-account-column-header"),
                size: 100,
                Cell: ({ row }) => (
                    <CellWrapper>{row.original.account}</CellWrapper>
                ),
            },
            {
                accessorKey: "contact",
                header: t("associated-users-list-contact-column-header"),
                size: 200,
                Cell: ({ row }) => (
                    <ClickableCellWrapper
                        onClick={() => handleSelectContanct(row.original)}
                    >
                        {row.original.contact}
                    </ClickableCellWrapper>
                ),
            },
            {
                accessorKey: "roles",
                header: t("associated-users-list-roles-column-header"),
                size: 400,
                Cell: ({ row }) => (
                    <ClickableCellWrapper
                        onClick={() => handleSelectConfig(row.original)}
                    >
                        {row.original.rolesText}
                    </ClickableCellWrapper>
                ),
            },
            {
                accessorKey: "tenants",
                header: t(
                    "associated-users-list-customer-management-column-header"
                ),
                size: 400,
                Cell: ({ row }) => (
                    <ClickableCellWrapper
                        onClick={() => handleSelectConfig(row.original)}
                    >
                        {row.original.tenantsText}
                    </ClickableCellWrapper>
                ),
            },
            {
                accessorKey: "isActive",
                header: t("associated-users-list-enable-account-column-header"),
                size: 100,
                enableColumnActions: false,
                Cell: ({ row }) => (
                    <CellWrapper>
                        <div style={{ textAlign: "center" }}>
                            <SwitchStyled
                                onChange={(value) =>
                                    onToggleUserActivation(row.original, value)
                                }
                                unCheckedChildren={t("switch-no")}
                                checkedChildren={t("switch-yes")}
                                checked={row.original.isActive}
                            />
                        </div>
                    </CellWrapper>
                ),
            },
        ],
        [users]
    );

    return (
        <AssociatedUsersListWrapper>
            {data.length > 0 && (
                <MaterialReactTable
                    muiTablePaperProps={{ style: { width: "100%" } }}
                    columns={columns}
                    data={data}
                    enableDensityToggle={false}
                    enableColumnOrdering
                    muiTableBodyCellProps={{
                        sx: {
                            textAlign: "left",
                        },
                    }}
                    onPaginationChange={setPagination}
                    state={{ pagination }}
                    autoResetPageIndex={false}
                />
            )}
        </AssociatedUsersListWrapper>
    );
};

export default AssociatedUsersList;
