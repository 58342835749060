import { Button, Divider, Space, Tag } from "antd";
import { FormItemStyled, SelectStyled } from "../../../../style/Input";
import { useTranslation } from "react-i18next";
import { SelectPair } from "../../../../types/common.types";
import styled from "styled-components";
import { AppraiserScoreCluster } from "../../../../redux/apiSpecifications/apiFesf";
import { ConfigureClusterActionType, DistrictCluster } from "../types";
import { useMemo } from "react";
import { useExpertizeScoreContext } from "../ExpertizeScoreContext";

const ConfigureClusterInputWrapper = styled.div`
    width: 100%;
    div.ant-form-item-row {
        display: flex;
        flex-direction: column;

        div.ant-form-item-label {
            text-align: left;
        }
    }
`;

const TagsWrapper = styled.div`
    .tag {
        margin-top: 0.5em;
        font-size: 1.2em;
    }
`;

interface IConfigureClusterInputProps {
    cluster: AppraiserScoreCluster;
    availableProvincesOptions: SelectPair[] | undefined;
    onAction: (action: ConfigureClusterActionType, payload: any) => void;
}

const ConfigureClusterInput = ({
    cluster,
    availableProvincesOptions,
    onAction,
}: IConfigureClusterInputProps) => {
    const { t } = useTranslation();

    const {
        provincesOptions
    } = useExpertizeScoreContext();


    const handleFilter = (input: string, option: any) => {
        const text = input.toLowerCase();

        const optionLabel = (option?.label ?? "").toLowerCase();

        return optionLabel.includes(text);
    };

    return (
        <ConfigureClusterInputWrapper>
            <FormItemStyled label={cluster.clusterName}>
                <SelectStyled
                    onChange={(value) => {
                        onAction("add-district", {
                            clusterId: cluster.id,
                            districtId: value,
                        });
                    }}
                    value={null}
                    options={availableProvincesOptions ?? []}
                    filterOption={handleFilter}
                    showSearch
                />
            </FormItemStyled>

            <TagsWrapper>
                {cluster.districts?.map((districtId) => (
                    <span key={districtId}>
                        <Tag
                            closable
                            onClose={(e) => {
                                e.preventDefault();

                                onAction("remove-district", {
                                    clusterId: cluster.id,
                                    districtId: districtId,
                                });
                            }}
                            className="tag"
                        >
                            {provincesOptions?.find(_ => _.value === districtId)?.label}
                        </Tag>
                    </span>
                ))}
            </TagsWrapper>
        </ConfigureClusterInputWrapper>
    );
};

export default ConfigureClusterInput;
