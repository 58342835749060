import React, { useMemo } from "react";
import styled from "styled-components";
import {
  PracticeAttachment,
  PracticeDocument,
} from "../../../../../redux/apiSpecifications/apiCrud";
import { useTranslation } from "react-i18next";
import AttachmentsPractice from "../../../../Inputs/AttachmentsPractice";
import { useDeleteAttachmentMutation } from "../../../../../redux/apiSpecifications/apiDocs";

const AttachmentsWrapper = styled.div<{ isMultiple: boolean }>`
  width: ${(props) => (props.isMultiple ? "100% !important" : "auto")};

  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.isMultiple ? "flex-start" : "center")};
  justify-content: center;
  width: 12em;
  text-align: center;
  font-size: 0.9em;
`;

/**
 * @description AppraisalDetailsDocument props
 */
interface IAppraisalDetailsDocumentProps {
  document: PracticeDocument | undefined;
  readonly?: boolean | undefined;
  onChange: (document: PracticeDocument) => void;
  onDeleteAttachment: (index: number) => void;
}

/**
 * @description Show a document related to the appraisal and its attachments
 */
const AppraisalDetailsDocument = ({
  document,
  onChange,
  onDeleteAttachment,
  readonly,
}: IAppraisalDetailsDocumentProps) => {
  const isMultiple = ["PAC", "ID"].includes(document?.documentTypeCode || "");

  // Common variables
  const { t } = useTranslation();

  // Fecth & update
  const [deleteAttachment] = useDeleteAttachmentMutation();

  // Local State
  const attachments = document?.attachments || [];
  const { acceptedFormats, maxFiles } = useMemo(() => {
    let acceptedFormats: any = {
      "application/pdf": [".pdf"],
    };

    let maxFiles = 1;

    if (["PAC", "ID"].includes(document?.documentTypeCode || ""))
      acceptedFormats = {
        "image/*": [".jpg", ".jpeg", ".png", ".gif"],
      };

    if (document?.documentTypeCode === "ID") maxFiles = 2;
    if (document?.documentTypeCode === "PAC") maxFiles = 10;

    return { acceptedFormats, maxFiles };
  }, [document]);

  // Handlers
  const handleAddAttachment = (attachment: PracticeAttachment) => {
    const newAttachments = [...document?.attachments!, attachment];

    const updatedDocument = {
      ...document,
      attachments: newAttachments,
    };

    onChange(updatedDocument);
  };

  const handleRemoveAttachment = (index: number) => {
    onDeleteAttachment(index);
  };

  const getAttachmentName = (document: PracticeDocument | undefined) => {
    if (["AAP", "AIN", "ACL"].includes(document?.documentTypeCode || "")) {
      return null;
    } else {
      const code =
        (document?.version || 0) > 1
          ? `${document?.documentTypeCode}-${document?.version}`
          : document?.documentTypeCode;
      return t(`appraisal-details-tab-documents-filetype-${code}`);
    }
  };

  return (
    <AttachmentsWrapper isMultiple={isMultiple}>
      <AttachmentsPractice
        attachments={attachments}
        acceptedFormats={acceptedFormats}
        maxFiles={maxFiles}
        onAddAttachment={handleAddAttachment}
        onRemoveAttachment={handleRemoveAttachment}
        showFilename={["AAP", "AIN", "ACL"].includes(
          document?.documentTypeCode || ""
        )}
        typeName={getAttachmentName(document)}
        readonly={
          ["VAD", "FI"].includes(document?.documentTypeCode || "") || readonly
        }
      />
    </AttachmentsWrapper>
  );
};

export default AppraisalDetailsDocument;
