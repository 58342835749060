import { Tabs } from "antd";
import { Tab } from "rc-tabs/lib/interface";
import { IconLoading } from "../../../config/icons";
import {
    LoadingInTabWrapper,
    MainTabContentWrapper,
    RefreshInTabWrapper,
} from "../../../style/DashbordWidgetWrappers";
import { TabsWrapper } from "../../MainTabs/MainTabContent/Commons/Wrappers";
import { useExpertizeScoreContext } from "./ExpertizeScoreContext";
import { useTranslation } from "react-i18next";
import ConfigureCluster from "./ConfigureCluster/ConfigureCluster";
import DownloadReport from "./DownloadReport/DownloadReport";
import ConfigureThresholdWeight from "./ConfigureThresholdWeight/ConfigureThresholdWeight";
import { useState } from "react";
import { Prompt } from "./Prompt/Prompt";

const ExpertizeScoreTabs = () => {
    const { t } = useTranslation();

    const { loadingStatus, isFormChanged, onCancelFormChanges } = useExpertizeScoreContext();

    const [activeTabKey, setActiveTabKey] =
        useState<string>("configure-cluster");

    const [showPrompt, setShowPrompt] = useState<boolean>(false);
    const [tabToSwitch, setTabToSwitch] = useState<string | undefined>(
        undefined
    );

    const items = [
        {
            key: "configure-cluster",
            label: t("expert-score-tab-configure-cluster-label"),
            children: <ConfigureCluster />,
        },
        {
            key: "configure-theshold-weight",
            label: t("expert-score-tab-configure-threshold-weight"),
            children: <ConfigureThresholdWeight />,
        } as Tab,
        {
            key: "download-report",
            label: t("expert-score-tab-download-report"),
            children: <DownloadReport />,
        } as Tab,
    ];

    const handleOnChangeTab = (key: string) => {
        if (isFormChanged) {
            setTabToSwitch(key);

            setShowPrompt(true);
        } else setActiveTabKey(key);
    };

    const handleOnCancelPrompt = () => {
        setShowPrompt(false);
    };

    const handleOnConfirmPrompt = () => {
        setShowPrompt(false);

        onCancelFormChanges();

        setActiveTabKey(tabToSwitch!);
    };

    return (
        <div>
            {loadingStatus === "loading" && (
                <LoadingInTabWrapper>
                    <div className="box">{IconLoading}</div>
                </LoadingInTabWrapper>
            )}

            {(loadingStatus === "refreshing" ||
                loadingStatus === "load-in-progress" ||
                loadingStatus === "updating" ||
                loadingStatus === "idle") && (
                <MainTabContentWrapper>
                    <TabsWrapper>
                        <Tabs
                            items={items}
                            tabPosition="left"
                            activeKey={activeTabKey}
                            onChange={handleOnChangeTab}
                        />
                    </TabsWrapper>

                    <Prompt
                        open={showPrompt}
                        onCancel={handleOnCancelPrompt}
                        onConfirm={handleOnConfirmPrompt}
                    />

                    {loadingStatus === "refreshing" ||
                        loadingStatus === "load-in-progress" ||
                        (loadingStatus === "updating" && (
                            <RefreshInTabWrapper>
                                <div className="box">{IconLoading}</div>
                            </RefreshInTabWrapper>
                        ))}
                </MainTabContentWrapper>
            )}
        </div>
    );
};

export default ExpertizeScoreTabs;
