import styled from "styled-components";

import { WidgetWrapper } from "../../../../../style/DashbordWidgetWrappers";
import { useState } from "react";
import MapBase, { Marker } from "../../../../Maps/MapBase";

const MapWrapper = styled.div`
  flex-direction: column;
  flex: 1;

  max-height: 2.5em;
  transition: max-height 1s;

  &.open {
    max-height: 100em;
    min-height: 30em;
  }
`;

interface IAppraisalsMapMapProps {
  isActive?: boolean;
  disable?: boolean;
  isOpen?: boolean;
  testMarker?: (markers: Array<Marker>) => void;
}

const AppraiserMap = (props: IAppraisalsMapMapProps) => {
  const { disable, isActive = false, testMarker } = props;
  const [isOpen, setIsOpen] = useState(props.isOpen);

  const [center, setCenter] = useState<[number, number]>([
    12.4963655, 41.9027835,
  ]);

  return (
    <MapWrapper className={`${isActive || isOpen ? "open" : ""}`}>
      {!disable && (
        <MapBase wrapperId="map-appraiser-list" markers={[]} center={center} />
      )}
    </MapWrapper>
  );
};

export default AppraiserMap;
