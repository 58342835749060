import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { mainTabs as testIds } from "../../../config/testIds";
import {
  DashboardWrapper,
  LoadingInTabWrapper,
  RefreshInTabWrapper,
} from "../../../style/DashbordWidgetWrappers";
import { useTranslation } from "react-i18next";
import {
  DashboardExperiseInitialDialogSelectionType,
  ExpertizerStatusType,
} from "../../../config/const";
import { IconLoading } from "../../../config/icons";
import { useEffect, useState } from "react";
import ExpertizerStatuses from "../../Widgets/Expertizer/ExpertizerStatuses";
import ExpertizerMap from "../../Widgets/Expertizer/ExpertierMap";
import ExpertizerInProgress, {
  FilterExpertizerInProgress,
} from "../../Widgets/Expertizer/ExpertizerInProgress";
import ExpertizerCommunications, {
  ExpertizerCommunicationSelectionType,
} from "../../Widgets/Expertizer/ExpertizerCommunications";
import ExpertizerList from "../../Widgets/Expertizer/ExpertizerList";
import CommunicationList from "../../Widgets/Communications/CommunicationsList";
import ExpertizerInitialDialog from "../../Widgets/Expertizer/ExpertizerInitialDialog";
import { RootState } from "../../../redux/store";
import { setInfoAlwaysVisibleTop } from "../../../redux/features/userSlice";
import {
  Communication,
  GetRequestListApiArg,
  Request,
  useLazyGetPracticesE2SummaryQuery,
  useLazyGetRequestListQuery,
  useLazyGetUserCommunicationsListQuery,
} from "../../../redux/apiSpecifications/apiCrud";
import { DashboardE2Summary } from "../../../redux/apiSpecifications/apiCrud";
import { AggregateSummaryDataE2 } from "./common-utils";
import { useInterval } from "../../../hooks/useInterval";
import TimerPie, { TimerUpdateWrapper } from "../../Layout/TimerPie";
import { formatDate } from "../../../utils/date";
import { useAuthorization } from "../../../hooks/useAuthorization";
import AppraisalsRequestsChartAP from "../../Widgets/Appraisals/AppraisalsRequestsCharts/AppraisalsRequestsChartAP";
import { AppraisalsRequestsChartSelectionType } from "../../Widgets/Appraisals/AppraisalsRequestsCharts/AppraisalsRequestsChartSelectionType";
import { Tenant } from "../../../redux/apiSpecifications/apiFesf";
import PracticeRequestsList from "../../Widgets/PracticeRequests/PracticeRequestsList";
import PriorityFilter from "../../Widgets/Expertizer/PriorityFilter";
import ExperiizerPriorityInfo from "../../Widgets/Expertizer/ExpertierPriorityInfo";

const DashboardE2Wrapper = styled(DashboardWrapper)`
  display: grid;
  gap: 1em;
  grid-template-columns: 1fr 1fr 36px;
  grid-template-rows: 36px 36px auto auto;
  grid-template-areas:
    "filter-priority filter-priority info"
    "filter-status filter-status refresh"
    "map requests requests"
    "practiceList practiceList practiceList";

  .filter-priority-wrapper {
    grid-area: filter-priority;
    display: flex;
    gap: 1em;
  }

  .filter-status-wrapper {
    grid-area: filter-status;
    display: flex;
    gap: 1em;

    .timer-spacer {
      width: 36px;
    }
  }

  .refresh-wrapper {
    grid-area: refresh;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .refresh-wrapper > div {
    height: 100%;
  }

  -info-wrapper {
    grid-area: info;
  }

  .map-wrapper {
    grid-area: map;
    display: flex;
  }

  .requests-wrapper {
    grid-area: requests;
  }

  .practiceList-wrapper {
    grid-area: practiceList;
  }
`;

interface IDashBoard_E2Props {
  disableMaps?: boolean;
  tabKey?: number;
}

export type StatusTypes = "RE" | "CR" | "AD" | "AFO" | "CP";

type FiltersValues = {
  status?: StatusTypes;
  priority: number | undefined;
};

export type FilterPriorityValues = {
  urgent: number;
  important: number;
  expiring: number;
  expired: number;
  standard: number;
};

export type FilterStatusValues = {
  rejected: number;
  confirmed: number;
  withoutAppointment: number;
  withAppointment: number;
  withAppointmentToday: number;
  concluded: number;
};

const defaultFilters: FiltersValues = {
  status: undefined,
  priority: undefined,
};

const DashBoard_E2 = (props: IDashBoard_E2Props) => {
  const { disableMaps } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [retrieveSummaryData] = useLazyGetPracticesE2SummaryQuery();
  const [getRequestList] = useLazyGetRequestListQuery();

  const { getAuthorization } = useAuthorization();

  const { selectedTenants, activeRole } = useSelector(
    (state: RootState) => state.user
  );
  const { activeKey } = useSelector((state: RootState) => state.mainTabs);
  const autoRefreshTabData = useSelector(
    (state: RootState) => state.user.configParams?.autoRefreshTabData || 600
  ); // 10 minutes

  const {
    restart: restartRefrehInterval,
    reset: resetRefrehInterval,
    clear: clearRefreshInterval,
    percentage,
    remainingTime,
  } = useInterval({
    seconds: autoRefreshTabData,
    callback: () => {
      setLoadingStatus("refreshing");
      //loadSummary(true);

      refreshDashboardData();
    },
  });

  const [showInitialDialog, setShowInitialDialog] = useState<boolean>(true);
  const [loadingStatus, setLoadingStatus] = useState<
    "idle" | "loading" | "refreshing"
  >("idle");

  const [filterPriorityValues, setFilterPriorityValues] =
    useState<FilterPriorityValues>({
      urgent: 0,
      important: 0,
      expiring: 0,
      expired: 0,
      standard: 0,
    });

  const [filterStatusValues, setFilterStatusValues] =
    useState<FilterStatusValues>({
      rejected: 0,
      confirmed: 0,
      withoutAppointment: 0,
      withAppointment: 0,
      withAppointmentToday: 0,
      concluded: 0,
    });

  const [summaryDataOriginal, setSummaryDataOriginal] =
    useState<DashboardE2Summary>();
  const [summaryDataFiltered, setSummaryDataFiltered] =
    useState<DashboardE2Summary>();

  const [filters, setFilters] = useState(defaultFilters);
  const [filteringType, setFilteringType] = useState<
    "status" | "in-progress" | "communications"
  >("status");

  const [requestsData, setRequestsData] = useState<Request[]>([]);

  const [requestsFilterSelection, setRequestsFilterSelection] =
    useState<AppraisalsRequestsChartSelectionType>({
      senderType: "",
      categoryCode: "",
    });

  useEffect(() => {
    if (activeKey === props.tabKey) {
      setLoadingStatus("refreshing");
      (async () => {
        // await loadSummary(true);
        await refreshDashboardData();

        restartRefrehInterval();
      })();
    } else clearRefreshInterval();
  }, [activeKey]);

  const loadRequests = async (
    selection: AppraisalsRequestsChartSelectionType
  ) => {
    if (!selectedTenants) return;

    dispatch(
      setInfoAlwaysVisibleTop({
        type: "empty",
        message: "",
      })
    );

    setLoadingStatus("refreshing");

    const params: GetRequestListApiArg = {
      authorization: await getAuthorization(),
      activeRole: activeRole!,
      tenants: selectedTenants.map((t: Tenant) => t.id!),
      lastMessageSenderType: selection.lastMessageSenderType ?? "",
      lastMessageReceiverType: selection.lastMessageReceiverType ?? "",
      categoryCode: selection.categoryCode ?? "",
      statusCode: selection.statusCode,
    };

    const response = await getRequestList(params);

    const { data, error, isSuccess, isError } = response;

    if (isSuccess && data) {
      setRequestsData(data as Request[]);
    } else if (isError) {
      dispatch(
        setInfoAlwaysVisibleTop({
          type: "error",
          message: t("http-error-loading-dashboard"),
        })
      );
      setLoadingStatus("idle");
      return;
    } else {
      console.log("unknown error");
    }

    dispatch(
      setInfoAlwaysVisibleTop({
        type: "empty",
        message: "",
      })
    );

    setLoadingStatus("idle");
  };

  const loadSummary = async (keepFilter?: boolean) => {
    if (!selectedTenants) return;
    dispatch(
      setInfoAlwaysVisibleTop({
        type: "empty",
        message: "",
      })
    );

    const tenantsSummaryData: DashboardE2Summary[] = [];

    for (let tenant of selectedTenants) {
      const idTenant = tenant.id!;

      const response = await retrieveSummaryData({
        idTenant,
        authorization: await getAuthorization(),
      });
      const { data, error, isSuccess, isError } = response;

      if (isSuccess && data) {
        tenantsSummaryData.push(data as DashboardE2Summary);
      } else if (isError) {
        dispatch(
          setInfoAlwaysVisibleTop({
            type: "error",
            message: t("http-error-loading-dashboard"),
          })
        );
        setLoadingStatus("idle");
        return;
      } else {
        console.log("unknown error");
      }
    }

    const filterByAppraiserPriority = (priority: number) =>
      aggregatedSummaryData.practices?.filter(
        (p) => p.priorityAppraiser === priority
      ).length || 0;

    const aggregatedSummaryData = AggregateSummaryDataE2(tenantsSummaryData);
    const filterPriorityValues = {
      urgent: filterByAppraiserPriority(1),
      important: filterByAppraiserPriority(2),
      expiring: filterByAppraiserPriority(3),
      expired: filterByAppraiserPriority(4),
      standard: filterByAppraiserPriority(5),
    };

    const updatedFiltersValues = {
      rejected: aggregatedSummaryData.totalRejected || 0,
      confirmed: aggregatedSummaryData.totalConfirmationOfReceipt || 0,
      withoutAppointment:
        aggregatedSummaryData.totalAppointmentToBeScheduled || 0,
      withAppointment: aggregatedSummaryData.appointmentsScheduled || 0,
      withAppointmentToday:
        aggregatedSummaryData.appointmentsScheduledToday || 0,
      concluded: aggregatedSummaryData.concludePractice || 0,
    };

    setFilterStatusValues(updatedFiltersValues);

    setSummaryDataOriginal(aggregatedSummaryData);
    setFilterPriorityValues(filterPriorityValues);

    if (!keepFilter) {
      setSummaryDataFiltered(aggregatedSummaryData);
    }

    setLoadingStatus("idle");
  };

  const refreshDashboardData = async () => {
    loadSummary(true);

    if (filteringType == "communications") {
      loadRequests(requestsFilterSelection);
    }
  };

  useEffect(() => {
    setLoadingStatus("loading");
    // loadSummary();
    refreshDashboardData();
  }, [selectedTenants]);

  const handleFilterStatus = (status: StatusTypes | undefined) => {
    setFilteringType("status");

    const updatedFilters = { ...filters, status };
    setFilters(updatedFilters);
  };

  useEffect(() => {
    const today = formatDate(new Date());

    let filteredPractices = summaryDataOriginal?.practices || [];

    if (filters.priority)
      filteredPractices = (filteredPractices || []).filter(
        (practice) => practice.priorityAppraiser === filters.priority
      );

    //  recalculate status values, "Appuntamento fissato oggi" win on every status
    // first get practices with appointment today
    // then on the rest of the practices, calc quantity by the status

    const practicesWithAppointmentToday = filteredPractices.filter(
      (p) => p.appointmentDate === today
    );

    const practicesWithoutAppointmentToday = filteredPractices.filter(
      (p) => p.appointmentDate !== today
    );

    const filterStatusValues = {
      rejected: practicesWithoutAppointmentToday.filter(
        (p) => p.status === "RE"
      ).length,
      confirmed: practicesWithoutAppointmentToday.filter(
        (p) => p.status === "PA"
      ).length,
      withoutAppointment: practicesWithoutAppointmentToday.filter(
        (p) => p.status === "AD"
      ).length,
      withAppointment: filteredPractices.filter(
        (p) => p.status === "AF" && p.appointmentDate !== today
      ).length,
      withAppointmentToday: practicesWithAppointmentToday.length,
      concluded: practicesWithoutAppointmentToday.filter(
        (p) => p.status === "CP"
      ).length,
    };

    if (filters.status) {
      filteredPractices = filteredPractices.filter((practice) => {
        if (filters.status === "AFO") {
          // TODAYS APPOINTMENTS
          return practice.appointmentDate === today;
        } else
          return (
            practice.status === filters.status &&
            practice.appointmentDate !== today
          ); // if appointment is today then the practice should be swown only for AFO filter
      });
    }
    setSummaryDataFiltered({
      practices: filteredPractices,
    });
    setFilterStatusValues(filterStatusValues);
  }, [filters, summaryDataOriginal]);

  const handleFilterPriority = (priority: number | undefined) => {
    setFilteringType("status");

    setFilters({ ...filters, priority });
  };

  const handleInitialDialogSelect = (
    selection: number | DashboardExperiseInitialDialogSelectionType
  ) => {
    if (selection === -1) return;
    if (selection === 0) {
      setShowInitialDialog(false);
      return;
    } else if (selection === "notifications")
      setFilteringType("communications");
    else {
      setFilteringType("status");
      handleFilterPriority(selection as number);
    }
    setShowInitialDialog(false);
  };

  const handleFilterRequests = (
    selection: AppraisalsRequestsChartSelectionType
  ) => {
    setFilteringType("communications");
    setRequestsFilterSelection(selection);

    loadRequests(selection);
  };

  return (
    <div data-testid={testIds.dashboards.E2}>
      {showInitialDialog && (
        <ExpertizerInitialDialog
          onSelect={handleInitialDialogSelect}
          values={filterPriorityValues}
          notifications={summaryDataOriginal?.totalCommunications || 0}
        />
      )}
      {loadingStatus === "loading" && (
        <LoadingInTabWrapper>
          <div className="box" data-testid={testIds.E2.loader}>
            {IconLoading}
          </div>
        </LoadingInTabWrapper>
      )}
      {(loadingStatus === "refreshing" || loadingStatus === "idle") &&
        summaryDataOriginal &&
        summaryDataFiltered && (
          <>
            <DashboardE2Wrapper>
              <div className="filter-priority-wrapper">
                <PriorityFilter
                  values={filterPriorityValues}
                  onSelect={handleFilterPriority}
                  selected={filters.priority}
                />
              </div>

              <div className="info-wrapper">
                <ExperiizerPriorityInfo />
              </div>
              <div className="filter-status-wrapper">
                <ExpertizerStatuses
                  values={filterStatusValues}
                  onSelect={handleFilterStatus}
                  selected={filters.status}
                />
              </div>
              <div className="refresh-wrapper">
                <TimerUpdateWrapper width={"36px"}>
                  <TimerPie
                    percentage={percentage as number}
                    tooltip={`${t(
                      "timer-dashboard-refresh-tooltip-1"
                    )!}  ${remainingTime}`}
                    innerText={remainingTime as string}
                    bgColor="#32b677"
                    gridArea="refresh"
                    onClick={() => {
                      setLoadingStatus("refreshing");
                      // loadSummary(true);
                      refreshDashboardData();
                      resetRefrehInterval();
                    }}
                  />
                </TimerUpdateWrapper>
              </div>

              <div className="map-wrapper">
                {!disableMaps && (
                  <ExpertizerMap practices={summaryDataFiltered.practices} />
                )}
              </div>
              <div className="requests-wrapper">
                <AppraisalsRequestsChartAP
                  isActive={filteringType === "communications"}
                  userRequestsSummary={
                    summaryDataOriginal?.userRequestsSummary!
                  }
                  gridArea={"comunications"}
                  onSelect={handleFilterRequests}
                  selection={requestsFilterSelection}
                />
              </div>

              <div className="practiceList-wrapper">
                {filteringType === "status" && (
                  <ExpertizerList
                    items={summaryDataFiltered.practices}
                    isActive={activeKey === props.tabKey}
                    onRefresh={(val) => {
                      //handleFilterStatus(val);
                    }}
                  />
                )}

                {filteringType === "in-progress" && (
                  <ExpertizerList
                    items={summaryDataFiltered.practices}
                    isActive={activeKey === props.tabKey}
                    onRefresh={(val) => {
                      // handleFilterStatus(val);
                    }}
                  />
                )}

                {filteringType === "communications" && (
                  <PracticeRequestsList
                    items={requestsData}
                    gridArea={"practicesList"}
                  />
                )}
              </div>
            </DashboardE2Wrapper>
            {loadingStatus === "refreshing" && (
              <RefreshInTabWrapper>
                <div className="box" data-testid={testIds.D2.loader}>
                  {IconLoading}
                </div>
              </RefreshInTabWrapper>
            )}
          </>
        )}
    </div>
  );
};

export default DashBoard_E2;
