import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Collapse, Modal, Popconfirm, message } from "antd";

import {
  AppraisalSubject,
  Appraiser,
  CreatePracticeNoteApiArg,
  DeletePracticeNoteApiArg,
  DeletePracticeNoteApiResponse,
  NotifyNoContactApiResponse,
  OutOfCoverageAppraisalSubject,
  PracticeAppointment,
  PracticeAttachment,
  PracticeNote,
  ReadOnlyPractice,
  RequestContactApiResponse,
  UpdatePracticeNoteApiArg,
  useAcceptPracticeAssignmentMutation,
  useAssignAppraiserMutation,
  useCreatePracticeAppointmentMutation,
  useCreatePracticeNoteMutation,
  useDeletePracticeNoteMutation,
  useNotifyNoContactMutation,
  useRequestContactMutation,
  useRestorePracticeMutation,
  useSetOutOfCoverageAreaMutation,
  useSuspendOrCancelPracticeMutation,
  useUpdatePracticeNoteMutation,
} from "../../../../../redux/apiSpecifications/apiCrud";

import { useEffect, useRef, useState } from "react";
import { RootState, store } from "../../../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import PanelHeaderWithButtons, {
  PanelStyled,
} from "../../Commons/PanelHeaderWithButtons";
import { ContentWrapper } from "../../Commons/Wrappers";
import AppraisalsMap from "./AppraiserMap";
import AppraiserSelection, { ErrorType } from "./AppraiserSelection";
import { IconLoading } from "../../../../../config/icons";
import {
  InputTextStyled,
  SegmentedStyled,
  SelectStyled,
  SwitchStyled,
} from "../../../../../style/Input";
import { FormRow } from "../../../../../style/form";
import { RowSpacer } from "../../../../../style/containers";
import { ButtonConfirm } from "../../../../Layout/Buttons";

import { mainTabs } from "../../../../../config/testIds";
import ScheduleAppointment from "./ScheduleAppointment";
import AppraisalNote from "./AppraisalNote";
import AcceptPractice from "./AcceptPractice";
import CancelPractice from "./CancelPractice";
import { removeTab } from "../../../../../redux/features/mainTabsSlice";
import { useAuthorization } from "../../../../../hooks/useAuthorization";
import { fileToBase64 } from "../../../../../utils/fileUtils";
import ConcludePractice from "./ConcludePractice/ConcludePractice";
import NoContactNotification from "./NoContactNotification";
import RequestContact from "./RequestContact";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";

import { notification } from "antd";
import { NotificationPlacement } from "antd/lib/notification";
import PostConcludedPractice from "./PostConcludedPractice/PostConcludedPractice";
import moment from "moment";
import { DateFormat } from "../../../../../config/const";

const testIds = mainTabs.tabs.activities;

const AppraisalDetailsTabActivitiesWrapper = styled(ContentWrapper)`
  overflow: auto;
  max-height: calc(100vh - 16em);
  padding-right: 2em;
`;

const AssignExpertizerWrapper = styled.div`
  display: flex;
  gap: 1em;
`;

const ReactivatePracticeWrapper = styled.div`
  .is-urgent {
    .ant-segmented-item-selected {
      background-color: #5ea7e9 !important;
      color: white !important;
    }
  }

  input[readonly] {
    color: #aaa !important;
  }
`;

const LoadingInTabWrapper = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  .box {
    width: 20em;
  }
`;

const ModalContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2em;
  padding: 2em 0;

  .text {
    padding: 10px;
  }

  .icon {
    width: 100px;
  }
`;

const AddADZWrapper = styled.div``;

const showAssignAppraiser = (
  role: string | undefined,
  status: string | undefined
) =>
  status &&
  role &&
  !["CAN", "SUS"].includes(status) &&
  ["D1", "D2", "E1", "E4"].includes(role);

const showConfirmPractice = (
  role: string | undefined,
  status: string | undefined
) =>
  /*status && ["PA", "AD"].includes(status) &&*/ role &&
  ["E2", "E3"].includes(role) &&
  ["PA", "AD"].includes(status!);

const showCancelPractice = (
  role: string | undefined,
  status: string | undefined
) =>
  status &&
  role &&
  ["DA", "SC", "PA", "AD", "AF", "IV", "CP"].includes(status) &&
  ["D1", "D2"].includes(role);

const showReactivatePractice = (status: string | undefined) =>
  status && ["CAN", "SUS"].includes(status);

const showScheduleAppointment = (
  role: string | undefined,
  status: string | undefined,
  hasAppraiser: boolean | undefined
) =>
  status &&
  role &&
  ["SC", "AD", "AF", "CP"].includes(status) &&
  ["D1", "D2", "E2", "E3"].includes(role) &&
  hasAppraiser;

const showNotes = (role: string | undefined, status: string | undefined) =>
  status &&
  role &&
  !["CAN", "SUS"].includes(status) &&
  ["D1", "D2"].includes(role);

const showConcludePractice = (
  status: string | undefined,
  activeRole: string | undefined,
  appointmentDate: string | undefined
) => {
  if (["D1", "D2"].includes(activeRole!)) return false;

  if (["CP", "IV", "CO", "CI", "CR", "CF"].includes(status || "")) return true;
  else if (
    !!appointmentDate &&
    moment(appointmentDate, DateFormat).isAfter(moment(), "day") === false
  )
    return true;
  else return false;
};

const showPostConcludePractice = (
  status: string | undefined,
  activeRole: string
) => {
  return (
    ["CO", "CI", "CR", "CF"].includes(status || "") &&
    ["E1", "E4"].includes(activeRole || "")
  );
};

const showNoContactNotification = (
  status: string | undefined,
  activeRole: string
) => {
  return (
    ["D1", "D2"].includes(activeRole!) &&
    !["IV", "CO", "CI", "CR", "CF"].includes(status || "")
  );
};

const showRequestContact = (activeRole: string) => {
  return ["D1", "D2"].includes(activeRole!);
};

interface IAppraisalDetailsTabActivitiesProps {
  practice: ReadOnlyPractice | undefined;
  defaultActiveKey?: string[];
  isActive?: boolean;
  onUpdatePractice: (practice: ReadOnlyPractice) => void;
  disableMap?: boolean;
  isTest?: boolean;
  onRefreshPractice: (practice: ReadOnlyPractice) => void;
}

const AppraisalDetailsTabActivities = (
  props: IAppraisalDetailsTabActivitiesProps
) => {
  const { t } = useTranslation();

  const {
    practice,
    isActive,
    onUpdatePractice,
    disableMap,
    onRefreshPractice,
  } = props;
  const { getAuthorization } = useAuthorization();

  const { activeRole } = useSelector((state: RootState) => state.user);

  const [appraiser, setAppraiser] = useState(practice?.appraiser);
  const [appraiserError, setAppraiserError] = useState<ErrorType | undefined>();
  const [defaultActiveKey, setDefaultActiveKey] = useState(
    props.defaultActiveKey ||
      (practice?.status === "SUS"
        ? ["reactivate-practice", "conclude-practice"]
        : ["PA", "AD"].includes(practice?.status || "")
        ? ["accept-practice", "conclude-practice"]
        : ["CP"].includes(practice?.status || "")
        ? ["conclude-practice"]
        : ["CO", "CI", "CR"].includes(practice?.status || "") &&
          ["E1", "E4"].includes(activeRole!)
        ? ["post-conclude-practice"]
        : ["assign-expertizer", "conclude-practice"])
  );

  const refAddADZ = useRef<HTMLInputElement>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [assignAppraiserToPractice] = useAssignAppraiserMutation();
  const [suspendOrCancelPractice] = useSuspendOrCancelPracticeMutation();
  const [reactivatePractice] = useRestorePracticeMutation();
  const [scheduleAppointment] = useCreatePracticeAppointmentMutation();
  const [confirmPractice] = useAcceptPracticeAssignmentMutation();
  const [confirmOutOfArea] = useSetOutOfCoverageAreaMutation();

  const [createNote] = useCreatePracticeNoteMutation();
  const [updateNote] = useUpdatePracticeNoteMutation();
  const [deleteNote] = useDeletePracticeNoteMutation();

  const [showModal, setShowModal] = useState(false);

  const [isUrgentReactivatePractice, setIsUrgentReactivatePractice] =
    useState(false);
  const [urgencyReason, setUrgencyReason] = useState("");

  const [api, contextHolder] = notification.useNotification();

  const [requestContact] = useRequestContactMutation();

  const [notifyNoContact] = useNotifyNoContactMutation();

  useEffect(() => {
    setAppraiser(practice?.appraiser);
  }, [practice?.appraiser]);

  const handleAssignAppraiser = async (appraiser: Appraiser) => {
    setIsLoading(true);

    const response = await assignAppraiserToPractice({
      authorization: await getAuthorization(),
      activeRole: activeRole!,
      assignmentData: {
        idAppraiser: appraiser.id,
        idPractice: practice!.id,
      },
    });

    const updatedPractice = (
      response as {
        data: ReadOnlyPractice;
      }
    ).data as ReadOnlyPractice;

    if (updatedPractice) {
      setAppraiser(updatedPractice.appraiser);
      onUpdatePractice(updatedPractice);
    } else {
      setAppraiserError({
        message: t(
          "appraisals-details-tab-activities-select-appraiser-error-assigning"
        )!,
        timestamp: Date.now(),
      } as ErrorType);
    }

    setIsLoading(false);
  };

  const handleOnChangeAppraiser = () => {
    setAppraiser(undefined);
    onUpdatePractice({
      ...practice!,
      status: "SC", // I need to force locally the state to trigger conditional rendering
    });
  };
  const handleSuspendPractice = async () => {
    setIsLoading(true);

    const response = await suspendOrCancelPractice({
      authorization: await getAuthorization(),
      activeRole: activeRole!,
      suspendOrCancelData: {
        idPractice: practice!.id,
        practiceStatus: "SUS",
      },
    });

    const updatedPractice = (
      response as {
        data: ReadOnlyPractice;
      }
    ).data as ReadOnlyPractice;

    if (updatedPractice) {
      onUpdatePractice(updatedPractice);
    } else {
      // set error
    }

    setIsLoading(false);
    setDefaultActiveKey(["reactivate-practice"]);
  };

  const handleReactivatePractice = async () => {
    setIsLoading(true);

    const response = await reactivatePractice({
      authorization: await getAuthorization(),
      activeRole: activeRole!,
      restorePracticeData: {
        idPractice: practice!.id,
        isUrgent: isUrgentReactivatePractice,
        urgencyReason: urgencyReason,
      },
    });

    const updatedPractice = (
      response as {
        data: ReadOnlyPractice;
      }
    ).data as ReadOnlyPractice;

    if (updatedPractice) {
      onUpdatePractice(updatedPractice);
    } else {
      // set error
    }

    setIsLoading(false);
    setDefaultActiveKey(["assign-expertizer"]);
  };

  const handleScheduleOrUpdateAppointment = async (
    practiceAppointment: PracticeAppointment
  ) => {
    setIsLoading(true);
    const response = await scheduleAppointment({
      authorization: await getAuthorization(),
      activeRole: activeRole!,
      practiceAppointment,
    });

    const errorResponse = response as {
      error: {
        status: number;
        data: string;
      };
    };
    if (errorResponse && errorResponse.error) {
      setShowModal(true);
    }

    const updatedPractice = (
      response as {
        data: ReadOnlyPractice;
      }
    ).data as ReadOnlyPractice;

    if (updatedPractice) {
      const practiceWithAppraiser = {
        ...updatedPractice,
        appraiser,
      } as ReadOnlyPractice;
      onUpdatePractice(practiceWithAppraiser);
    } else {
      // set error
    }

    setIsLoading(false);
  };

  const handleSaveNote = async (
    val: PracticeNote,
    notesArr: PracticeNote[]
  ) => {
    setIsLoading(true);

    const newNote: CreatePracticeNoteApiArg = {
      authorization: await getAuthorization(),
      practiceNote: val,
    };

    const response = await createNote(newNote);
    const note = (
      response as {
        data: PracticeNote;
      }
    ).data as PracticeNote;

    if (note) {
      onUpdatePractice({
        ...practice,
        practiceNotes: [...notesArr, note],
      });
    }

    setIsLoading(false);
    setDefaultActiveKey(["note-practice"]);
  };

  const handleUpdateNote = async (
    val: PracticeNote,
    notesArr: PracticeNote[]
  ) => {
    setIsLoading(true);

    const _updateNote: UpdatePracticeNoteApiArg = {
      authorization: await getAuthorization(),
      practiceNote: val,
    };

    const response = await updateNote(_updateNote);
    const note = (
      response as {
        data: PracticeNote;
      }
    ).data as PracticeNote;

    if (note) {
      const editNotes = notesArr?.map((n: PracticeNote) => {
        if (n?.id === note?.id) {
          return note;
        } else {
          return n;
        }
      });

      onUpdatePractice({
        ...practice,
        practiceNotes: editNotes,
      });
    }

    setIsLoading(false);
    setDefaultActiveKey(["note-practice"]);
  };

  const handleDeleteNote = async (val: number) => {
    setIsLoading(true);

    const _deleteNote: DeletePracticeNoteApiArg = {
      authorization: await getAuthorization(),
      id: val,
    };

    const response = await deleteNote(_deleteNote);
    const noteDelete = (
      response as {
        data: DeletePracticeNoteApiResponse;
      }
    ).data as DeletePracticeNoteApiResponse;

    if (noteDelete?.id === val && noteDelete?.message === "") {
      onUpdatePractice({
        ...practice,
        practiceNotes: practice?.practiceNotes?.filter((x) => x.id !== val),
      });
    } else {
      message.info(
        t(
          "appraisals-details-tab-activities-collapsable-note-error-message-delete"
        )
      );
    }

    setIsLoading(false);
    setDefaultActiveKey(["note-practice"]);
  };

  const handleConfirmPractice = async () => {
    setIsLoading(true);

    const response = await confirmPractice({
      authorization: await getAuthorization(),
      idPractice: practice!.id || -1,
    });

    const updatedPractice = (
      response as {
        data: ReadOnlyPractice;
      }
    ).data as ReadOnlyPractice;

    if (updatedPractice) {
      onUpdatePractice(updatedPractice);
    } else {
      // set error
    }

    setIsLoading(false);
  };

  const getState = (): RootState => store.getState();
  const dispatch = useDispatch();

  const handleConfirmOutOfZone = async (
    subject: OutOfCoverageAppraisalSubject
  ) => {
    setIsLoading(true);

    const response = await confirmOutOfArea({
      authorization: await getAuthorization(),
      activeRole: activeRole!,
      idPractice: practice!.id || -1,
      outOfCoverageAppraisalSubject: subject,
    });

    const updatedPractice = (
      response as {
        data: ReadOnlyPractice;
      }
    ).data as ReadOnlyPractice;

    if (updatedPractice) {
      onUpdatePractice(updatedPractice);

      const activeKey = getState().mainTabs.activeKey;

      dispatch(removeTab(activeKey));
    } else {
      const errorResponse = response as {
        error: FetchBaseQueryError;
      };

      notifyError(errorResponse.error);
    }

    setIsLoading(false);
  };

  const handleCancelPractice = async (reason: string, note: string) => {
    setIsLoading(true);

    const response = await suspendOrCancelPractice({
      authorization: await getAuthorization(),
      activeRole: activeRole!,
      suspendOrCancelData: {
        idPractice: practice!.id,
        practiceStatus: "CAN",
        cancellationReason: reason,
        cancellationNote: note,
      },
    });

    const updatedPractice = (
      response as {
        data: ReadOnlyPractice;
      }
    ).data as ReadOnlyPractice;

    if (updatedPractice) {
      onUpdatePractice(updatedPractice);
    } else {
      // set error
    }

    setIsLoading(false);
    setDefaultActiveKey(["reactivate-practice"]);
  };

  const handleSelectADZ = () => {
    //
  };

  const handleAddADZ = async (e: any) => {
    if (!e) return;
    const selectedFile = e.target.files[0];

    if (!selectedFile) return;

    const fileContent = await fileToBase64(selectedFile);

    const adz = {
      id: 0,
      name: selectedFile.name,
      originalName: selectedFile.name,
      base64File: fileContent,
    } as PracticeAttachment;
  };

  const notifySuccess = (text: string) => {
    api.success({
      message: "",
      description: text,
      duration: 5,
      placement: "bottomRight" as NotificationPlacement,
    });
  };

  const notifyError = (error: FetchBaseQueryError) => {
    let description: string = "Errore";

    if (error.status === "FETCH_ERROR") {
      description = error["error"];
    } else if (["400", "404"].includes(error.status.toString())) {
      if (Array.isArray(error["data"])) {
        description = error["data"].map((m) => m).join(" | ");
      } else if (typeof error["data"] === "string") {
        description = error["data"];
      }
    } else {
      console.log(error);
    }

    api.error({
      message: "Attenzione",
      description: description,
      duration: 5,
      placement: "top" as NotificationPlacement,
    });
  };

  const handleNoContactNotificationSend = async () => {
    setIsLoading(true);

    const response = await notifyNoContact({
      idPractice: practice!.id || -1,
      authorization: await getAuthorization(),
    });

    const correctResponse = response as {
      data: NotifyNoContactApiResponse;
    };

    const errorResponse = response as {
      error: FetchBaseQueryError;
    };

    if (correctResponse.data) {
      notifySuccess("Richiesta inviata con successo.");
    } else if (errorResponse) {
      notifyError(errorResponse.error);
    }

    setIsLoading(false);
  };

  const handleRequestContactSend = async () => {
    setIsLoading(true);

    const response = await requestContact({
      idPractice: practice!.id || -1,
      authorization: await getAuthorization(),
    });

    const correctResponse = response as {
      data: RequestContactApiResponse;
    };

    const errorResponse = response as {
      error: FetchBaseQueryError;
    };

    if (correctResponse.data) {
      //alert(correctResponse.data);
    } else if (errorResponse) {
      notifyError(errorResponse.error);
    }

    setIsLoading(false);
  };

  return (
    <AppraisalDetailsTabActivitiesWrapper data-testid={testIds.container}>
      {contextHolder}

      <Collapse defaultActiveKey={defaultActiveKey}>
        {showAssignAppraiser(activeRole, practice?.status) && (
          <PanelStyled
            header={
              <PanelHeaderWithButtons
                text={t(
                  "appraisals-details-tab-activities-collapsable-assign-expertizer"
                )}
              />
            }
            key="assign-expertizer"
          >
            <AssignExpertizerWrapper>
              <AppraisalsMap isOpen={true} disable={disableMap} />

              <AppraiserSelection
                appraiser={appraiser}
                practiceStatus={practice?.status || ""}
                practiceId={practice?.id}
                onAssignAppraiser={handleAssignAppraiser}
                onChange={handleOnChangeAppraiser}
                onSuspend={handleSuspendPractice}
                error={appraiserError}
              />
            </AssignExpertizerWrapper>
          </PanelStyled>
        )}

        {showConfirmPractice(activeRole, practice?.status) && (
          <PanelStyled
            header={
              <PanelHeaderWithButtons
                text={t(
                  "appraisals-details-tab-activities-collapsable-confirm-practice"
                )}
              />
            }
            key="accept-practice"
          >
            <AcceptPractice
              practice={practice}
              onConfirmPractice={handleConfirmPractice}
              onConfirmOutOfArea={handleConfirmOutOfZone}
            />
          </PanelStyled>
        )}

        {showCancelPractice(activeRole, practice?.status) && (
          <PanelStyled
            header={
              <PanelHeaderWithButtons
                text={t(
                  "appraisals-details-tab-activities-collapsable-cancel-practice"
                )}
              />
            }
            key="cancel-practice"
          >
            <CancelPractice onCancel={handleCancelPractice} />
          </PanelStyled>
        )}

        {showReactivatePractice(practice?.status) && (
          <PanelStyled
            header={t(
              "appraisals-details-tab-activities-collapsable-reactivate-practice"
            )}
            key="reactivate-practice"
          >
            <ReactivatePracticeWrapper>
              <FormRow style={{ alignItems: "flex-end" }}>
                <SelectStyled
                  label={t("select-practice-urgency-reason-label")}
                  onChange={(val) => {
                    setUrgencyReason(val);
                  }}
                  value={urgencyReason}
                  options={[
                    { value: "", label: "" },
                    {
                      value: "DC",
                      label: t("select-practice-urgency-reason-DC"),
                    },
                    {
                      value: "TU",
                      label: t("select-practice-urgency-reason-TU"),
                    },
                    {
                      value: "RP",
                      label: t("select-practice-urgency-reason-RP"),
                    },
                  ]}
                />

                <RowSpacer />
                <SwitchStyled
                  label={t(
                    "appraisals-details-tab-activities-reactivate-practice-priority-label"
                  )}
                  onChange={(val) => {
                    setIsUrgentReactivatePractice(val);
                  }}
                  unCheckedChildren={t("switch-no")}
                  checkedChildren={t("switch-yes")}
                  checked={isUrgentReactivatePractice}
                />

                <RowSpacer />
                <Popconfirm
                  placement="leftBottom"
                  icon={null}
                  title={t(
                    "appraisals-details-tab-activities-select-appraiser-confirm-reactivate-practice"
                  )}
                  onConfirm={handleReactivatePractice}
                  okText={
                    <div data-testid={testIds.buttonReactivatePracticeConfirm}>
                      {t("switch-yes")}
                    </div>
                  }
                  cancelText={t("switch-no")}
                >
                  <ButtonConfirm
                    onClick={() => {}}
                    dataTestId={testIds.buttonReactivatePractice}
                  >
                    {
                      t(
                        "appraisals-details-tab-activities-reactivate-practice-button"
                      )!
                    }
                  </ButtonConfirm>
                </Popconfirm>
              </FormRow>
            </ReactivatePracticeWrapper>
          </PanelStyled>
        )}

        {showNotes(activeRole, practice?.status) && (
          <PanelStyled
            header={t("appraisals-note-tab-collapse-header")}
            key="note-practice"
          >
            <AppraisalNote
              idPractice={Number(practice?.id)}
              onSave={(value, notesArr) => handleSaveNote(value, notesArr)}
              onUpdate={(value, notesArr) => handleUpdateNote(value, notesArr)}
              OnDelete={(value) => handleDeleteNote(value)}
              historyNote={practice?.practiceNotes}
              isPracticeInvoiced={practice?.status === "CF"}
            />
          </PanelStyled>
        )}

        {
          // if is a BO and the practice is not SC, SUS or CAN
          props.isTest ||
            (showScheduleAppointment(
              activeRole,
              practice?.status,
              appraiser != undefined
            ) && (
              <PanelStyled
                header={
                  <PanelHeaderWithButtons
                    text={t(
                      practice?.appointment
                        ? "appraisals-details-tab-activities-collapsable-button-modify-appointment"
                        : "appraisals-details-tab-activities-collapsable-schedule-appoinment"
                    )}
                  />
                }
                key="schedule-appoinment"
                data-testid={testIds.scheduleAppointment.header}
              >
                <ScheduleAppointment
                  appointment={practice?.appointment}
                  idPractice={practice?.id}
                  onSchedule={handleScheduleOrUpdateAppointment}
                  onModify={handleScheduleOrUpdateAppointment}
                />
              </PanelStyled>
            ))
        }

        {showConcludePractice(
          practice?.status,
          activeRole,
          practice?.appointment?.appointmentDate
        ) && (
          <PanelStyled
            header={t("appraisals-conclude-practice-tab-collapse-header")}
            key="conclude-practice"
          >
            <ConcludePractice
              practice={practice}
              onRefreshPractice={onRefreshPractice}
            />
          </PanelStyled>
        )}

        {showPostConcludePractice(practice?.status, activeRole!) && (
          <PanelStyled
            header={t("appraisals-post-conclude-practice-tab-collapse-header")}
            key="post-conclude-practice"
          >
            <PostConcludedPractice
              practice={practice}
              onRefreshPractice={onRefreshPractice}
            />
          </PanelStyled>
        )}

        {showNoContactNotification(practice?.status, activeRole!) && (
          <PanelStyled
            header={t(
              "appraisals-details-tab-activities-collapsable-no-contact-notification"
            )}
            key="failure-to-contact"
          >
            <NoContactNotification
              practiceDate={practice?.practiceDate}
              onSend={handleNoContactNotificationSend}
            />
          </PanelStyled>
        )}

        {showRequestContact(activeRole!) && (
          <PanelStyled
            header={t(
              "appraisals-details-tab-activities-collapsable-request-contact"
            )}
            key="request-contact"
          >
            <RequestContact
              communicationRecipient={practice?.communicationRecipient!}
              onSend={handleRequestContactSend}
              isPracticeInvoiced={practice?.status === "CF"}
            />
          </PanelStyled>
        )}
      </Collapse>

      {isLoading && (
        <LoadingInTabWrapper data-testid={testIds.loadingContainer}>
          <div className="box">{IconLoading}</div>
        </LoadingInTabWrapper>
      )}

      {showModal && (
        <Modal
          open={showModal}
          footer={null}
          onCancel={() => setShowModal(false)}
        >
          <ModalContent>
            <div className="text">
              {t(
                "appraisals-details-tab-activities-collapsable-error-appoinment-popup"
              )}
            </div>
          </ModalContent>
        </Modal>
      )}
    </AppraisalDetailsTabActivitiesWrapper>
  );
};

export default AppraisalDetailsTabActivities;
