import { Button, Card, Modal, Segmented } from "antd";
import FormItem from "antd/es/form/FormItem";
import { useState } from "react";
import { BsGear } from "react-icons/bs";
import styled from "styled-components";
import { LocalStorageKeys } from "../config/const";
import { ConfigParams } from "../types/common.types";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { setConfigParams } from "../redux/features/userSlice";
import { IconLoading } from "../config/icons";
import { InputNumberStyled, SelectStyled } from "../style/Input";
import dekra from "../style/dekra";
import { config } from "process";

const ConfigWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10%;

  padding: 4em 3em 0 3em;

  label {
    width: 10em;
  }

  .ant-card-head {
    background-color: orange;
    color: white;
    font-weight: bold;
    letter-spacing: 2px;
  }
`;

const ConfigButtonWrapper = styled.div`
  font-size: 1.2em;
  cursor: pointer;
  color: ${dekra.primaryColor};
  margin-top: 8px;
  display: flex;
  align-items: center;

  svg {
    font-size: 1.4em;
  }
`;

const FunctionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;
  margin-bottom: 3em;
`;

const FunctionTitle = styled.div`
  display: flex;
  align-items: center;
  padding: 0 1em;
  color: white;
  background-color: orange;
  letter-spacing: 2px;
`;

const FunctionBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  padding: 1em;
`;

const FunctionItem = styled.div`
  display: flex;
  align-items: center;

  h4 {
    flex: 1;
    margin-bottom: 0;
    font-size: 0.8em;
    font-weight: 400;
  }

  .ant-segmented {
    width: 12em;
    font-size: 12px;
    padding: 0;

    .ant-segmented-item-label {
      line-height: 20px;
      min-height: 20px;
    }
  }

  .ant-segmented-item-selected {
    background-color: #5ea7e9;
    color: white;
  }
`;

const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2em;
  width: 100%;
  margin-bottom: 3em;
`;

const LoadingWrapper = styled.div`
  height: 400px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  div {
    width: 100px;
  }
`;

const options = ["BACKEND", "DEMO"];

const ConfigModal = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [settingParams, setSettingParams] = useState(false);
  const configParams = useSelector(
    (state: RootState) => state.user.configParams
  );

  const handleOnChangeBool = (value: boolean, field: string) => {
    const updatedConfigParams = {
      ...configParams,
      [field]: value,
    } as ConfigParams;
    dispatch(setConfigParams(updatedConfigParams));
  };

  const handleOnChangeNumber = (value: number, field: string) => {
    const updatedConfigParams = {
      ...configParams,
      [field]: value,
    } as ConfigParams;
    dispatch(setConfigParams(updatedConfigParams));
  };

  const handleOnSetSettings = async () => {
    setSettingParams(true);
    await fetch("/set-config-params", {
      method: "POST",
      body: JSON.stringify(configParams),
    });

    setSettingParams(false);
    setOpen(false);
  };

  const handleOnResetData = async () => {
    setSettingParams(true);
    await fetch("/reset-data", {
      method: "GET",
    });

    localStorage.removeItem(LocalStorageKeys.configParams);
    setSettingParams(false);
  };

  const getValue = (selected: boolean | undefined) =>
    selected ? options[1] : options[0];

  const handleSetAllDemo = () => {
    dispatch(
      setConfigParams({
        ...configParams,
        subject_get: true,
        subject_post: true,
        subject_put: true,
        claim_get: true,
        claim_post: true,
        claim_put: true,
        policy_get: true,
        search: true,
        attachments: true,
        check_sic: true,
        me: true,
        roleId: 100,
        dashboardD1: true,
        dashboardD2: true,
        dashboardE1: true,
        dashboardE2: true,
        dashboardE3: true,
        dashboardE4: true,
        dashboardF1: true,
        dashboardG1: true,
        dashboardG2: true,
        practiceDetails: true,
        communicationListD2: true,
        practicesStatusUpdate: true,
        practicesBlockUnblock: true,
        appraisersList: true,
        assignAppraiser: true,
        suspendPractice: true,
        reactivatePractice: true,
        scheduleOrUpdateAppointment: true,
        notesList: true,
        acceptPractice: true,
        modifyOutOfArea: true,
        saveNote: true,
        updateNote: true,
        deleteNote: true,
        historicalActionsList: true,
        dataNetworkNF: true,
        appraiserManagementStatus: true,
        appraiserDetails_get: true,
        addAppraiser_post: true,
        appraisersManagement_get: true,
        appraisersManagementZones_get: true,
        setAppraiserManagementCover_post: true,
        setAppraiserClientsPermissions_post: true,
        tenantsRates_get: true,
        tenantRates_post: true,
        tenantsRatesHistory_get: true,
        appraiserRates_get: true,
        appraiserRates_put: true,
        practiceRate_put: true,
        practiceDocuments_post: true,
        practiceDocuments_get: true,
        practiceDocumentsCategory_get: true,
        practiceAttachment_get: true,
        users_get: true,
        userConfigLists_get: true,
        practiceAttachment_delete: true,
        uploadADZ_post: true,
        confirmQuestionnaire_post: true,
        finalizePractice_post: true,
        questionnaire_get: true,
        rejectionReasonList_get: true,
        congruityReport_post: true,
        practiceRequests_get: true,
        practiceRequest_get: true,
        conservaticeAgreement_get: true,
        requestsList_get: true,
        sendRejectedFeedback: true,
        agendaAppriaser_get: true,
        agendaAppriaser_post: true,
        agendaSupplierNetwork_get: true,
        agendaDistrictAvailability_get: true,
        tenantsFees_get: true,
        tenantFees_post: true,
        remindersSupplierNetwork_get: true,
        expirationDatesConfiguration_get: true,
        assignmentMap_get: true,
        expertizeScore_get: true
      } as ConfigParams)
    );
  };

  const handleSetAllBE = () => {
    dispatch(
      setConfigParams({
        ...configParams,
        subject_get: false,
        subject_post: false,
        subject_put: false,
        claim_get: false,
        claim_post: false,
        claim_put: false,
        policy_get: false,
        search: false,
        attachments: false,
        check_sic: false,
        me: false,
        dashboardD1: false,
        dashboardD2: false,
        dashboardE1: false,
        dashboardE2: false,
        dashboardE3: false,
        dashboardE4: false,
        dashboardF1: false,
        dashboardG1: false,
        dashboardG2: false,
        practiceDetails: false,
        communicationListD2: false,
        practicesStatusUpdate: false,
        practicesBlockUnblock: false,
        appraisersList: false,
        assignAppraiser: false,
        suspendPractice: false,
        reactivatePractice: false,
        scheduleOrUpdateAppointment: false,
        notesList: false,
        acceptPractice: false,
        modifyOutOfArea: false,
        saveNote: false,
        updateNote: false,
        deleteNote: false,
        historicalActionsList: false,
        dataNetworkNF: false,
        appraiserManagementStatus: false,
        appraiserDetails_get: false,
        addAppraiser_post: false,
        appraisersManagement_get: false,
        appraisersManagementZones_get: false,
        setAppraiserManagementCover_post: false,
        setAppraiserClientsPermissions_post: false,
        tenantsRates_get: false,
        tenantRates_post: false,
        tenantsRatesHistory_get: false,
        appraiserRates_get: false,
        appraiserRates_put: false,
        practiceRate_put: false,
        practiceDocuments_post: false,
        practiceDocuments_get: false,
        practiceDocumentsCategory_get: false,
        practiceAttachment_get: false,
        practiceAttachment_delete: false,
        uploadADZ_post: false,
        users_get: false,
        userConfigLists_get: false,
        confirmQuestionnaire_post: false,
        finalizePractice_post: false,
        questionnaire_get: false,
        rejectionReasonList_get: false,
        congruityReport_post: false,
        practiceRequests_get: false,
        practiceRequest_get: false,
        conservaticeAgreement_get: false,
        requestsList_get: false,
        sendRejectedFeedback: false,
        agendaAppriaser_get: false,
        agendaAppriaser_post: false,
        agendaSupplierNetwork_get: false,
        agendaDistrictAvailability_get: false,
        tenantsFees_get: false,
        tenantFees_post: false,
        remindersSupplierNetwork_get: false,
        expirationDatesConfiguration_get: false,
        assignmentMap_get: false,
        expertizeScore_get: false
      } as ConfigParams)
    );
  };

  return (
    <>
      <ConfigButtonWrapper onClick={() => setOpen(true)}>
        <BsGear />
        <span style={{ marginLeft: "1em" }}>SETTINGS</span>
      </ConfigButtonWrapper>
      
      <Modal
        open={open}
        onCancel={() => setOpen(false)}
        footer={null}
        width="90%"
      >
        {settingParams && (
          <LoadingWrapper>
            <div>{IconLoading}</div>
          </LoadingWrapper>
        )}
        {!settingParams && (
          <ConfigWrapper>
            <FunctionWrapper>
              <FunctionTitle>SOGGETTI</FunctionTitle>
              <FunctionBody>
                <FunctionItem>
                  <h4>CARICAMENTO SOGGETTO</h4>
                  <Segmented
                    onChange={(val) =>
                      handleOnChangeBool(val === options[1], "subject_get")
                    }
                    options={options}
                    value={getValue(configParams?.subject_get)}
                  />
                </FunctionItem>

                <FunctionItem>
                  <h4>NUOVO SOGGETTO</h4>
                  <Segmented
                    onChange={(val) =>
                      handleOnChangeBool(val === options[1], "subject_post")
                    }
                    options={options}
                    value={getValue(configParams?.subject_post)}
                  />
                </FunctionItem>

                <FunctionItem>
                  <h4>MODIFICA SOGGETTO</h4>
                  <Segmented
                    onChange={(val) =>
                      handleOnChangeBool(val === options[1], "subject_put")
                    }
                    options={options}
                    value={getValue(configParams?.subject_put)}
                  />
                </FunctionItem>
              </FunctionBody>
            </FunctionWrapper>
            <FunctionWrapper>
              <FunctionTitle>SINISTRI</FunctionTitle>
              <FunctionBody>
                <FunctionItem>
                  <h4>CARICAMENTO SINISTRO</h4>
                  <Segmented
                    onChange={(val) =>
                      handleOnChangeBool(val === options[1], "claim_get")
                    }
                    options={options}
                    value={getValue(configParams?.claim_get)}
                  />
                </FunctionItem>

                <FunctionItem>
                  <h4>NUOVO SINISTRO</h4>
                  <Segmented
                    onChange={(val) =>
                      handleOnChangeBool(val === options[1], "claim_post")
                    }
                    options={options}
                    value={getValue(configParams?.claim_post)}
                  />
                </FunctionItem>

                <FunctionItem>
                  <h4>MODIFICA SINISTRO</h4>
                  <Segmented
                    onChange={(val) =>
                      handleOnChangeBool(val === options[1], "claim_put")
                    }
                    options={options}
                    value={getValue(configParams?.claim_put)}
                  />
                </FunctionItem>
              </FunctionBody>
            </FunctionWrapper>
            <FunctionWrapper>
              <FunctionTitle>POLIZZE</FunctionTitle>
              <FunctionBody>
                <FunctionItem>
                  <h4>CARICAMENTO POLIZZA</h4>
                  <Segmented
                    onChange={(val) =>
                      handleOnChangeBool(val === options[1], "policy_get")
                    }
                    options={options}
                    value={getValue(configParams?.policy_get)}
                  />
                </FunctionItem>
              </FunctionBody>
            </FunctionWrapper>
            <FunctionWrapper>
              <FunctionTitle>RICERCA</FunctionTitle>
              <FunctionBody>
                <FunctionItem>
                  <h4>CERCA SINISTRI</h4>
                  <Segmented
                    onChange={(val) =>
                      handleOnChangeBool(val === options[1], "search")
                    }
                    options={options}
                    value={getValue(configParams?.search)}
                  />
                </FunctionItem>
              </FunctionBody>
            </FunctionWrapper>
            <FunctionWrapper>
              <FunctionTitle>ALLEGATI DOCUMENTI</FunctionTitle>
              <FunctionBody>
                <FunctionItem>
                  <h4>ALLEGATI</h4>
                  <Segmented
                    onChange={(val) =>
                      handleOnChangeBool(val === options[1], "attachments")
                    }
                    options={options}
                    value={getValue(configParams?.attachments)}
                  />
                </FunctionItem>
              </FunctionBody>
            </FunctionWrapper>
            <FunctionWrapper>
              <FunctionTitle>SIC</FunctionTitle>
              <FunctionBody>
                <FunctionItem>
                  <h4>CHECK</h4>
                  <Segmented
                    onChange={(val) =>
                      handleOnChangeBool(val === options[1], "check_sic")
                    }
                    options={options}
                    value={getValue(configParams?.check_sic)}
                  />
                </FunctionItem>
              </FunctionBody>
            </FunctionWrapper>
            <FunctionWrapper>
              <FunctionTitle>PROFILI & RUOLI</FunctionTitle>
              <FunctionBody>
                <FunctionItem>
                  <h4>ME</h4>
                  <Segmented
                    onChange={(val) =>
                      handleOnChangeBool(val === options[1], "me")
                    }
                    options={options}
                    value={getValue(configParams?.me)}
                  />
                </FunctionItem>
                {configParams?.me && (
                  <FunctionItem>
                    <SelectStyled
                      options={[
                        {
                          value: 1,
                          label: "0 - Nuovo Utente senza Profilo/Ruolo",
                        },
                        {
                          value: 10,
                          label: "D1 - Utente Base BO",
                        },
                        {
                          value: 11,
                          label: "D2 - Supervisor BO",
                        },
                        {
                          value: 13,
                          label: "F1 - Cliente",
                        },
                        {
                          value: 15,
                          label: "E2 - Perito Interno",
                        },
                        {
                          value: 16,
                          label: "E3 - Perito Esterno",
                        },
                        {
                          value: 20,
                          label: "NF - Network Fornitori",
                        },
                        {
                          value: 17,
                          label: "E4 - Sala Tecnica",
                        },
                        {
                          value: 19,
                          label: "E1 - Sala Tecnica Supervisor",
                        },
                        {
                          value: 21,
                          label: "SR - Service",
                        },
                        {
                          value: 22,
                          label: "G1 - Amministrativo Contabile",
                        },
                        {
                          value: 23,
                          label: "G2 - Amministrativo Tariffe",
                        },
                        {
                          value: 100,
                          label: "TUTTI",
                        },
                      ]}
                      onChange={(val) => handleOnChangeBool(val, "roleId")}
                      value={configParams?.roleId}
                    />
                  </FunctionItem>
                )}
              </FunctionBody>
            </FunctionWrapper>
            <FunctionWrapper>
              <FunctionTitle>GENERICI</FunctionTitle>
              <FunctionBody>
                <FunctionItem>
                  <h4>AUTO REFRESH TAB DATA (sec.)</h4>
                  <InputNumberStyled
                    onChange={(val) =>
                      handleOnChangeNumber(val, "autoRefreshTabData")
                    }
                    value={configParams?.autoRefreshTabData}
                  />
                </FunctionItem>
              </FunctionBody>
            </FunctionWrapper>
            <FunctionWrapper>
              <FunctionTitle>PRATICHE</FunctionTitle>
              <FunctionBody>
                <FunctionItem>
                  <h4>DASHBOARD BO UTENTE BASE</h4>
                  <Segmented
                    onChange={(val) =>
                      handleOnChangeBool(val === options[1], "dashboardD1")
                    }
                    options={options}
                    value={getValue(configParams?.dashboardD1)}
                  />
                </FunctionItem>
                <FunctionItem>
                  <h4>DASHBOARD BO SUPERVISOR</h4>
                  <Segmented
                    onChange={(val) =>
                      handleOnChangeBool(val === options[1], "dashboardD2")
                    }
                    options={options}
                    value={getValue(configParams?.dashboardD2)}
                  />
                </FunctionItem>

                <FunctionItem>
                  <h4>DASHBOARD SALA TECNICA SUPERVISOR </h4>
                  <Segmented
                    onChange={(val) =>
                      handleOnChangeBool(val === options[1], "dashboardE1")
                    }
                    options={options}
                    value={getValue(configParams?.dashboardE1)}
                  />
                </FunctionItem>

                <FunctionItem>
                  <h4>DASHBOARD PERITO INTERNO</h4>
                  <Segmented
                    onChange={(val) =>
                      handleOnChangeBool(val === options[1], "dashboardE2")
                    }
                    options={options}
                    value={getValue(configParams?.dashboardE2)}
                  />
                </FunctionItem>

                <FunctionItem>
                  <h4>DASHBOARD PERITO ESTERNO</h4>
                  <Segmented
                    onChange={(val) =>
                      handleOnChangeBool(val === options[1], "dashboardE3")
                    }
                    options={options}
                    value={getValue(configParams?.dashboardE2)}
                  />
                </FunctionItem>

                <FunctionItem>
                  <h4>DASHBOARD SALA TECNICA </h4>
                  <Segmented
                    onChange={(val) =>
                      handleOnChangeBool(val === options[1], "dashboardE4")
                    }
                    options={options}
                    value={getValue(configParams?.dashboardE4)}
                  />
                </FunctionItem>

                <FunctionItem>
                  <h4>DASHBOARD CLIENTE</h4>
                  <Segmented
                    onChange={(val) =>
                      handleOnChangeBool(val === options[1], "dashboardF1")
                    }
                    options={options}
                    value={getValue(configParams?.dashboardF1)}
                  />
                </FunctionItem>

                <FunctionItem>
                  <h4>DETTAGLIO PRATICA</h4>
                  <Segmented
                    onChange={(val) =>
                      handleOnChangeBool(val === options[1], "practiceDetails")
                    }
                    options={options}
                    value={getValue(configParams?.practiceDetails)}
                  />
                </FunctionItem>

                <FunctionItem>
                  <h4>LISTA COMUNICAZIONI</h4>
                  <Segmented
                    onChange={(val) =>
                      handleOnChangeBool(
                        val === options[1],
                        "communicationListD2"
                      )
                    }
                    options={options}
                    value={getValue(configParams?.communicationListD2)}
                  />
                </FunctionItem>

                <FunctionItem>
                  <h4>UPDATE STATO PRATICHE</h4>
                  <Segmented
                    onChange={(val) =>
                      handleOnChangeBool(
                        val === options[1],
                        "practicesStatusUpdate"
                      )
                    }
                    options={options}
                    value={getValue(configParams?.practicesStatusUpdate)}
                  />
                </FunctionItem>

                <FunctionItem>
                  <h4>BLOCK / UNBLOCK</h4>
                  <Segmented
                    onChange={(val) =>
                      handleOnChangeBool(
                        val === options[1],
                        "practicesBlockUnblock"
                      )
                    }
                    options={options}
                    value={getValue(configParams?.practicesBlockUnblock)}
                  />
                </FunctionItem>
              </FunctionBody>
            </FunctionWrapper>
            <FunctionWrapper>
              <FunctionTitle>PERITI</FunctionTitle>
              <FunctionBody>
                <FunctionItem>
                  <h4>CARICAMENTO LISTA PERITI</h4>
                  <Segmented
                    onChange={(val) =>
                      handleOnChangeBool(val === options[1], "appraisersList")
                    }
                    options={options}
                    value={getValue(configParams?.appraisersList)}
                  />
                </FunctionItem>

                <FunctionItem>
                  <h4>ASSEGNAZIONE PERITO</h4>
                  <Segmented
                    onChange={(val) =>
                      handleOnChangeBool(val === options[1], "assignAppraiser")
                    }
                    options={options}
                    value={getValue(configParams?.assignAppraiser)}
                  />
                </FunctionItem>

                <FunctionItem>
                  <h4>SOSPENDI PRATICA</h4>
                  <Segmented
                    onChange={(val) =>
                      handleOnChangeBool(val === options[1], "suspendPractice")
                    }
                    options={options}
                    value={getValue(configParams?.suspendPractice)}
                  />
                </FunctionItem>

                <FunctionItem>
                  <h4>RIATTIVA PRATICA</h4>
                  <Segmented
                    onChange={(val) =>
                      handleOnChangeBool(
                        val === options[1],
                        "reactivatePractice"
                      )
                    }
                    options={options}
                    value={getValue(configParams?.reactivatePractice)}
                  />
                </FunctionItem>

                <FunctionItem>
                  <h4>PRESA VISIONE RESPINGIMENTO PRATICA</h4>
                  <Segmented
                    onChange={(val) =>
                      handleOnChangeBool(
                        val === options[1],
                        "sendRejectedFeedback"
                      )
                    }
                    options={options}
                    value={getValue(configParams?.sendRejectedFeedback)}
                  />
                </FunctionItem>
              </FunctionBody>
            </FunctionWrapper>
            <FunctionWrapper>
              <FunctionTitle>ATTIVITA'</FunctionTitle>
              <FunctionBody>
                <FunctionItem>
                  <h4>FISSA O AGGIORNA APPUNTAMENTO</h4>
                  <Segmented
                    onChange={(val) =>
                      handleOnChangeBool(
                        val === options[1],
                        "scheduleOrUpdateAppointment"
                      )
                    }
                    options={options}
                    value={getValue(configParams?.scheduleOrUpdateAppointment)}
                  />
                </FunctionItem>
                <FunctionItem>
                  <h4>ACCETTA PRATICA</h4>
                  <Segmented
                    onChange={(val) =>
                      handleOnChangeBool(val === options[1], "acceptPractice")
                    }
                    options={options}
                    value={getValue(configParams?.acceptPractice)}
                  />
                </FunctionItem>

                <FunctionItem>
                  <h4>MODIFICA IN FUORIZONA</h4>
                  <Segmented
                    onChange={(val) =>
                      handleOnChangeBool(val === options[1], "modifyOutOfArea")
                    }
                    options={options}
                    value={getValue(configParams?.modifyOutOfArea)}
                  />
                </FunctionItem>

                <FunctionItem>
                  <h4>LISTA MOTIVI RESPINGI PRATICA</h4>
                  <Segmented
                    onChange={(val) =>
                      handleOnChangeBool(
                        val === options[1],
                        "rejectionReasonList_get"
                      )
                    }
                    options={options}
                    value={getValue(configParams?.rejectionReasonList_get)}
                  />
                </FunctionItem>
              </FunctionBody>
            </FunctionWrapper>
            <FunctionWrapper>
              <FunctionTitle>NOTES</FunctionTitle>
              <FunctionBody>
                <FunctionItem>
                  <h4>CARICAMENTO LISTA NOTE</h4>
                  <Segmented
                    onChange={(val) =>
                      handleOnChangeBool(val === options[1], "notesList")
                    }
                    options={options}
                    value={getValue(configParams?.notesList)}
                  />
                </FunctionItem>
                <FunctionItem>
                  <h4>SALVA NOTA</h4>
                  <Segmented
                    onChange={(val) =>
                      handleOnChangeBool(val === options[1], "saveNote")
                    }
                    options={options}
                    value={getValue(configParams?.saveNote)}
                  />
                </FunctionItem>
                <FunctionItem>
                  <h4>DELTE NOTA</h4>
                  <Segmented
                    onChange={(val) =>
                      handleOnChangeBool(val === options[1], "deleteNote")
                    }
                    options={options}
                    value={getValue(configParams?.deleteNote)}
                  />
                </FunctionItem>
                <FunctionItem>
                  <h4>UPDATE NOTA</h4>
                  <Segmented
                    onChange={(val) =>
                      handleOnChangeBool(val === options[1], "updateNote")
                    }
                    options={options}
                    value={getValue(configParams?.updateNote)}
                  />
                </FunctionItem>
              </FunctionBody>
            </FunctionWrapper>
            <FunctionWrapper>
              <FunctionTitle>NETWORK FORNITORI</FunctionTitle>
              <FunctionBody>
                <FunctionItem>
                  <h4>DASHBOARD NF</h4>
                  <Segmented
                    onChange={(val) =>
                      handleOnChangeBool(val === options[1], "dataNetworkNF")
                    }
                    options={options}
                    value={getValue(configParams?.dataNetworkNF)}
                  />
                </FunctionItem>
                <FunctionItem>
                  <h4>STATO PERITO (ADMIN)</h4>
                  <Segmented
                    onChange={(val) =>
                      handleOnChangeBool(
                        val === options[1],
                        "appraiserManagementStatus"
                      )
                    }
                    options={options}
                    value={getValue(configParams?.appraiserManagementStatus)}
                  />
                </FunctionItem>

                <FunctionItem>
                  <h4>DETTAGLIO PERITO (ADMIN)</h4>
                  <Segmented
                    onChange={(val) =>
                      handleOnChangeBool(
                        val === options[1],
                        "appraiserDetails_get"
                      )
                    }
                    options={options}
                    value={getValue(configParams?.appraiserDetails_get)}
                  />
                </FunctionItem>

                <FunctionItem>
                  <h4>AGGIUNGI PERITO (ADMIN)</h4>
                  <Segmented
                    onChange={(val) =>
                      handleOnChangeBool(
                        val === options[1],
                        "addAppraiser_post"
                      )
                    }
                    options={options}
                    value={getValue(configParams?.addAppraiser_post)}
                  />
                </FunctionItem>

                <FunctionItem>
                  <h4>GET RUOLO PERITO(ADMIN)</h4>
                  <Segmented
                    onChange={(val) =>
                      handleOnChangeBool(
                        val === options[1],
                        "appraisersManagement_get"
                      )
                    }
                    options={options}
                    value={getValue(configParams?.appraisersManagement_get)}
                  />
                </FunctionItem>

                <FunctionItem>
                  <h4>GET APPRAISER MANAG. ZONES</h4>
                  <Segmented
                    onChange={(val) =>
                      handleOnChangeBool(
                        val === options[1],
                        "appraisersManagementZones_get"
                      )
                    }
                    options={options}
                    value={getValue(
                      configParams?.appraisersManagementZones_get
                    )}
                  />
                </FunctionItem>

                <FunctionItem>
                  <h4>POST APPRAISER MANAG. COVERAGE</h4>
                  <Segmented
                    onChange={(val) =>
                      handleOnChangeBool(
                        val === options[1],
                        "setAppraiserManagementCover_post"
                      )
                    }
                    options={options}
                    value={getValue(
                      configParams?.setAppraiserManagementCover_post
                    )}
                  />
                </FunctionItem>

                <FunctionItem>
                  <h4>POST APPRAISER CLIENTS PERMISSIONS</h4>
                  <Segmented
                    onChange={(val) =>
                      handleOnChangeBool(
                        val === options[1],
                        "setAppraiserClientsPermissions_post"
                      )
                    }
                    options={options}
                    value={getValue(
                      configParams?.setAppraiserClientsPermissions_post
                    )}
                  />
                </FunctionItem>
              </FunctionBody>
            </FunctionWrapper>
            <FunctionWrapper>
              <FunctionTitle>AMMINISTRATIVO CONTABILE/TARIFFE</FunctionTitle>
              <FunctionBody>
                <FunctionItem>
                  <h4>DASHBOARD AMMINISTRATIVO CONTABILE</h4>
                  <Segmented
                    onChange={(val) =>
                      handleOnChangeBool(val === options[1], "dashboardG1")
                    }
                    options={options}
                    value={getValue(configParams?.dashboardG1)}
                  />
                </FunctionItem>
                <FunctionItem>
                  <h4>DASHBOARD AMMINISTRATIVO TARIFFE</h4>
                  <Segmented
                    onChange={(val) =>
                      handleOnChangeBool(val === options[1], "dashboardG2")
                    }
                    options={options}
                    value={getValue(configParams?.dashboardG2)}
                  />
                </FunctionItem>
              </FunctionBody>
            </FunctionWrapper>
            <FunctionWrapper>
              <FunctionTitle>TARIFFE</FunctionTitle>
              <FunctionBody>
                <FunctionItem>
                  <h4>CARICAMENTO TARIFFE CLIENTI</h4>
                  <Segmented
                    onChange={(val) =>
                      handleOnChangeBool(val === options[1], "tenantsRates_get")
                    }
                    options={options}
                    value={getValue(configParams?.tenantsRates_get)}
                  />
                </FunctionItem>

                <FunctionItem>
                  <h4>SALVA TARIFFE CLIENTE</h4>
                  <Segmented
                    onChange={(val) =>
                      handleOnChangeBool(val === options[1], "tenantRates_post")
                    }
                    options={options}
                    value={getValue(configParams?.tenantRates_post)}
                  />
                </FunctionItem>

                <FunctionItem>
                  <h4>CARICAMENTO STORICO TARIFFE</h4>
                  <Segmented
                    onChange={(val) =>
                      handleOnChangeBool(
                        val === options[1],
                        "tenantsRatesHistory_get"
                      )
                    }
                    options={options}
                    value={getValue(configParams?.tenantsRatesHistory_get)}
                  />
                </FunctionItem>

                <FunctionItem>
                  <h4>CARICAMENTO TARIFFE APPRAISER</h4>
                  <Segmented
                    onChange={(val) =>
                      handleOnChangeBool(
                        val === options[1],
                        "appraiserRates_get"
                      )
                    }
                    options={options}
                    value={getValue(configParams?.appraiserRates_get)}
                  />
                </FunctionItem>

                <FunctionItem>
                  <h4>SALVA TARIFFE APPRAISER</h4>
                  <Segmented
                    onChange={(val) =>
                      handleOnChangeBool(
                        val === options[1],
                        "appraiserRates_put"
                      )
                    }
                    options={options}
                    value={getValue(configParams?.appraiserRates_put)}
                  />
                </FunctionItem>

                <FunctionItem>
                  <h4>SALVA TARIFFA PRATICA</h4>
                  <Segmented
                    onChange={(val) =>
                      handleOnChangeBool(val === options[1], "practiceRate_put")
                    }
                    options={options}
                    value={getValue(configParams?.practiceRate_put)}
                  />
                </FunctionItem>
              </FunctionBody>
            </FunctionWrapper>
            <FunctionWrapper>
              <FunctionTitle>PARCELLE</FunctionTitle>
              <FunctionBody>
                <FunctionItem>
                  <h4>CARICAMENTO PARCELLE CLIENTI</h4>
                  <Segmented
                    onChange={(val) =>
                      handleOnChangeBool(val === options[1], "tenantsFees_get")
                    }
                    options={options}
                    value={getValue(configParams?.tenantsFees_get)}
                  />
                </FunctionItem>

                <FunctionItem>
                  <h4>SALVA PARCELLE CLIENTE</h4>
                  <Segmented
                    onChange={(val) =>
                      handleOnChangeBool(val === options[1], "tenantFees_post")
                    }
                    options={options}
                    value={getValue(configParams?.tenantFees_post)}
                  />
                </FunctionItem>
              </FunctionBody>
            </FunctionWrapper>
            <FunctionWrapper>
              <FunctionTitle>HISTORICAL ACTIONS</FunctionTitle>
              <FunctionBody>
                <FunctionItem>
                  <h4>CARICAMENTO STORICO AZIONI</h4>
                  <Segmented
                    onChange={(val) =>
                      handleOnChangeBool(
                        val === options[1],
                        "historicalActionsList"
                      )
                    }
                    options={options}
                    value={getValue(configParams?.historicalActionsList)}
                  />
                </FunctionItem>
              </FunctionBody>
            </FunctionWrapper>
            <FunctionWrapper>
              <FunctionTitle>DOCUMENTI PRATICA</FunctionTitle>
              <FunctionBody>
                <FunctionItem>
                  <h4>CARICA CATEGORIE DOCUMENTI</h4>
                  <Segmented
                    onChange={(val) =>
                      handleOnChangeBool(
                        val === options[1],
                        "practiceDocumentsCategory_get"
                      )
                    }
                    options={options}
                    value={getValue(
                      configParams?.practiceDocumentsCategory_get
                    )}
                  />
                </FunctionItem>

                <FunctionItem>
                  <h4>SALVA DOCUMENTI</h4>
                  <Segmented
                    onChange={(val) =>
                      handleOnChangeBool(
                        val === options[1],
                        "practiceDocuments_post"
                      )
                    }
                    options={options}
                    value={getValue(configParams?.practiceDocuments_post)}
                  />
                </FunctionItem>

                <FunctionItem>
                  <h4>CARICA DOCUMENTI</h4>
                  <Segmented
                    onChange={(val) =>
                      handleOnChangeBool(
                        val === options[1],
                        "practiceDocuments_get"
                      )
                    }
                    options={options}
                    value={getValue(configParams?.practiceDocuments_get)}
                  />
                </FunctionItem>

                <FunctionItem>
                  <h4>DOWNLOAD ALLEGATO</h4>
                  <Segmented
                    onChange={(val) =>
                      handleOnChangeBool(
                        val === options[1],
                        "practiceAttachment_get"
                      )
                    }
                    options={options}
                    value={getValue(configParams?.practiceAttachment_get)}
                  />
                </FunctionItem>

                <FunctionItem>
                  <h4>DELETE ALLEGATO</h4>
                  <Segmented
                    onChange={(val) =>
                      handleOnChangeBool(
                        val === options[1],
                        "practiceAttachment_delete"
                      )
                    }
                    options={options}
                    value={getValue(configParams?.practiceAttachment_delete)}
                  />
                </FunctionItem>

                <FunctionItem>
                  <h4>CARICA ADZ</h4>
                  <Segmented
                    onChange={(val) =>
                      handleOnChangeBool(val === options[1], "uploadADZ_post")
                    }
                    options={options}
                    value={getValue(configParams?.uploadADZ_post)}
                  />
                </FunctionItem>
              </FunctionBody>
            </FunctionWrapper>
            <FunctionWrapper>
              <FunctionTitle>UTENTI</FunctionTitle>
              <FunctionBody>
                <FunctionItem>
                  <h4>LISTA UTENTI</h4>
                  <Segmented
                    onChange={(val) =>
                      handleOnChangeBool(val === options[1], "users_get")
                    }
                    options={options}
                    value={getValue(configParams?.users_get)}
                  />
                </FunctionItem>

                <FunctionItem>
                  <h4>CONFIGURAZIONI UTENTE</h4>
                  <Segmented
                    onChange={(val) =>
                      handleOnChangeBool(
                        val === options[1],
                        "userConfigLists_get"
                      )
                    }
                    options={options}
                    value={getValue(configParams?.userConfigLists_get)}
                  />
                </FunctionItem>
              </FunctionBody>
            </FunctionWrapper>

            <FunctionWrapper>
              <FunctionTitle>CONCLUSIONE PRATICA</FunctionTitle>
              <FunctionBody>
                <FunctionItem>
                  <h4>SALVA QUESTIONARIO</h4>
                  <Segmented
                    onChange={(val) =>
                      handleOnChangeBool(
                        val === options[1],
                        "confirmQuestionnaire_post"
                      )
                    }
                    options={options}
                    value={getValue(configParams?.confirmQuestionnaire_post)}
                  />
                </FunctionItem>

                <FunctionItem>
                  <h4>FINALIZZA PRATICA</h4>
                  <Segmented
                    onChange={(val) =>
                      handleOnChangeBool(
                        val === options[1],
                        "finalizePractice_post"
                      )
                    }
                    options={options}
                    value={getValue(configParams?.finalizePractice_post)}
                  />
                </FunctionItem>

                <FunctionItem>
                  <h4>GET QUESTIONARIO</h4>
                  <Segmented
                    onChange={(val) =>
                      handleOnChangeBool(
                        val === options[1],
                        "questionnaire_get"
                      )
                    }
                    options={options}
                    value={getValue(configParams?.questionnaire_get)}
                  />
                </FunctionItem>

                <FunctionItem>
                  <h4>RAPPORTO CONGRUITA'</h4>
                  <Segmented
                    onChange={(val) =>
                      handleOnChangeBool(
                        val === options[1],
                        "congruityReport_post"
                      )
                    }
                    options={options}
                    value={getValue(configParams?.congruityReport_post)}
                  />
                </FunctionItem>

                <FunctionItem>
                  <h4>RICHIESTA CONGUITA'</h4>
                  <Segmented
                    onChange={(val) =>
                      handleOnChangeBool(
                        val === options[1],
                        "conservaticeAgreement_get"
                      )
                    }
                    options={options}
                    value={getValue(configParams?.conservaticeAgreement_get)}
                  />
                </FunctionItem>
              </FunctionBody>
            </FunctionWrapper>

            <FunctionWrapper>
              <FunctionTitle>RICHIESTE PRATICA</FunctionTitle>
              <FunctionBody>
                <FunctionItem>
                  <h4>LISTA RICHIESTE PRATICA</h4>
                  <Segmented
                    onChange={(val) =>
                      handleOnChangeBool(
                        val === options[1],
                        "practiceRequests_get"
                      )
                    }
                    options={options}
                    value={getValue(configParams?.practiceRequests_get)}
                  />
                </FunctionItem>

                <FunctionItem>
                  <h4>RICHIESTA PRATICA</h4>
                  <Segmented
                    onChange={(val) =>
                      handleOnChangeBool(
                        val === options[1],
                        "practiceRequest_get"
                      )
                    }
                    options={options}
                    value={getValue(configParams?.practiceRequest_get)}
                  />
                </FunctionItem>

                <FunctionItem>
                  <h4>LISTA RICHIESTE DASHBOARD</h4>
                  <Segmented
                    onChange={(val) =>
                      handleOnChangeBool(val === options[1], "requestsList_get")
                    }
                    options={options}
                    value={getValue(configParams?.requestsList_get)}
                  />
                </FunctionItem>
              </FunctionBody>
            </FunctionWrapper>

            <FunctionWrapper>
              <FunctionTitle>AGENDA</FunctionTitle>
              <FunctionBody>
                <FunctionItem>
                  <h4>GET AGENDA PERITO</h4>
                  <Segmented
                    onChange={(val) =>
                      handleOnChangeBool(
                        val === options[1],
                        "agendaAppriaser_get"
                      )
                    }
                    options={options}
                    value={getValue(configParams?.agendaAppriaser_get)}
                  />
                </FunctionItem>

                <FunctionItem>
                  <h4>POST AGENDA PERITO</h4>
                  <Segmented
                    onChange={(val) =>
                      handleOnChangeBool(
                        val === options[1],
                        "agendaAppriaser_post"
                      )
                    }
                    options={options}
                    value={getValue(configParams?.agendaAppriaser_post)}
                  />
                </FunctionItem>

                <FunctionItem>
                  <h4>GET AGENDA NETWORK FORNITORI</h4>
                  <Segmented
                    onChange={(val) =>
                      handleOnChangeBool(
                        val === options[1],
                        "agendaSupplierNetwork_get"
                      )
                    }
                    options={options}
                    value={getValue(configParams?.agendaSupplierNetwork_get)}
                  />
                </FunctionItem>

                <FunctionItem>
                  <h4>GET AGENDA DISPOBILITA' PROVINCIA</h4>
                  <Segmented
                    onChange={(val) =>
                      handleOnChangeBool(
                        val === options[1],
                        "agendaDistrictAvailability_get"
                      )
                    }
                    options={options}
                    value={getValue(
                      configParams?.agendaDistrictAvailability_get
                    )}
                  />
                </FunctionItem>
              </FunctionBody>
            </FunctionWrapper>

            <FunctionWrapper>
              <FunctionTitle>SOLLECITI</FunctionTitle>
              <FunctionBody>
                <FunctionItem>
                  <h4>CARICAMENTO DASHBOARD</h4>
                  <Segmented
                    onChange={(val) =>
                      handleOnChangeBool(
                        val === options[1],
                        "remindersSupplierNetwork_get"
                      )
                    }
                    options={options}
                    value={getValue(configParams?.remindersSupplierNetwork_get)}
                  />
                </FunctionItem>
              </FunctionBody>
            </FunctionWrapper>

            <FunctionWrapper>
              <FunctionTitle>CONFIGURAZIONE DATE SCADENZE</FunctionTitle>
              <FunctionBody>
                <FunctionItem>
                  <h4>CONFIGURAZIONE DATE SCADENZE</h4>
                  <Segmented
                    onChange={(val) =>
                      handleOnChangeBool(
                        val === options[1],
                        "expirationDatesConfiguration_get"
                      )
                    }
                    options={options}
                    value={getValue(configParams?.expirationDatesConfiguration_get)}
                  />
                </FunctionItem>
              </FunctionBody>
            </FunctionWrapper>

            <FunctionWrapper>
              <FunctionTitle>ASSEGNAZIONE GEOGRAFICA</FunctionTitle>
              <FunctionBody>
              <FunctionItem>
                  <h4>ASSEGNAZIONE GEOGRAFICA</h4>
                  <Segmented
                    onChange={(val) =>
                      handleOnChangeBool(
                        val === options[1],
                        "assignmentMap_get"
                      )
                    }
                    options={options}
                    value={getValue(configParams?.assignmentMap_get)}
                  />
                </FunctionItem>
              </FunctionBody>
            </FunctionWrapper>

            <FunctionWrapper>
              <FunctionTitle>SCORE PERITO</FunctionTitle>
              <FunctionBody>
                <FunctionItem>
                  <h4>GET SCORE PERITO</h4>
                  <Segmented
                    onChange={(val) =>
                      handleOnChangeBool(val === options[1], "expertizeScore_get")
                    }
                    options={options}
                    value={getValue(configParams?.expertizeScore_get)}
                  />
                </FunctionItem>
              </FunctionBody>
            </FunctionWrapper>

            <ActionsWrapper>
              <Button onClick={handleSetAllDemo}>SET ALL DEMO</Button>
              <Button onClick={handleSetAllBE}>SET ALL BE</Button>
              <Button type="primary" onClick={handleOnSetSettings}>
                UPDATE SETTINGS
              </Button>
              <Button type="primary" onClick={handleOnResetData}>
                RESET DATA
              </Button>
            </ActionsWrapper>
          </ConfigWrapper>
        )}
      </Modal>
    </>
  );
};

export default ConfigModal;
