import { useTranslation } from "react-i18next";
import styled from "styled-components";
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import { useEffect, useMemo, useState } from "react";
import {
  PracticeAppointment,
  PracticeData,
  PracticesProcessingStatus,
  ReadOnlyPractice,
  useAcceptPracticeAssignmentMutation,
  useCreatePracticeAppointmentMutation,
  useLazyGetPracticeDetailQuery,
  useLazyGetPracticesStatusQuery,
} from "../../../redux/apiSpecifications/apiCrud";
import { useDispatch, useSelector } from "react-redux";
import { addTab } from "../../../redux/features/mainTabsSlice";
import { widgets } from "../../../config/testIds";
import { WidgetWrapper } from "../../../style/DashbordWidgetWrappers";
import { truncText } from "../../../utils/text";
import { useInterval } from "../../../hooks/useInterval";
import { RootState } from "../../../redux/store";
import { Modal } from "antd";
import { IconLoading, IconLocked } from "../../../config/icons";
import { ButtonConfirm } from "../../Layout/Buttons";
import dekra from "../../../style/dekra";
import { ExpertizerListItemType } from "../Expertizer/ExpertizerList";
import ScheduleAppointment from "../../MainTabs/MainTabContent/AppraisalsDetails/AppraisalDetailsActivities/ScheduleAppointment";
import { ExpertizerStatusType } from "../../../config/const";
import { useAuthorization } from "../../../hooks/useAuthorization";
const testId = widgets.appraisalsList;

const AppraisalsListWrapper = styled(WidgetWrapper)`
  align-items: flex-start;
  max-width: 100%;
  overflow: auto;
  padding: 2em;

  .MuiPaper-root {
    box-shadow: none;
    width: 100%;
  }

  .MuiToolbar-root > .MuiBox-root {
    justify-content: flex-start;
  }

  .MuiTableCell-root {
    padding: 0;
  }

  .confirm-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2em;
    height: 2em;
  }

  .MuiDialog-container {
    .MuiPaper-root {
      padding: 0 2em !important;
    }
  }

  .MuiTableRow-head {
    .MuiInputBase-root {
      input {
        margin-left: 5px;
      }
    }
  }
`;

const AppraisalsListWithoutWrapper = styled.div<{ gridArea?: string }>`
  grid-area: ${(props) => props.gridArea};

  .MuiPaper-root {
    box-shadow: none;
  }

  .MuiToolbar-root > .MuiBox-root {
    justify-content: flex-start;
  }

  .MuiTableCell-root {
    padding: 0;
  }

  .MuiTableRow-head {
    .MuiInputBase-root {
      input {
        margin-left: 5px;
      }
    }
  }
`;

const CellWrapper = styled.div`
  cursor: pointer;
  padding: 1em 0;

  &.blocked-practice-row {
    color: #888;
  }
`;

export const BlockedWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 0.6em;
  font-size: 1.6em;
  color: #c44f4f;
`;

const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    font-size: 0.9em;
  }
`;

const ModalContent = styled.div`
  padding: 4em 6em;
  text-align: center;

  .text {
    font-size: 16px;
  }

  .to-liquidate {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 28px;
    color: ${dekra.primaryColor};
    margin: 1em 0;
  }

  .total {
    font-size: 20px;
    font-style: italic;
  }
`;

const ModalContentError = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2em;
  padding: 2em 0;

  .text {
    padding: 10px;
  }

  .icon {
    width: 100px;
  }
`;

export type AppraisalsResultItem = {
  id: number;
  priority: string;
  practiceNumber: string;
  sxNumber: string;
  plate: string;
  model: string;
  cap: string;
  city: string;
  province: string;
  insertDate: string;
  expirationDate: string;
  study: string;
  status: string;
  statusCode: string;
  isBlocked: boolean;
  blockedBy: string;
  actions: string;
  customerExpirationDate: string;
  practiceType: string;
  appraiserExpirationDate: string;
  appraiserPriority: string;
  customerIdPractice: string;
  isNegative: boolean;
  chassisNumber: string;
};

export type AppraisalsListItemType = PracticeData & {
  isBlocked: boolean;
  blockedBy: string;
};

interface IAppraisalsListProps {
  items: PracticeData[] | undefined;
  removeWrapper?: boolean;
  gridArea?: string;
  isActive?: boolean;
  scenario?: "dashboard_bo" | "dashboard_st" | "search_result" | undefined;
  onRefresh: (status: ExpertizerStatusType) => void;
}

const AppraisalsList = (props: IAppraisalsListProps) => {
  const dispatch = useDispatch();
  const { items, removeWrapper, gridArea, isActive, scenario, onRefresh } =
    props;
  const { t } = useTranslation();

  const { getAuthorization } = useAuthorization();

  const { activeRole } = useSelector((state: RootState) => state.user);
  const [retrivePracticesStatus] = useLazyGetPracticesStatusQuery();
  const [confirmPractice] = useAcceptPracticeAssignmentMutation();

  const [acceptingPracticeId, setAcceptingPracticeId] = useState<number>(0);

  const [practicesToMonitoringStatus, setPracticesToMonitoringStatus] =
    useState<number[]>([]);

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const [updatedItems, setUpdatedItems] = useState<
    AppraisalsListItemType[] | undefined
  >(items as AppraisalsListItemType[]);

  const [scheduleAppointment] = useCreatePracticeAppointmentMutation();
  const [retrievePractice] = useLazyGetPracticeDetailQuery();
  const [showAppointmentDialog, setShowAppointmentDialog] =
    useState<boolean>(false);
  const [schedulingAppointmentPracticeId, setSchedulingAppointmentPracticeId] =
    useState<number>(0);

  const showSupervisorAction = (role: string): boolean => {
    return ["D2"].includes(role);
  };

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setUpdatedItems(items as AppraisalsListItemType[]);
  }, [items]);

  useEffect(() => {
    const pageItems = items?.slice(
      pagination.pageIndex * pagination.pageSize,
      (pagination.pageIndex + 1) * pagination.pageSize
    );

    const updatedPracticesToMonitor = (
      pageItems?.filter((item) => item.id !== undefined) || []
    ).map((item) => item.id as number);

    setPracticesToMonitoringStatus(updatedPracticesToMonitor);
  }, [items, pagination.pageIndex, pagination.pageSize]);

  const data = useMemo(
    () =>
      (items as AppraisalsListItemType[])?.map((item) => {
        const status = t(`appraisal-statuses-${item.status}`) || "";

        return {
          id: item.id,
          priority: t(
            `appraisals-dashboard-results-appraisals-priority-${item.priority}`
          )!,
          practiceNumber: item.practiceNumber || "",
          sxNumber: item.claimNumber || "",
          plate: item.plateNumber || "",
          model: t(`vehicle-type-${item.vehicleTypeCode}`) || "",
          cap: item.appraisalLocationAddress?.postalCode,
          city:
            item.appraisalLocationAddress?.city +
            " " +
            `(${item.appraisalLocationAddress?.province})`,
          insertDate: item.practiceDate || "",
          expirationDate: item.expirationDate || "",
          study: item.appraiser,
          status,
          statusCode: item.status,
          isBlocked: item.isBlocked,
          blockedBy: item.blockedBy,
          actions: "",
          customerExpirationDate: item.expirationDateClient || "",
          practiceType: item.practiceTypeCode || "",
          appraiserExpirationDate: item.expirationDateAppraiser || "",
          appraiserPriority: item.priorityAppraiser || "",
          customerIdPractice: item.practiceNumberCompany || "",
          isNegative: item.isNegative || undefined,
          chassisNumber: item.chassisNumber || "",
        } as AppraisalsResultItem;
      }) || [],
    [items]
  );

  const updatePracticesStatus = async () => {
    if (practicesToMonitoringStatus.length < 1) return;

    const authorization = await getAuthorization();
    const response = await retrivePracticesStatus({
      authorization,
      practices: practicesToMonitoringStatus,
      activeRole: activeRole!,
    });
    const statuses = response.data as PracticesProcessingStatus;

    if (response.isSuccess && statuses) {
      // updatedItems è aggiornato???????
      const _updatedItems = (
        (items as AppraisalsListItemType[])?.filter(
          (i) => i.id !== undefined
        ) || []
      ).map((item) => {
        const status = statuses.find((s) => s.practiceId === item.id);

        if (status === undefined) return item;

        return {
          ...item,
          status: status.practiceStatusCode,
          blockedBy: status.blockedBy || "",
          isBlocked: status.blockedBy ? status.blockedBy.length > 0 : false,
        };
      });

      setUpdatedItems(_updatedItems);
    }
  };

  const {
    restart: restartRefrehInterval,
    reset: resetRefrehInterval,
    clear: clearRefreshInterval,
    percentage,
    remainingTime,
  } = useInterval({
    seconds: 10,
    callback: () => updatePracticesStatus(),
  });

  useEffect(() => {
    return () => clearRefreshInterval();
  }, []);

  useEffect(() => {
    if (isActive) restartRefrehInterval();
    else clearRefreshInterval();
  }, [isActive]);

  const handleSelectRow = (row: AppraisalsResultItem) => {
    dispatch(
      addTab({
        key: 0,
        label: row.practiceNumber,
        type: "appraisal-details",
        externalData: {
          idPractice: row.id,
        },
      })
    );
  };

  const resultsColumns = useMemo<MRT_ColumnDef<AppraisalsResultItem>[]>(() => {
    const result: MRT_ColumnDef<AppraisalsResultItem>[] = [];

    if (scenario === undefined || scenario === "dashboard_bo") {
      result.push({
        accessorKey: "priority",
        header: t("appraisals-dashboard-results-appraisals-priority"),
        size: 50,
        Cell: ({ row }) => (
          <CellWrapper
            className={row.original.isBlocked ? "blocked-practice-row" : ""}
            onClick={() => handleSelectRow(row.original)}
          >
            {row.original.priority}
          </CellWrapper>
        ),
      });
    }

    result.push(
      {
        accessorKey: "practiceNumber",
        header: t(
          "appraisals-dashboard-communications-list-header-id-assignment"
        ),
        size: 50,
        Cell: ({ row }) => (
          <CellWrapper
            className={row.original.isBlocked ? "blocked-practice-row" : ""}
            onClick={() => handleSelectRow(row.original)}
          >
            {row.original.practiceNumber}
          </CellWrapper>
        ),
      },
      {
        accessorKey: "sxNumber",
        header: t("appraisals-dashboard-results-appraisals-sx-number"),
        size: 100,
        Cell: ({ row }) => (
          <CellWrapper
            className={row.original.isBlocked ? "blocked-practice-row" : ""}
            onClick={() => handleSelectRow(row.original)}
          >
            <div title={row.original.sxNumber}>
              {truncText(row.original.sxNumber, 14)}
            </div>
          </CellWrapper>
        ),
      },
      {
        accessorKey: "plate",
        header: t("appraisals-dashboard-results-appraisals-plate"),
        size: 100,
        Cell: ({ row }) => (
          <CellWrapper
            className={row.original.isBlocked ? "blocked-practice-row" : ""}
            onClick={() => handleSelectRow(row.original)}
          >
            {row.original.plate}
          </CellWrapper>
        ),
      },
      {
        accessorKey: "cap",
        header: t("appraisals-dashboard-results-appraisals-cap"),
        size: 100,
        Cell: ({ row }) => (
          <CellWrapper
            className={row.original.isBlocked ? "blocked-practice-row" : ""}
            onClick={() => handleSelectRow(row.original)}
          >
            {row.original.cap}
          </CellWrapper>
        ),
      },
      {
        accessorKey: "city",
        header: t("appraisals-dashboard-results-appraisals-city-province"),
        size: 100,
        Cell: ({ row }) => (
          <CellWrapper
            className={row.original.isBlocked ? "blocked-practice-row" : ""}
            onClick={() => handleSelectRow(row.original)}
          >
            <div title={`${row.original.city} ${row.original.province}`}>
              {truncText(row.original.city, 15)}
            </div>
          </CellWrapper>
        ),
      },
      {
        accessorKey: "insertDate",
        header: t("appraisals-dashboard-results-appraisals-insertDate"),
        size: 100,
        Cell: ({ row }) => (
          <CellWrapper
            className={row.original.isBlocked ? "blocked-practice-row" : ""}
            onClick={() => handleSelectRow(row.original)}
          >
            {row.original.insertDate}
          </CellWrapper>
        ),
      }
    );

    if (scenario === undefined) {
      result.push({
        accessorKey: "expirationDate",
        header: t("appraisals-dashboard-results-appraisals-expirationDate"),
        size: 100,
        Cell: ({ row }) => (
          <CellWrapper
            className={row.original.isBlocked ? "blocked-practice-row" : ""}
            onClick={() => handleSelectRow(row.original)}
          >
            {row.original.expirationDate}
          </CellWrapper>
        ),
      });
    }

    if (
      scenario === undefined ||
      ["dashboard_st", "search_result"].includes(scenario)
    ) {
      result.push({
        accessorKey: "study",
        header: t("appraisals-dashboard-results-appraisals-study"),
        size: 100,
        Cell: ({ row }) => (
          <CellWrapper
            className={row.original.isBlocked ? "blocked-practice-row" : ""}
            onClick={() => handleSelectRow(row.original)}
          >
            <div title={row.original.study}>
              {truncText(row.original.study, 25)}
            </div>
          </CellWrapper>
        ),
      });
    }

    result.push(
      {
        accessorKey: "status",
        header: t("appraisals-dashboard-results-appraisals-status"),
        size: 150,
        Cell: ({ row }) => (
          <CellWrapper
            className={row.original.isBlocked ? "blocked-practice-row" : ""}
            onClick={() => handleSelectRow(row.original)}
          >
            {row.original.status}
          </CellWrapper>
        ),
      },
      {
        accessorKey: "isBlocked",
        header: t("appraisals-dashboard-results-appraisals-is-blocked"),
        size: 100,
        Cell: ({ row }) => (
          <CellWrapper
            className={row.original.isBlocked ? "blocked-practice-row" : ""}
            onClick={() => handleSelectRow(row.original)}
          >
            {row.original.isBlocked ? (
              <BlockedWrapper title={row.original.blockedBy}>
                <IconLocked />{" "}
              </BlockedWrapper>
            ) : (
              <></>
            )}
          </CellWrapper>
        ),
      }
    );

    if (scenario === "dashboard_bo") {
      result.push({
        accessorKey: "customerExpirationDate",
        header: t(
          "appraisals-dashboard-results-appraisals-customer-expiration-date"
        ),
        size: 200,
        Cell: ({ row }) => (
          <CellWrapper
            className={row.original.isBlocked ? "blocked-practice-row" : ""}
            onClick={() => handleSelectRow(row.original)}
          >
            {row.original.customerExpirationDate}
          </CellWrapper>
        ),
      });
    }

    if (scenario === "dashboard_st" || scenario === "search_result") {
      result.push({
        accessorKey: "appraiserExpirationDate",
        header: t("appraisals-dashboard-results-appraiser-expiration-date"),
        size: 200,
        Cell: ({ row }) => (
          <CellWrapper
            className={row.original.isBlocked ? "blocked-practice-row" : ""}
            onClick={() => handleSelectRow(row.original)}
          >
            {row.original.appraiserExpirationDate}
          </CellWrapper>
        ),
      });
    }

    if (scenario !== undefined) {
      result.push({
        accessorKey: "practiceType",
        header: t("appraisals-dashboard-results-appraisals-practice-type"),
        size: 50,
        Cell: ({ row }) => (
          <CellWrapper
            className={row.original.isBlocked ? "blocked-practice-row" : ""}
            onClick={() => handleSelectRow(row.original)}
          >
            {t(
              `appraisals-dashboard-results-appraisals-practice-type-code-${row.original.practiceType}`
            )}
          </CellWrapper>
        ),
      });
    }

    if (scenario === "dashboard_st") {
      result.push({
        accessorKey: "appraiserPriority",
        header: t("appraisals-dashboard-results-appraiser-priority"),
        size: 50,
        Cell: ({ row }) => (
          <CellWrapper
            className={row.original.isBlocked ? "blocked-practice-row" : ""}
            onClick={() => handleSelectRow(row.original)}
          >
            {t(
              `appraisals-dashboard-results-appraiser-priority-type-code-${row.original.appraiserPriority}`
            )}
          </CellWrapper>
        ),
      });
    }

    if (scenario === "search_result") {
      result.push({
        accessorKey: "customerIdPractice",
        header: t("appraisals-dashboard-results-customer-id-practice"),
        size: 50,
        Cell: ({ row }) => (
          <CellWrapper
            className={row.original.isBlocked ? "blocked-practice-row" : ""}
            onClick={() => handleSelectRow(row.original)}
          >
            {row.original.customerIdPractice}
          </CellWrapper>
        ),
      });
    }

    if (scenario === "dashboard_st" || scenario === "search_result") {
      result.push({
        accessorKey: "isNegative",
        header: t("appraisals-dashboard-results-is-negative"),
        size: 50,
        Cell: ({ row }) => (
          <CellWrapper
            className={row.original.isBlocked ? "blocked-practice-row" : ""}
            onClick={() => handleSelectRow(row.original)}
          >
            {t(
              `appraisals-dashboard-results-is-negative-response-${row.original.isNegative}`
            )}
          </CellWrapper>
        ),
      });
    }

    if (scenario === "search_result") {
      result.push({
        accessorKey: "chassisNumber",
        header: t("appraisals-dashboard-results-chassis-number"),
        size: 50,
        Cell: ({ row }) => (
          <CellWrapper
            className={row.original.isBlocked ? "blocked-practice-row" : ""}
            onClick={() => handleSelectRow(row.original)}
          >
            {row.original.chassisNumber}
          </CellWrapper>
        ),
      });
    }

    result.push({
      accessorKey: "actions",
      header: "",
      size: 100,
      enableSorting: false,
      enableColumnDragging: false,
      enableColumnActions: false,
      Cell: ({ row }) =>
        row.original.isBlocked ? (
          <></>
        ) : (
          <ActionsWrapper>
            {acceptingPracticeId === row.original.id && (
              <div className="confirm-loader">{IconLoading}</div>
            )}
            {showSupervisorAction(activeRole as string) && (
              <RowActions
                item={row.original}
                onScheduleAppointment={handleShowScheduleAppointmentDialog}
                acceptingPracticeId={acceptingPracticeId}
                schedulingAppointmentPracticeId={
                  schedulingAppointmentPracticeId
                }
              />
            )}
          </ActionsWrapper>
        ),
    });

    return result;
  }, [items, acceptingPracticeId, schedulingAppointmentPracticeId]);

  const handleShowScheduleAppointmentDialog = (id: number) => {
    setSchedulingAppointmentPracticeId(id);
    setShowAppointmentDialog(true);
  };

  const handleCancelScheduleAppointment = () => {
    setShowAppointmentDialog(false);
    setSchedulingAppointmentPracticeId(0);
  };

  const handleScheduleAppointment = async (
    practiceAppointment: PracticeAppointment
  ) => {
    setShowAppointmentDialog(false);

    // I don't have idAppraiser, I get it from practiceDetails
    const { data, error, isSuccess, status } = await retrievePractice({
      authorization: await getAuthorization(),
      id: schedulingAppointmentPracticeId,
      activeRole: activeRole!,
    });
    if (isSuccess && data) {
      const practiceDetails = data as ReadOnlyPractice;

      practiceAppointment.idAppraiser = practiceDetails?.appraiser?.id;
      practiceAppointment.idPractice = practiceDetails.id;

      const response = await scheduleAppointment({
        authorization: await getAuthorization(),
        activeRole: activeRole!,
        practiceAppointment,
      });

      const errorResponse = response as {
        error: {
          status: number;
          data: string;
        };
      };
      if (errorResponse && errorResponse.error) {
        setShowModal(true);
      }

      const updatedPractice = (
        response as {
          data: ReadOnlyPractice;
        }
      ).data as ReadOnlyPractice;

      if (updatedPractice && updatedItems) {
        const _updatedItems = updatedItems.map((practice) => {
          return practice.id === schedulingAppointmentPracticeId
            ? ({
                ...practice,
                status: updatedPractice.status,
                statusCode: updatedPractice.status,
              } as ExpertizerListItemType)
            : practice;
        });
        setUpdatedItems(_updatedItems);
        onRefresh("AF");
      }
    } else {
      console.log(error);
    }

    setSchedulingAppointmentPracticeId(0);
  };

  const renderTable = () => (
    <MaterialReactTable
      columns={resultsColumns}
      data={data}
      enableDensityToggle={false}
      enableColumnOrdering
      muiTableBodyCellProps={{
        sx: {
          textAlign: "left",
        },
      }}
      onPaginationChange={setPagination}
      state={{ pagination }}
      autoResetPageIndex={false}
    />
  );

  return (
    <>
      {removeWrapper && (
        <AppraisalsListWithoutWrapper
          data-testid={testId.container}
          gridArea={gridArea}
        >
          {renderTable()}
        </AppraisalsListWithoutWrapper>
      )}

      {!removeWrapper && (
        <AppraisalsListWrapper
          data-testid={testId.container}
          gridArea={gridArea}
        >
          {renderTable()}
        </AppraisalsListWrapper>
      )}

      <Modal
        open={showAppointmentDialog}
        footer={null}
        onCancel={handleCancelScheduleAppointment}
        width="70%"
      >
        <ModalContent>
          <ScheduleAppointment
            onSchedule={handleScheduleAppointment}
            onCancel={handleCancelScheduleAppointment}
          />
        </ModalContent>
      </Modal>

      {showModal && (
        <Modal
          open={showModal}
          footer={null}
          onCancel={() => setShowModal(false)}
        >
          <ModalContentError>
            <div className="text">
              {t(
                "appraisals-details-tab-activities-collapsable-error-appoinment-popup"
              )}
            </div>
          </ModalContentError>
        </Modal>
      )}
    </>
  );
};

interface IRowActionsProps {
  item: AppraisalsResultItem;
  onScheduleAppointment: (params: any) => void;
  acceptingPracticeId: number;
  schedulingAppointmentPracticeId: number;
}

const RowActions = (props: IRowActionsProps) => {
  const { item, onScheduleAppointment, schedulingAppointmentPracticeId } =
    props;
  const { t } = useTranslation();

  const status = item.statusCode;

  if (status === "AD") {
    if (schedulingAppointmentPracticeId === item.id)
      return <div className="confirm-loader">{IconLoading}</div>;

    return (
      <ButtonConfirm
        disabled={schedulingAppointmentPracticeId > 0}
        size="small"
        onClick={() => onScheduleAppointment(item.id)}
        dataTestId={testId.buttonSetAppointment}
      >
        {
          t(
            "appraisals-details-tab-activities-collapsable-button-schedule-appointment"
          )!
        }
      </ButtonConfirm>
    );
  }

  return <></>;
};

export default AppraisalsList;
