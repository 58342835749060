import { createContext, useContext, useEffect, useState } from "react";
import {
    AppraiserScoreCluster,
    AppraiserScoreClusterConfig,
    AppraiserScoreDashboard,
    AppraiserScoreReport,
    ProvincesAndZones,
    useCreateOrUpdateClusterConfigMutation,
    useCreateOrUpdateClustersMutation,
    useLazyGetAllCoverageProvincesAndZonesQuery,
    useLazyGetAppraiserScoreDashboardQuery,
} from "../../../redux/apiSpecifications/apiFesf";
import { SelectPair } from "../../../types/common.types";
import { useNotifications } from "../../../hooks/useNotifications";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { updateObject } from "../../../utils/updateObject";
import { useTranslation } from "react-i18next";

export interface ExpertizeScoreContextData {
    loadingStatus:
        | "idle"
        | "loading"
        | "refreshing"
        | "updating"
        | "load-in-progress";
    appraiserScoreClusterList?: AppraiserScoreCluster[];
    appraiserScoreClusterConfigList?: AppraiserScoreClusterConfig[];
    appraiserScoreReportList?: AppraiserScoreReport[];
    provincesOptions?: SelectPair[];
    selectedClusterId?: number;
    selectedClusterConfig?: AppraiserScoreClusterConfig;
    isFormChanged: boolean;
    onChangeSelectedCluster: (clusterId: number) => void;
    onChangeSelectedClusterConfig: (field: string, value: any) => void;
    onAddDistrictToCluster: (clusterId: number, districtId: number) => void;
    onRemoveDistrictToCluster: (clusterId: number, districtId: number) => void;
    onSubmitConfigureClusterForm: () => void;
    onSubmitConfigureThresholdWeightForm: () => void;
    onCancelFormChanges: () => void;
}

export const ExpertizeScoreContext = createContext<
    ExpertizeScoreContextData | undefined
>(undefined);

type ExpertizeScoreState = {
    loadingStatus:
        | "idle"
        | "loading"
        | "refreshing"
        | "updating"
        | "load-in-progress";
    appraiserScoreClusterList?: AppraiserScoreCluster[];
    appraiserScoreClusterConfigList?: AppraiserScoreClusterConfig[];
    appraiserScoreReportList?: AppraiserScoreReport[];
    provincesOptions?: SelectPair[];
    selectedClusterId?: number;
    selectedClusterConfig?: AppraiserScoreClusterConfig;
    changedForm?:
        | "configure-cluster-form"
        | "configure-threshold-weight-form"
        | undefined;
};

interface IExpertizeScoreProviderProps {
    children: React.ReactNode;
}

const ExpertizeScoreProvider: React.FC<IExpertizeScoreProviderProps> = ({
    children,
}) => {
    const { authorization, userDetails } = useSelector(
        (state: RootState) => state.user
    );

    const { displayFetchBaseQueryErrorNotification, displaySuccess } =
        useNotifications();

    const { t } = useTranslation();

    const [state, setState] = useState<ExpertizeScoreState>({
        loadingStatus: "loading",
    });

    const [getAppraiserScoreDashboard] =
        useLazyGetAppraiserScoreDashboardQuery();

    const [getAllCoverageProvincesAndZones] =
        useLazyGetAllCoverageProvincesAndZonesQuery();

    const [createOrUpdateClusters] = useCreateOrUpdateClustersMutation();
    const [createOrUpdateClusterConfig] =
        useCreateOrUpdateClusterConfigMutation();

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        const { selectedClusterId, appraiserScoreClusterConfigList } = state;

        if (selectedClusterId !== undefined) {
            let clusterConfig = appraiserScoreClusterConfigList?.find(
                (_) => _.clusterId === selectedClusterId
            );

            if (clusterConfig === undefined) {
                clusterConfig = {
                    clusterId: selectedClusterId,
                    confirmationReactivityThreshold: 0,
                    confirmationReactivityWeight: 0,
                    accessReactivityThreshold: 0,
                    accessReactivityWeight: 0,
                    accessesCountThreshold: 0,
                    accessesCountWeight: 0,
                    noAccessesCountThreshold: 0,
                    noAccessesCountWeight: 0,
                    agreementsCountThreshold: 0,
                    agreementsCountWeight: 0,
                    rejectedCountThreshold: 0,
                    rejectedCountWeight: 0,
                    accountingVerifyCountThreshold: 0,
                    accountingVerifyCountWeight: 0,
                    meanAppraisalaverageAmountThreshold: 0,
                    meanAppraisalaverageAmountWeight: 0,
                    appraiserScoreTenantConfigs: [],
                };

                if (userDetails?.tenants) {
                    for (let tenant of userDetails?.tenants) {
                        clusterConfig.appraiserScoreTenantConfigs?.push({
                            tenantId: tenant.id,
                            clusterId: selectedClusterId,
                            singleSignatureCountThreshold: 0,
                            singleSignatureCountWeight: 0,
                            doubleSignatureCountThreshold: 0,
                            doubleSignatureCountWeight: 0,
                            noCardCvtCountThreshold: 0,
                            noCardCvtCountWeight: 0,
                        });
                    }
                }
            }

            setState({ ...state, selectedClusterConfig: clusterConfig });
        }
        // else {
        //     setState({ ...state, selectedClusterConfig: undefined });
        // }
    }, [state.selectedClusterId]);

    const loadData = async () => {
        loadAppraiserScoreDashboard()
            .then((appraiserScoreDashboard) => {
                let {
                    appraiserScoreClusterList,
                    appraiserScoreClusterConfigList,
                    appraiserScoreReportList,
                } = appraiserScoreDashboard;

                loadProvinces()
                    .then((provincesOptions) => {
                        setState({
                            ...state,
                            loadingStatus: "idle",
                            appraiserScoreClusterList:
                                appraiserScoreClusterList,
                            appraiserScoreClusterConfigList:
                                appraiserScoreClusterConfigList,
                            appraiserScoreReportList: appraiserScoreReportList,
                            provincesOptions: provincesOptions,
                            selectedClusterId: appraiserScoreClusterList
                                ? appraiserScoreClusterList[0].id
                                : undefined,
                        });
                    })
                    .catch((error) =>
                        displayFetchBaseQueryErrorNotification(error)
                    );
            })
            .catch((error) => displayFetchBaseQueryErrorNotification(error));
    };

    const loadAppraiserScoreDashboard = (): Promise<AppraiserScoreDashboard> =>
        new Promise(async (resolve, reject) => {
            const response = await getAppraiserScoreDashboard({
                authorization: authorization,
            });

            const { data, error, isSuccess, isError } = response;

            if (isSuccess && data) {
                resolve(data);
            } else if (isError) {
                reject(error);
            }
        });

    const loadProvinces = (): Promise<SelectPair[] | undefined> =>
        new Promise(async (resolve, reject) => {
            const response = await getAllCoverageProvincesAndZones({
                authorization: authorization,
            });

            const { data, error, isSuccess, isError } = response;

            if (isSuccess && data) {
                const correctData = data as ProvincesAndZones;

                const provincesOptions = correctData.provinces
                    ?.map(
                        (province) =>
                            ({
                                value: province.id,
                                label: province.districtName,
                            } as SelectPair)
                    )
                    .sort((p1, p2) =>
                        p1.label > p2.label ? 1 : p1.label < p2.label ? -1 : 0
                    );

                resolve(provincesOptions);
            } else if (isError) {
                reject(error);
            }
        });

    const onChangeSelectedCluster = (clusterId: number) => {
        setState({
            ...state,
            selectedClusterId: clusterId,
            changedForm: undefined,
        });
    };

    const onChangeSelectedClusterConfig = (field: string, value: any) => {
        const updatedData = updateObject(
            state.selectedClusterConfig,
            value,
            field
        );

        setState({
            ...state,
            selectedClusterConfig: { ...updatedData },
            changedForm: "configure-threshold-weight-form",
        });
    };

    const onAddDistrictToCluster = (clusterId: number, districtId: number) => {
        setState({
            ...state,
            appraiserScoreClusterList: state.appraiserScoreClusterList?.map(
                (cluster) => {
                    if (cluster.id !== clusterId) return cluster;

                    return {
                        ...cluster,
                        districts: [...cluster.districts!, districtId],
                    };
                }
            ),
            changedForm: "configure-cluster-form",
        });
    };

    const onRemoveDistrictToCluster = (
        clusterId: number,
        districtId: number
    ) => {
        setState({
            ...state,
            appraiserScoreClusterList: state.appraiserScoreClusterList?.map(
                (cluster) => {
                    if (cluster.id !== clusterId) return cluster;

                    return {
                        ...cluster,
                        districts: cluster.districts?.filter(
                            (_) => _ != districtId
                        ),
                    };
                }
            ),
            changedForm: "configure-cluster-form",
        });
    };

    const onSubmitConfigureClusterForm = async () => {
        setState({
            ...state,
            loadingStatus: "updating",
        });

        const response = await createOrUpdateClusters({
            authorization: authorization,
            appraiserScoreClusters: {
                clusters: state.appraiserScoreClusterList!,
            },
        });

        const correctResponse = response as {
            data: AppraiserScoreDashboard;
        };

        if (correctResponse.data) {
            const {
                appraiserScoreClusterList,
                appraiserScoreClusterConfigList,
                appraiserScoreReportList,
            } = correctResponse.data;

            setState({
                ...state,
                loadingStatus: "idle",
                appraiserScoreClusterList: appraiserScoreClusterList,
                appraiserScoreClusterConfigList:
                    appraiserScoreClusterConfigList,
                appraiserScoreReportList: appraiserScoreReportList,
                changedForm: undefined,
            });

            displaySuccess(t("expert-score-configuration-saved-successfully"));
        } else {
            const errorResponse = response as {
                error: FetchBaseQueryError;
            };

            displayFetchBaseQueryErrorNotification(errorResponse.error);

            setState({
                ...state,
                loadingStatus: "idle",
            });
        }
    };

    const convertToNumber = (value: number) => {
        return Number(value.toString().replace(",", "."));
    };

    const onSubmitConfigureThresholdWeightForm = async () => {
        const selectedClusterId = state.selectedClusterId;

        setState({
            ...state,
            loadingStatus: "updating",
            selectedClusterId: undefined,
        });

        const response = await createOrUpdateClusterConfig({
            authorization: authorization,
            id: state.selectedClusterConfig?.clusterId!,
            appraiserScoreClusterConfig: {
                ...state.selectedClusterConfig!,
                confirmationReactivityThreshold: convertToNumber(
                    state.selectedClusterConfig
                        ?.confirmationReactivityThreshold!
                ),
                confirmationReactivityWeight: convertToNumber(
                    state.selectedClusterConfig?.confirmationReactivityWeight!
                ),
                accessReactivityThreshold: convertToNumber(
                    state.selectedClusterConfig?.accessReactivityThreshold!
                ),
                accessReactivityWeight: convertToNumber(
                    state.selectedClusterConfig?.accessReactivityWeight!
                ),
                accessesCountThreshold: convertToNumber(
                    state.selectedClusterConfig?.accessesCountThreshold!
                ),
                accessesCountWeight: convertToNumber(
                    state.selectedClusterConfig?.accessesCountWeight!
                ),
                noAccessesCountThreshold: convertToNumber(
                    state.selectedClusterConfig?.noAccessesCountThreshold!
                ),
                noAccessesCountWeight: convertToNumber(
                    state.selectedClusterConfig?.noAccessesCountWeight!
                ),
                agreementsCountThreshold: convertToNumber(
                    state.selectedClusterConfig?.agreementsCountThreshold!
                ),
                agreementsCountWeight: convertToNumber(
                    state.selectedClusterConfig?.agreementsCountWeight!
                ),
                rejectedCountThreshold: convertToNumber(
                    state.selectedClusterConfig?.rejectedCountThreshold!
                ),
                rejectedCountWeight: convertToNumber(
                    state.selectedClusterConfig?.rejectedCountWeight!
                ),
                accountingVerifyCountThreshold: convertToNumber(
                    state.selectedClusterConfig?.accountingVerifyCountThreshold!
                ),
                accountingVerifyCountWeight: convertToNumber(
                    state.selectedClusterConfig?.accountingVerifyCountWeight!
                ),
                meanAppraisalaverageAmountThreshold: convertToNumber(
                    state.selectedClusterConfig
                        ?.meanAppraisalaverageAmountThreshold!
                ),
                meanAppraisalaverageAmountWeight: convertToNumber(
                    state.selectedClusterConfig
                        ?.meanAppraisalaverageAmountWeight!
                ),
                appraiserScoreTenantConfigs:
                    state.selectedClusterConfig?.appraiserScoreTenantConfigs?.map(
                        (scoreTenant) => {
                            return {
                                ...scoreTenant,
                                singleSignatureCountThreshold: convertToNumber(
                                    scoreTenant.singleSignatureCountThreshold!
                                ),
                                singleSignatureCountWeight: convertToNumber(
                                    scoreTenant.singleSignatureCountWeight!
                                ),
                                doubleSignatureCountThreshold: convertToNumber(
                                    scoreTenant.doubleSignatureCountThreshold!
                                ),
                                doubleSignatureCountWeight: convertToNumber(
                                    scoreTenant.doubleSignatureCountWeight!
                                ),
                                noCardCvtCountThreshold: convertToNumber(
                                    scoreTenant.noCardCvtCountThreshold!
                                ),
                                noCardCvtCountWeight: convertToNumber(
                                    scoreTenant.noCardCvtCountWeight!
                                ),
                            };
                        }
                    ),
            },
        });

        const correctResponse = response as {
            data: AppraiserScoreDashboard;
        };

        if (correctResponse.data) {
            const {
                appraiserScoreClusterList,
                appraiserScoreClusterConfigList,
                appraiserScoreReportList,
            } = correctResponse.data;

            setState({
                ...state,
                loadingStatus: "idle",
                appraiserScoreClusterList: appraiserScoreClusterList,
                appraiserScoreClusterConfigList:
                    appraiserScoreClusterConfigList,
                appraiserScoreReportList: appraiserScoreReportList,
                selectedClusterId: selectedClusterId,
                changedForm: undefined,
            });

            displaySuccess(t("expert-score-configuration-saved-successfully"));
        } else {
            const errorResponse = response as {
                error: FetchBaseQueryError;
            };

            displayFetchBaseQueryErrorNotification(errorResponse.error);

            setState({
                ...state,
                loadingStatus: "idle",
            });
        }
    };

    const onCancelFormChanges = async () => {
        const selectedClusterId = state.selectedClusterId;

        setState({
            ...state,
            loadingStatus: "refreshing",
            selectedClusterId: undefined,
        });

        loadAppraiserScoreDashboard()
            .then((appraiserScoreDashboard) => {
                const {
                    appraiserScoreClusterList,
                    appraiserScoreClusterConfigList,
                    appraiserScoreReportList,
                } = appraiserScoreDashboard;

                setState({
                    ...state,
                    loadingStatus: "idle",
                    appraiserScoreClusterList: appraiserScoreClusterList,
                    appraiserScoreClusterConfigList:
                        appraiserScoreClusterConfigList,
                    appraiserScoreReportList: appraiserScoreReportList,
                    selectedClusterId: selectedClusterId,
                    changedForm: undefined,
                });
            })
            .catch((error) => displayFetchBaseQueryErrorNotification(error));
    };

    return (
        <ExpertizeScoreContext.Provider
            value={{
                loadingStatus: state.loadingStatus,
                appraiserScoreClusterList: state.appraiserScoreClusterList,
                appraiserScoreClusterConfigList:
                    state.appraiserScoreClusterConfigList,
                appraiserScoreReportList: state.appraiserScoreReportList,
                provincesOptions: state.provincesOptions,
                selectedClusterId: state.selectedClusterId,
                selectedClusterConfig: state.selectedClusterConfig,
                isFormChanged: state.changedForm !== undefined,
                onChangeSelectedCluster,
                onChangeSelectedClusterConfig,
                onAddDistrictToCluster,
                onRemoveDistrictToCluster,
                onSubmitConfigureClusterForm,
                onSubmitConfigureThresholdWeightForm,
                onCancelFormChanges,
            }}
        >
            {children}
        </ExpertizeScoreContext.Provider>
    );
};

export const useExpertizeScoreContext = (): ExpertizeScoreContextData => {
    const context = useContext(ExpertizeScoreContext);

    if (!context) {
        throw new Error("Errore");
    }

    return context;
};

export default ExpertizeScoreProvider;
