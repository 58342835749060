import { useEffect, useState } from "react";
import { IconLoading } from "../../../config/icons";
import {
  MainTabContentWrapper,
  RefreshInTabWrapper,
} from "../../../style/DashbordWidgetWrappers";
import {
  ForcedActivationStatus,
  FullUser,
  ToggleUserActivationApiResponse,
  UserConfig,
  UserConfigLists,
  Users,
  useLazyGetAllUsersQuery,
  useToggleUserActivationMutation,
  useUpdateUserConfigurationMutation,
  useUpdateUserMutation,
} from "../../../redux/apiSpecifications/apiFesf";
import AccountsToManageList from "./AccountsToManage/AccountsToManageList";
import AssociatedUsersList from "./AssociatedUsers/AssociatedUsersList";
import { UserItem } from "./UserItem";
import UserConfigModal from "./components/UserConfigModal/UserConfigModal";
import UserSubjectModal from "./components/UserSubjectModal/UserSubjectModal";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { useNotifications } from "../../../hooks/useNotifications";
import { SerializedError } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";

interface IUserManagementProps {
  tabKey?: number;
}

const UserManagement = (props: IUserManagementProps) => {
  const { t } = useTranslation();

  const [accountsToManage, setAccountsToManage] = useState<Users>([]);
  const [associatedUsers, setAssociatedUsers] = useState<Users>([]);

  const [selectedUserContact, setSelectedUserContact] =
    useState<UserItem | null>(null);

  const [selectedUserConfig, setSelectedUserConfig] = useState<UserItem | null>(
    null
  );

  const [retrieveUsersData] = useLazyGetAllUsersQuery();

  const [toggleUserActivation] = useToggleUserActivationMutation();

  const [updateUser] = useUpdateUserMutation();

  const [updateUserConfiguration] = useUpdateUserConfigurationMutation();

  const { activeKey } = useSelector((state: RootState) => state.mainTabs);

  const { authorization, activeRole } = useSelector(
    (state: RootState) => state.user
  );

  const [loadingStatus, setLoadingStatus] = useState<
    "idle" | "loading" | "refreshing"
  >("idle");

  const { displaySuccess, displayFetchBaseQueryErrorNotification } =
    useNotifications();

  const loadData = async () => {
    const response = await retrieveUsersData({
      authorization: authorization,
      activeRole: activeRole!
    });

    const data = response.data as Users;

    let accountsToManage: Users = [];
    let associatedUsers: Users = [];

    data?.forEach((u) => {
      if (u.idSubject === null || u.roles?.length == 0)
        accountsToManage?.push(u);
      else associatedUsers?.push(u);
    });

    setAccountsToManage(accountsToManage);
    setAssociatedUsers(associatedUsers);

    setLoadingStatus("idle");
  };

  useEffect(() => {
    if (activeKey === props.tabKey) {
      setLoadingStatus("refreshing");

      loadData();
    }
  }, [activeKey]);

  const handleEditUserContact = (userItem: UserItem) => {
    setSelectedUserContact(userItem);
  };

  const handleEditUserConfig = (userItem: UserItem) => {
    setSelectedUserConfig(userItem);
  };

  const handleToggleUserActivation = async (
    userItem: UserItem,
    activation: boolean
  ) => {
    setLoadingStatus("refreshing");

    const response = await toggleUserActivation({
      authorization: authorization,
      id: userItem.id!,
      activeRole: activeRole!,
      forcedActivationStatus: activation as ForcedActivationStatus,
    });

    const correctResponse = response as {
      data: ToggleUserActivationApiResponse;
    };

    if (correctResponse.data) {
      await loadData();

      displaySuccess(t(`user-management-${activation}-success-message`));
    
    } else {
      const errorResponse = response as {
        error: FetchBaseQueryError | SerializedError;
      };

      displayFetchBaseQueryErrorNotification(errorResponse.error);
    }

    setLoadingStatus("idle");
  };

  const handleOnCancelUserSubject = () => setSelectedUserContact(null);

  const handleOnConfirmUserSubject = async (fullUser: FullUser) => {
    setLoadingStatus("refreshing");

    const userId = selectedUserContact?.id!;

    setSelectedUserContact(null);

    const response = await updateUser({
      authorization: authorization,
      id: userId,
      fullUser: fullUser,
    });

    const correctResponse = response as {
      data: ToggleUserActivationApiResponse;
    };

    if (correctResponse.data) {
      await loadData();

      displaySuccess(t("user-management-config-user-subject-success"));

    } else {
      const errorResponse = response as {
        error: FetchBaseQueryError | SerializedError;
      };

      displayFetchBaseQueryErrorNotification(errorResponse.error);
    }

    setLoadingStatus("idle");
  };

  const handleOnCancelUserConfig = () => setSelectedUserConfig(null);

  const handleOnConfirmUserConfig = async (userConfig: UserConfig) => {
    setLoadingStatus("refreshing");

    const userId = selectedUserConfig?.id!;

    setSelectedUserConfig(null);

    const response = await updateUserConfiguration({
      authorization: authorization,
      id: userId,
      activeRole: activeRole!,
      userConfig: userConfig,
    });

    const correctResponse = response as {
      data: ToggleUserActivationApiResponse;
    };

    if (correctResponse.data) {
      await loadData();

      displaySuccess(t("user-management-config-user-success"));

    } else {

      const errorResponse = response as {
        error: FetchBaseQueryError | SerializedError;
      };

      displayFetchBaseQueryErrorNotification(errorResponse.error);
    }

    setLoadingStatus("idle");
  };

  return (
    <div data-testid="">
      {loadingStatus === "refreshing" && (
        <RefreshInTabWrapper>
          <div className="box" data-testid={""}>
            {IconLoading}
          </div>
        </RefreshInTabWrapper>
      )}

      <MainTabContentWrapper data-testid="user-management">
        <AccountsToManageList
          users={accountsToManage}
          onEditUserContact={handleEditUserContact}
          onEditUserConfig={handleEditUserConfig}
        />

        <AssociatedUsersList
          users={associatedUsers}
          onEditUserContact={handleEditUserContact}
          onEditUserConfig={handleEditUserConfig}
          onToggleUserActivation={handleToggleUserActivation}
        />
      </MainTabContentWrapper>

      {selectedUserContact && (
        <UserSubjectModal
          user={selectedUserContact}
          onCancel={handleOnCancelUserSubject}
          onConfirm={handleOnConfirmUserSubject}
        />
      )}

      {selectedUserConfig && (
        <UserConfigModal
          user={selectedUserConfig}
          onCancel={handleOnCancelUserConfig}
          onConfirm={handleOnConfirmUserConfig}
        />
      )}
    </div>
  );
};

export default UserManagement;
