import { useEffect, useState } from "react";
import styled from "styled-components";
import { ContentWrapper, GenericWrapper } from "../../Commons/Wrappers";
import AgendaBase, { DayContent } from "../../../../Inputs/AgendaBase";
import moment, { Moment } from "moment";
import dekra from "../../../../../style/dekra";
import { IconLoading } from "../../../../../config/icons";
import {
  AppraiserAgenda as AgendaAppraiser,
  Appointment,
  DailyAgenda,
  District,
  DistrictAppraiserStatus,
  DistrictAppraiserStatuses,
  useLazyGetMyAgendaQuery,
  useSetAppraiserStatusOnDistrictsMutation,
} from "../../../../../redux/apiSpecifications/apiFesf";
import { useAuthorization } from "../../../../../hooks/useAuthorization";
import { useTranslation } from "react-i18next";
import ProvinceList, { SelectedDayType } from "./ProvinceList";
import AppraiserAgendaAppointments from "./AppraiserAgendaAppointments";
import AppraiserAgendaAppraiserStatusLeave, {
  ProvinceSelection,
  RangeValue,
} from "./AppraiserAgendaAppraiserStatus";

import { LoadingFullScreenWrapper } from "../../../../../style/DashbordWidgetWrappers";
import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { useDispatch, useSelector } from "react-redux";
import {
  setAppraiserAgendaDays,
  setAppraiserAgendaSelectDay,
  setAppraiserAgendaSelectProvince,
  setAppraiserError,
  setAppraiserStatus,
} from "../../../../../redux/features/appraisersSlice";
import { RootState } from "../../../../../redux/store";

// Styled components

const AppraiserAgendaWrapper = styled(GenericWrapper)`
  display: grid;
  padding: 2em 3em 3em 3em;
  height: 48em;

  grid-template-columns: auto 20em 1fr;
  grid-template-rows: 3em 30% 1fr;
  grid-template-areas:
    "calendar legend legend"
    "calendar provinces appointments"
    "calendar set-leave set-leave";

  grid-gap: 1em 5em;

  .calendar-wrapper {
    grid-area: calendar;
  }

  .legend-wrapper {
    grid-area: legend;
    display: flex;
    align-items: center;
    width: 100%;
  }

  .provinces-wrapper {
    grid-area: provinces;
    height: 100%;
  }

  .appointments-wrapper {
    grid-area: appointments;
    height: 100%;
  }

  .set-leave-wrapper {
    grid-area: set-leave;
    height: 100%;
    width: 100%;
  }

  .appointment,
  .leave,
  .saint-patron,
  .illness {
    text-transform: uppercase;
    font-size: 0.8em;
    letter-spacing: 1px;
    width: 4em;
    height: 2em;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 2em;
    color: #fff;
    background-color: ${dekra.primaryColor};
    padding: 0.1em 1em;
  }

  .illness {
    color: #fff;
    background-color: #d91b1b;
  }

  .leave,
  .saint-patron {
    color: #fff;
    background-color: orange;
  }

  .day-content {
    display: flex;
    flex-direction: column;
    gap: 0.2em;
  }

  .legend {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;

    .legend-item {
      display: flex;
      align-items: center;
      gap: 0.25em;
    }

    .square-appointment,
    .square-leave,
    .square-illnes {
      width: 3em;
      height: 1.6em;
      border-radius: 5px;
      margin-right: 1em;
    }

    .square-appointment {
      background-color: #00c123;
    }

    .square-leave {
      background-color: #ef7c00;
    }
    .square-illnes {
      background-color: #d91b1b;
    }
  }
`;

const truncate = (str: string, n: number) =>
  str.length > n ? str.slice(0, n - 1) + "..." : str;

export type SelectedProvince = {
  code: string;
  name: string;
  type: string;
};

let today = moment();
today = moment([today.year(), today.month(), today.date()]);
/**
 * @description
 * @param {number | undefined} idAppraiser  - id of the appraiser
 */
interface IAppraiserAgendaProps {
  idAppraiser: number | undefined;
  readOnly?: boolean;
}

const AppraiserAgenda = ({ idAppraiser, readOnly }: IAppraiserAgendaProps) => {
  const { getAuthorization } = useAuthorization();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const appraiserData = useSelector((state: RootState) =>
    state.appraisers.appraisers.find((a) => a.id === idAppraiser)
  );

  const { myAgenda: agendaData, status } = appraiserData || {};

  const {
    days,
    districts: allDistricts,
    selectedDay: selectedDailyAgenda,
    selectionType,
    selectedProvince,
    selectedAppointments,
    defaultStatusValues,
  } = agendaData || {
    days: [],
    districts: [],
  };

  // Local state
  const [minDate, setMinDate] = useState<Moment | undefined>();
  const [daysContent, setDaysContent] = useState<DayContent[]>([]);

  // API
  const [loadAgenda] = useLazyGetMyAgendaQuery();
  //const [saveAppraiserStatuses] = useSetAppraiserStatusOnDistrictsMutation();

  // Utils
  const getDistrinctName = (districtCode: string | undefined) => {
    if (!districtCode) return "";
    const district = allDistricts.find((d) => d.districtCode === districtCode);
    return truncate(district?.districtName || "", 10);
  };

  useEffect(() => {
    if (!idAppraiser || idAppraiser === 0) return;
    (async () => {
      await loadMonthData(today.format("DD/MM/YYYY"));
    })();
  }, [idAppraiser]);

  const loadMonthData = async (month: string) => {
    dispatch(setAppraiserStatus({ id: idAppraiser!, status: "loading" }));

    const { data, isError } = await loadAgenda({
      authorization: await getAuthorization(),
      appraiserid: idAppraiser!,
      month,
    });

    if (isError) {
      dispatch(
        setAppraiserError({
          id: idAppraiser!,
          message: "si è verificato un errore nel caricamento dell'agenda",
        })
      );
      return;
    }

    const {
      daysBeforeLeaveRequest = 0,
      districts = [],
      days = [],
    } = data as AgendaAppraiser;

    const newMinDate = moment().add(daysBeforeLeaveRequest, "days");
    setMinDate(newMinDate);

    dispatch(
      setAppraiserAgendaDays({
        id: idAppraiser!,
        days,
        dayToSelect: month,
        districts,
      })
    );
  };

  useEffect(() => {
    if (!days) return;
    if (days.length === 0 && daysContent.length == 0) return;

    const newDaysContent = days.map((day) => {
      const {
        date = "",
        onIllnessDistricts = [],
        onLeaveDistricts = [],
        onPatronSaintDistricts = [],
        appointments = [],
      } = day;

      const hasLeaves = onLeaveDistricts.length > 0;

      const hasPatronSaint = onPatronSaintDistricts.length > 0;

      const hasIllness = onIllnessDistricts.length > 0;

      const hasAppointments = appointments.length > 0;

      return {
        day: moment(day.date, "DD/MM/YYYY"),
        content: (
          <>
            {hasLeaves && (
              <div
                className="leave"
                onClick={() => handleSelectDayStatus("leave", date)}
              ></div>
            )}
            {hasPatronSaint && (
              <div
                className="saint-patron"
                onClick={() => handleSelectDayStatus("saint", date)}
              ></div>
            )}
            {hasIllness && (
              <div
                className="illness"
                onClick={() => handleSelectDayStatus("illness", date)}
              ></div>
            )}
            {hasAppointments && (
              <div
                className="appointment"
                onClick={() => handleSelectDayStatus("appointments", date)}
              ></div>
            )}
          </>
        ),
      } as DayContent;
    });

    setDaysContent(newDaysContent);
  }, [days]);

  const handleChangeMonth = async (date: Moment) => {
    await loadMonthData(date.format("DD/MM/YYYY"));

    dispatch(
      setAppraiserAgendaSelectDay({
        id: idAppraiser!,
        date: date.format("DD/MM/YYYY"),
        type: undefined,
      })
    );
  };

  const handleDayClick = (day: Moment) => {};

  const findDateRange = (
    day: DailyAgenda,
    type: SelectedDayType,
    province: string
  ) => {
    let districts: District[] = [];

    if (type === "illness") {
      districts = day?.onIllnessDistricts || [];
    } else if (type === "leave") {
      districts = day?.onLeaveDistricts || [];
    }

    const allProvinces = districts?.map((a) => a.districtCode || "") || [];
    const uniqueProvinces = Array.from(new Set(allProvinces || []));

    // range
    let firstDay = moment(day!.date, "DD/MM/YYYY");
    let lastDay = moment(day!.date, "DD/MM/YYYY");

    // find first day of leave
    const startDay = moment(day?.date, "DD/MM/YYYY");
    const minDay = moment(day?.date, "DD/MM/YYYY").subtract(30, "days");
    const maxDay = moment(day?.date, "DD/MM/YYYY").add(30, "days");

    const hasSameTypeAndProvinces = (d: Moment) => {
      const dDate = days.find((_d) => _d.date === d.format("DD/MM/YYYY"));
      if (!dDate) return false; // the day entry does not exist

      let dayDistricts: District[] = [];
      if (type === "illness") {
        dayDistricts = dDate?.onIllnessDistricts || [];
      } else if (type === "leave") {
        dayDistricts = dDate?.onLeaveDistricts || [];
      }
      return (
        dayDistricts?.find((p) => p.districtCode === province) !== undefined
      );
    };

    for (let d = moment(startDay); d.isAfter(minDay); d.subtract(1, "days")) {
      if (hasSameTypeAndProvinces(d)) firstDay = moment(d);
      else break;
    }

    for (let d = moment(startDay); d.isBefore(maxDay); d.add(1, "days")) {
      if (hasSameTypeAndProvinces(d)) lastDay = moment(d);
      else break;
    }

    return [
      firstDay.format("DD/MM/YYYY"),
      lastDay.format("DD/MM/YYYY"),
    ] as RangeValue;
  };

  const handleSelectDayStatus = async (type: SelectedDayType, date: string) => {
    const day = days.find((d) => d.date === date);
    //const range = findDateRange(day!, type);

    if (date.slice(3, 10) !== selectedDailyAgenda?.date?.slice(3, 10))
      await loadMonthData(date);

    dispatch(
      setAppraiserAgendaSelectDay({
        id: idAppraiser!,
        date,
        type,
        //  range,
      })
    );
  };

  const handleSelectProvince = (
    provinceCode: string,
    type: SelectedDayType
  ) => {
    let newAppointments: Appointment[] = [];

    if (type === "appointments") {
      newAppointments =
        selectedDailyAgenda?.appointments
          ?.filter((a) => a.appointmentDistrict === provinceCode)
          ?.sort((a, b) =>
            a.appointmentTimeSlot!.localeCompare(b.appointmentTimeSlot!)
          ) || [];
    }
    // else {
    const range = findDateRange(selectedDailyAgenda!, type, provinceCode);

    dispatch(
      setAppraiserAgendaSelectProvince({
        id: idAppraiser!,
        range,
        province: {
          code: provinceCode,
          name: getDistrinctName(provinceCode),
          type,
        } as SelectedProvince,
        appointments: newAppointments,
        defaultProvinces: [provinceCode],
        status: type,
        statusCode: "",
      })
    );
  };

  const currentDate = selectedDailyAgenda?.date
    ? moment(selectedDailyAgenda?.date, "DD/MM/YYYY")
    : today;

  return (
    <>
      {status === "loading" && (
        <LoadingFullScreenWrapper>
          <div className="box">{IconLoading}</div>
        </LoadingFullScreenWrapper>
      )}

      <AppraiserAgendaWrapper>
        <div className="calendar-wrapper">
          <AgendaBase
            currentDate={currentDate}
            daysContent={daysContent}
            dayMinHeight={"6em"}
            isDayClickable={false}
            onDayClick={handleDayClick}
            onDateChange={handleChangeMonth}
          />
        </div>

        <div className="legend-wrapper">
          <div className="legend">
            <div className="legend-item">
              <div className="square-appointment"></div>
              <div className="item-text">{t("agenda-legend-appointments")}</div>
            </div>
            <div className="legend-item">
              <div className="square-leave"></div>
              <div className="item-text">{t("agenda-legend-leave")}</div>
            </div>
            <div className="legend-item">
              <div className="square-illnes"></div>
              <div className="item-text">{t("agenda-legend-illness")}</div>
            </div>
          </div>
        </div>

        <div className="provinces-wrapper">
          <ProvinceList
            type={selectionType}
            agenda={selectedDailyAgenda}
            date={selectedDailyAgenda?.date}
            allDistricts={allDistricts}
            onSelect={handleSelectProvince}
          />
        </div>

        <div className="appointments-wrapper">
          <AppraiserAgendaAppointments
            appointments={selectedAppointments}
            // provinceName={selectedProvince?.name}
          />
        </div>

        <div className="set-leave-wrapper">
          <AppraiserAgendaAppraiserStatusLeave
            idAppraiser={idAppraiser}
            allProvinces={allDistricts}
            agenda={selectedDailyAgenda}
            minDate={minDate}
            daysContent={daysContent}
            readonly={readOnly}
            //onSave={handleSaveLeaveSuspension}
            reloadAgendaMonth={() =>
              loadMonthData(currentDate.format("DD/MM/YYYY"))
            }
          />
        </div>
      </AppraiserAgendaWrapper>
    </>
  );
};

export default AppraiserAgenda;
