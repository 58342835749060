import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

import { IconLoading } from "../../../config/icons";
import {
    AccountingReportList,
    useLazyGetAccountingReportListQuery,
} from "../../../redux/apiSpecifications/apiFesf";
import SimulatedReportsList from "./SimulatedReportsList";
import {
    MainTabContentWrapper,
    RefreshInTabWrapper,
} from "../../../style/DashbordWidgetWrappers";
import ReportsList from "./ReportsList";

interface IManageReportProps {
    tabKey?: number;
}

const ManageReport = ({ tabKey }: IManageReportProps) => {
    const { activeKey } = useSelector((state: RootState) => state.mainTabs);

    const { authorization, activeRole } = useSelector(
        (state: RootState) => state.user
    );

    const [loadingStatus, setLoadingStatus] = useState<
        "idle" | "loading" | "refreshing"
    >("idle");
    const [simulatedReports, setSimulatedReports] =
        useState<AccountingReportList>([]);
    const [reports, setReports] = useState<AccountingReportList>([]);

    const [getAccountingReportList] = useLazyGetAccountingReportListQuery();

    useEffect(() => {
        if (activeKey === tabKey) {
            refresh();
        }
    }, [activeKey]);

    const refresh = () => {
        setLoadingStatus("refreshing");

        loadData();
    };

    const loadData = async () => {
        const response = await getAccountingReportList({
            activeRole: activeRole!,
            authorization: authorization,
        });

        const data = response.data as AccountingReportList;

        let simulatedReportsFiltered: AccountingReportList = [];
        let reportsFiltered: AccountingReportList = [];

        data?.forEach((r) => {
            if (r.isSimulated ?? false) simulatedReportsFiltered.push(r);
            else reportsFiltered.push(r);
        });

        setSimulatedReports(simulatedReportsFiltered);
        setReports(reportsFiltered);

        setLoadingStatus("idle");
    };

    const handleOnChangeStatus = () => {
        refresh();
    };

    return (
        <div data-testid="">
            {loadingStatus === "refreshing" && (
                <RefreshInTabWrapper>
                    <div className="box" data-testid={""}>
                        {IconLoading}
                    </div>
                </RefreshInTabWrapper>
            )}

            <MainTabContentWrapper>
                {activeRole === "G1" && (
                    <SimulatedReportsList reports={simulatedReports} />
                )}

                {reports.length > 0 && (
                    <ReportsList reports={reports} onChangeStatus={refresh} />
                )}
            </MainTabContentWrapper>
        </div>
    );
};

export default ManageReport;
