import {
  AiOutlineContacts,
  AiOutlineCheck,
  AiOutlineSafetyCertificate,
  AiOutlineEuro,
  AiOutlineQuestionCircle,
  AiOutlineCloseCircle,
  AiFillPhone,
  AiOutlineMail,
  AiOutlineInfoCircle,
} from "react-icons/ai";
import { BiHomeAlt, BiSearch, BiEditAlt, BiCar } from "react-icons/bi";
import {
  BsLightning,
  BsCardChecklist,
  BsBox,
  BsCalendar3,
} from "react-icons/bs";
import { FiEdit, FiUser, FiUsers } from "react-icons/fi";
import {
  HiOutlineDocumentRemove,
  HiOutlineDocumentSearch,
  HiOutlineDocumentText,
} from "react-icons/hi";
import { SlUser } from "react-icons/sl";

import { InboxOutlined } from "@ant-design/icons";
import { IoIosBusiness, IoIosLock } from "react-icons/io";
import { FaCar, FaCaretDown, FaCaretUp } from "react-icons/fa";
import { GrDocumentConfig } from "react-icons/gr";
import { CgArrowsExchangeAlt } from "react-icons/cg";
import { FcCancel, FcFlashOn } from "react-icons/fc";
import { TfiHelpAlt } from "react-icons/tfi";
import { IoBodyOutline, IoBusiness } from "react-icons/io5";
import {
  RiDeleteBin6Line,
  RiFilterOffLine,
  RiFolderUserLine,
  RiUserLine,
} from "react-icons/ri";
import { MdDelete, MdOutlinePlace } from "react-icons/md";
import { VscCopy } from "react-icons/vsc";
import { TbCloudDownload } from "react-icons/tb";
import { GrUpdate } from "react-icons/gr";
import dekra from "../style/dekra";
import { ImCancelCircle } from "react-icons/im";

interface IconProps {
  className?: string;
  style?: any;
  dataTestid?: string | undefined;
}

export const IconAddress = (props: IconProps) => <></>;

export const IconApply = (props: IconProps) => (
  <TbCloudDownload
    className={props.className}
    style={props.style}
    data-testid={props.dataTestid}
  />
);

export const IconUpdate = (props: IconProps) => (
  <GrUpdate
    className={props.className}
    style={props.style}
    data-testid={props.dataTestid}
  />
);

export const IconArrowDown = (props: IconProps) => (
  <FaCaretDown
    className={props.className}
    style={props.style}
    data-testid={props.dataTestid}
  />
);

export const IconArrowUp = (props: IconProps) => (
  <FaCaretUp
    className={props.className}
    style={props.style}
    data-testid={props.dataTestid}
  />
);

export const IconBetween = (props: IconProps) => (
  <CgArrowsExchangeAlt
    className={props.className}
    style={props.style}
    data-testid={props.dataTestid}
  />
);

export const IconPracticesAssigned = (props: IconProps) => (
  <RiFolderUserLine
    className={props.className}
    style={props.style}
    data-testid={props.dataTestid}
  />
);

export const IconPracticesRejected = (props: IconProps) => (
  <RiDeleteBin6Line
    className={props.className}
    style={props.style}
    data-testid={props.dataTestid}
  />
);

export const IconBusiness = (props: IconProps) => (
  <IoIosBusiness
    className={props.className}
    style={props.style}
    data-testid={props.dataTestid}
  />
);

export const IconDocumentCancelled = (props: IconProps) => (
  <HiOutlineDocumentRemove
    className={props.className}
    style={props.style}
    data-testid={props.dataTestid}
  />
);

export const IconCancel = (props: IconProps) => (
  <ImCancelCircle
    className={props.className}
    style={props.style}
    data-testid={props.dataTestid}
  />
);

export const IconCar = (props: IconProps) => (
  <FaCar
    className={props.className}
    style={props.style}
    data-testid={props.dataTestid}
  />
);

export const IconCopy = (props: IconProps) => (
  <VscCopy
    className={props.className}
    style={props.style}
    data-testid={props.dataTestid}
  />
);

export const IconClaim = (props: IconProps) => (
  <BsLightning
    className={props.className}
    style={props.style}
    data-testid={props.dataTestid}
  />
);

export const IconClose = (props: IconProps) => (
  <AiOutlineCloseCircle
    className={props.className}
    style={props.style}
    data-testid={props.dataTestid}
  />
);

export const IconCheck = (props: IconProps) => (
  <AiOutlineCheck
    className={props.className}
    style={props.style}
    data-testid={props.dataTestid}
  />
);

export const IconCheckList = (props: IconProps) => (
  <BsCardChecklist
    className={props.className}
    style={props.style}
    data-testid={props.dataTestid}
  />
);

export const IconContact = (props: IconProps) => (
  <AiOutlineContacts
    className={props.className}
    style={props.style}
    data-testid={props.dataTestid}
  />
);

export const IconDaily = (props: IconProps) => (
  <BsCalendar3
    className={props.className}
    style={props.style}
    data-testid={props.dataTestid}
  />
);

export const IconDelete = (props: IconProps) => (
  <MdDelete
    className={props.className}
    style={props.style}
    data-testid={props.dataTestid}
  />
);

export const IconDamageVehicle = (props: IconProps) => (
  <BiCar
    className={props.className}
    style={props.style}
    data-testid={props.dataTestid}
  />
);

export const IconDamagePerson = (props: IconProps) => (
  <IoBodyOutline
    className={props.className}
    style={props.style}
    data-testid={props.dataTestid}
  />
);

export const IconDamageProperty = (props: IconProps) => (
  <BsBox
    className={props.className}
    style={props.style}
    data-testid={props.dataTestid}
  />
);

export const IconDocument = (props: IconProps) => (
  <HiOutlineDocumentText
    className={props.className}
    style={props.style}
    data-testid={props.dataTestid}
  />
);

export const IconDocuments = (props: IconProps) => <></>;

export const IconEuro = (props: IconProps) => (
  <AiOutlineEuro
    className={props.className}
    style={props.style}
    data-testid={props.dataTestid}
  />
);

export const IconEmail = (props: IconProps) => (
  <AiOutlineMail
    className={props.className}
    style={props.style}
    data-testid={props.dataTestid}
  />
);

export const IconEdit = (props: IconProps) => (
  <BiEditAlt
    className={props.className}
    style={props.style}
    data-testid={props.dataTestid}
  />
);

export const IconFlash = (props: IconProps) => (
  <FcFlashOn
    className={props.className}
    style={props.style}
    data-testid={props.dataTestid}
  />
);

export const IconGiuridicalSubject = (props: IconProps) => (
  <IoBusiness
    className={props.className}
    style={props.style}
    data-testid={props.dataTestid}
  />
);

export const IconHelp = (props: IconProps) => (
  <TfiHelpAlt
    className={props.className}
    style={props.style}
    data-testid={props.dataTestid}
  />
);

export const IconHome = (props: IconProps) => (
  <BiHomeAlt
    className={props.className}
    style={props.style}
    data-testid={props.dataTestid}
  />
);

export const IconInbox = (props: IconProps) => (
  <InboxOutlined
    className={props.className}
    style={props.style}
    data-testid={props.dataTestid}
  />
);

export const IconInfo = (props: IconProps) => (
  <AiOutlineInfoCircle
    className={props.className}
    style={props.style}
    data-testid={props.dataTestid}
  />
);

export const IconInVerification = (props: IconProps) => (
  <HiOutlineDocumentSearch
    className={props.className}
    style={props.style}
    data-testid={props.dataTestid}
  />
);

export const IconLocked = (props: IconProps) => (
  <IoIosLock
    className={props.className}
    style={props.style}
    data-testid={props.dataTestid}
  />
);

export const IconModified = (props: IconProps) => (
  <FiEdit
    className={props.className}
    style={props.style}
    data-testid={props.dataTestid}
  />
);

export const IconNaturalSubject = (props: IconProps) => (
  <SlUser
    className={props.className}
    style={props.style}
    data-testid={props.dataTestid}
  />
);

export const IconNoSafe = (props: IconProps) => (
  <FcCancel
    className={props.className}
    style={props.style}
    data-testid={props.dataTestid}
  />
);

export const IconPlace = (props: IconProps) => (
  <MdOutlinePlace
    className={props.className}
    style={props.style}
    data-testid={props.dataTestid}
  />
);

export const IconPhone = (props: IconProps) => (
  <AiFillPhone
    className={props.className}
    style={props.style}
    data-testid={props.dataTestid}
  />
);

export const IconUnknown = (props: IconProps) => (
  <AiOutlineQuestionCircle
    className={props.className}
    style={props.style}
    data-testid={props.dataTestid}
  />
);

export const IconRemoveFilter = (props: IconProps) => (
  <RiFilterOffLine
    className={props.className}
    style={props.style}
    data-testid={props.dataTestid}
  />
);

export const IconSearch = (props: IconProps) => (
  <BiSearch
    className={props.className}
    style={props.style}
    data-testid={props.dataTestid}
  />
);

export const IconSafe = (props: IconProps) => (
  <AiOutlineSafetyCertificate
    className={props.className}
    style={props.style}
    data-testid={props.dataTestid}
  />
);

export const IconUser = (props: IconProps) => (
  <RiUserLine
    className={props.className}
    style={props.style}
    data-testid={props.dataTestid}
  />
);

export const IconUsers = (props: IconProps) => (
  <FiUsers
    className={props.className}
    style={props.style}
    data-testid={props.dataTestid}
  />
);

export const IconLoading = (
  <svg
    version="1.1"
    id="L7"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 100 100"
    enableBackground="new 0 0 100 100"
    xmlSpace="preserve"
  >
    <path
      fill={dekra.primaryColor}
      d="M31.6,3.5C5.9,13.6-6.6,42.7,3.5,68.4c10.1,25.7,39.2,38.3,64.9,28.1l-3.1-7.9c-21.3,8.4-45.4-2-53.8-23.3
  c-8.4-21.3,2-45.4,23.3-53.8L31.6,3.5z"
    >
      <animateTransform
        attributeName="transform"
        attributeType="XML"
        type="rotate"
        dur="2s"
        from="0 50 50"
        to="360 50 50"
        repeatCount="indefinite"
      />
    </path>
    <path
      fill={dekra.primaryColor}
      d="M42.3,39.6c5.7-4.3,13.9-3.1,18.1,2.7c4.3,5.7,3.1,13.9-2.7,18.1l4.1,5.5c8.8-6.5,10.6-19,4.1-27.7
  c-6.5-8.8-19-10.6-27.7-4.1L42.3,39.6z"
    >
      <animateTransform
        attributeName="transform"
        attributeType="XML"
        type="rotate"
        dur="1s"
        from="0 50 50"
        to="-360 50 50"
        repeatCount="indefinite"
      />
    </path>
    <path
      fill={dekra.primaryColor}
      d="M82,35.7C74.1,18,53.4,10.1,35.7,18S10.1,46.6,18,64.3l7.6-3.4c-6-13.5,0-29.3,13.5-35.3s29.3,0,35.3,13.5
  L82,35.7z"
    >
      <animateTransform
        attributeName="transform"
        attributeType="XML"
        type="rotate"
        dur="2s"
        from="0 50 50"
        to="360 50 50"
        repeatCount="indefinite"
      />
    </path>
  </svg>
);
