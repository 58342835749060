import { useTranslation } from "react-i18next";
import { Modifydetail } from "../../../../../../redux/apiSpecifications/apiCrud";
import React, { useMemo } from "react";

interface IPracticeModifiedDescriptionProps {
    modifyDetailsJson: Modifydetail[];
}

type MapEntry = {
    entity: string;
    field: string;
    oldValue: string;
    newValue: string;
};

const PracticeModifiedDescription = ({
    modifyDetailsJson,
}: IPracticeModifiedDescriptionProps) => {
    const { t } = useTranslation();

    const translateValue = (field: string, value: string) => {
        if (value === "[NULL]") {
            return t("historical-actions-field-null-value");
        } else {
            if (field === "practice_type_code") {
                return t(
                    `appraisals-details-tab-details-appraisals-expertise-type-${value}`
                );
            } else if (field === "claims_branch_code") {
                return t(`claims_branch_code-${value}`);
            } else if (field === "signature_type") {
                return t(`select-signature-type-${value}`);
            } else if (field === "status") {
                return t(`appraisal-statuses-${value}`);
            } else if (field === "urgency_reason") {
                return t(`urgency_reason-${value}`);
            } else if (
                ["expiration_date_client", "expiration_date_sla"].includes(
                    field
                )
            ) {
                if (["", "01/01/0001"].includes(value)) {
                    return t("historical-actions-date-field-no-value");
                }
            }
        }

        return value;
    };

    const getDescription: string[] = useMemo(() => {
        let descriptions: string[] = [];

        let map = new Map();

        modifyDetailsJson.forEach((element) => {
            const splitOldNew = element.key!.split("-");

            const splitEntity = splitOldNew[0].split(".");

            let mapEntry: MapEntry;

            if (map.has(splitOldNew[0])) {
                mapEntry = map.get(splitOldNew[0]);
            } else {
                mapEntry = {
                    entity: "", // splitEntity.length === 2 ? splitEntity[0] : "",
                    field:
                        splitEntity.length === 2
                            ? splitEntity[1]
                            : splitEntity[0],
                    oldValue: "",
                    newValue: "",
                };

                map.set(splitOldNew[0], mapEntry);
            }

            if (splitOldNew[1] === "old") {
                mapEntry!.oldValue = element.value!;
            } else {
                mapEntry!.newValue = element.value!;
            }
        });

        map.forEach((value: MapEntry, key) => {
            const fieldTranslation = t(
                `historical-actions-field-${value.field}`
            );

            const entityTranslation = t(
                value.entity === ""
                    ? ""
                    : `historical-actions-entity-${value.entity}`
            );

            let dictionaryKey = "";

            if (["True", "False"].includes(value.newValue)) {
                dictionaryKey =
                    value.entity === ""
                        ? `historical-actions-practice-modified-description-flag-${value.newValue}`
                        : `historical-actions-practice-modified-description-entity-flag-${value.newValue}`;
            } else {
                dictionaryKey =
                    value.entity === ""
                        ? `historical-actions-practice-modified-description`
                        : `historical-actions-practice-modified-description-entity`;
            }

            descriptions.push(
                t(dictionaryKey, {
                    field_name: fieldTranslation,
                    entity_name: entityTranslation,
                    old_value: translateValue(value.field, value.oldValue),
                    new_value: translateValue(value.field, value.newValue),
                })
            );
        });

        return descriptions;
    }, [modifyDetailsJson]);

    return (
        <>
            {getDescription.map((description, index) => (
                <React.Fragment key={index}>
                    {description}
                    <br />
                </React.Fragment>
            ))}
        </>
    );
};

export default PracticeModifiedDescription;
