import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { SelectStyled } from "../../../../../style/Input";
import { FormRow } from "../../../../../style/form";
import { UserConfigFormModel } from "./UserConfigFormModel";
import { SelectPair } from "../../../../../types/common.types";
import { Alert, Button, Divider, Space } from "antd";
import {
  BaseRole,
  BaseTenant,
  RoleType,
} from "../../../../../redux/apiSpecifications/apiFesf";

interface IUserConfigFormProps {
  model: UserConfigFormModel;
  roleTypes: RoleType[];
  enabledTenants: BaseTenant[];
  onChange: (value: any, field: string) => void;
}

const UserConfigForm = (props: IUserConfigFormProps) => {
  const { model, roleTypes, enabledTenants, onChange } = props;

  const { t } = useTranslation();

  const [selectedRoleType, setSelectedRoleType] = useState<
    RoleType | undefined
  >(undefined);

  useEffect(() => {
    if (model.roleType === "") setSelectedRoleType(undefined);
    else {
      setSelectedRoleType(
        roleTypes.find((_) => _.roleTypeCode === model.roleType)
      );
    }
  }, [model.roleType]);

  const handleFilter = (input: string, option: any) => {
    const text = input.toLowerCase();

    const optionLabel = (option?.label ?? "").toLowerCase();

    return optionLabel.includes(text);
  };

  const typeOptions = useMemo(() => {
    const result = roleTypes.map(
      (roleType) =>
        ({
          value: roleType.roleTypeCode!,
          label: roleType.roleTypeDescription!,
        } as SelectPair)
    );

    result.unshift({
      value: "",
      label: "",
    });

    return result;
  }, [roleTypes]);

  const roleOptions = useMemo(() => {
    if (selectedRoleType === undefined) return [];

    const roleOptionsArray: SelectPair[] = [];

    selectedRoleType.roles?.forEach((role) => {
      roleOptionsArray.push({
        value: role.id!.toString(),
        label: t(`role-description-${role.roleCode!}`),
      });
    });

    return roleOptionsArray;
  }, [selectedRoleType]);

  const tenantOptions = useMemo(() => {
    const tenantOptionsArray: SelectPair[] = [];

    enabledTenants?.forEach((tenant) => {
      tenantOptionsArray.push({
        value: tenant.id!.toString(),
        label: tenant.companyName!,
      });
    });

    return tenantOptionsArray;
  }, [enabledTenants]);

  const checkIfIncludePeritoInterno = (selectedRoles: string[]) => {
    const result = selectedRoleType?.roles?.find((r) => r.roleCode! === "E2"); // Perito interno

    if (result != null) {
      return selectedRoles.includes(result.id!.toString());
    }

    return false;
  };

  const checkIfIncludePeritoEsterno = (selectedRoles: string[]) => {
    const result = selectedRoleType?.roles?.find((r) => r.roleCode! === "E3"); // Perito esterno

    if (result != null) {
      return selectedRoles.includes(result.id!.toString());
    }

    return false;
  };

  return (
    <>
      <FormRow>
        <SelectStyled
          label={t("user-config-form-role-type-label")}
          tooltip={t("user-config-form-role-type-tooltip")}
          onChange={(roleType) => onChange(roleType, "roleType")}
          value={model.roleType}
          options={typeOptions}
        />
      </FormRow>

      {selectedRoleType && (
        <FormRow>
          <SelectStyled
            label={t("user-config-form-roles-label")}
            tooltip={t("user-config-form-roles-tooltip")}
            onChange={(roleIds) => onChange(roleIds, "userRoleIds")}
            value={selectedRoleType.isMultiRole ? (roleOptions.length > 0 ? model.userRoleIds : []) : (roleOptions.length > 0 ? model.userRoleIds[0] : null)}
            mode={selectedRoleType.isMultiRole ? "multiple" : undefined}
            allowClear={true}
            options={roleOptions}
            filterOption={handleFilter}
          />
        </FormRow>
      )}

      {selectedRoleType && (
        <FormRow>
          <SelectStyled
            label={t("user-config-form-tenants-label")}
            tooltip={t("user-config-form-tenants-tooltip")}
            onChange={(tenantIds) => onChange(tenantIds, "userTenantIds")}
            value={tenantOptions.length > 0 ? model.userTenantIds : []}
            mode={selectedRoleType.isMultiTenant ? "multiple" : undefined}
            allowClear={true}
            options={tenantOptions}
            filterOption={handleFilter}
            dropdownRender={(menu) => (
              <>
                {menu}
                {selectedRoleType.isMultiTenant && (
                  <>
                    <Divider style={{ margin: "8px 0" }} />
                    <Space style={{ padding: "0 8px 4px" }}>
                      <Button
                        type="link"
                        onClick={() =>
                          onChange(
                            tenantOptions.map((r) => r.value),
                            "userTenantIds"
                          )
                        }
                      >
                        {t("select-all-tenants-button")}
                      </Button>
                    </Space>
                  </>
                )}
              </>
            )}
          />
        </FormRow>
      )}

      {checkIfIncludePeritoInterno(model.userRoleIds) && (
        <Space direction="vertical" style={{ width: "100%" }}>
          <Alert
            message={t("user-config-form-internal-appraiser-disclaimer")}
            type="info"
          />
        </Space>
      )}

      {checkIfIncludePeritoEsterno(model.userRoleIds) && (
        <Space direction="vertical" style={{ width: "100%" }}>
          <Alert
            message={t("user-config-form-external-appraiser-disclaimer")}
            type="info"
          />
        </Space>
      )}

      {/* <pre>{JSON.stringify(model, null, 2)}</pre> */}
    </>
  );
};

export default UserConfigForm;
