import { getLoginRequest, getMsalConfig } from "../../../config/azureConfig";
import {
    BrowserAuthOptions,
    EndSessionPopupRequest,
    PublicClientApplication,
} from "@azure/msal-browser";
import { BaseQueryApi } from "@reduxjs/toolkit/query";
import { logout, setAuthorization } from "../../features/userSlice";
import { RootState } from "../../store";
import jwtDecode from "jwt-decode";
import moment from "moment";

export const checkToken = async (api: BaseQueryApi) => {
    const authorization = (api.getState() as RootState)?.user.authorization;

    const token = authorization.replace("Bearer ", "");

    let decoded: any = jwtDecode(token);

    const now = moment();

    const mustLogout = now.isAfter(
        moment(decoded.exp * 1000).add(30, "minutes")
    );

    const mustRefreshToken = moment(decoded.exp * 1000)
        .add(-5, "minutes")
        .isBefore(now);

    if (mustLogout) {
        const auth = getMsalConfig().auth as BrowserAuthOptions;

        const msalInstance = new PublicClientApplication({
            auth: auth,
        });

        msalInstance.logoutRedirect({
            postLogoutRedirect: "/",
            mainWindowRedirectUri: "/",
        } as EndSessionPopupRequest);

        api.dispatch(logout());
    } else if (mustRefreshToken) {
        const auth = getMsalConfig().auth as BrowserAuthOptions;

        const msalInstance = new PublicClientApplication({
            auth: auth,
        });

        const accounts = msalInstance.getAllAccounts();

        const response = await msalInstance.acquireTokenSilent({
            ...getLoginRequest(),
            forceRefresh: true,
            account: accounts[0],
        });

        const jwt = "Bearer " + response.accessToken;
        api.dispatch(setAuthorization(jwt));
    }
};
