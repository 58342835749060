import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Modal, Tooltip } from "antd";
import { ButtonCancel, ButtonConfirm } from "../../../Layout/Buttons";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { LoadingInTabWrapper } from "../../../../style/DashbordWidgetWrappers";
import { IconLoading } from "../../../../config/icons";
import {
  TenantRatesByTypeHist,
  TenantRatesByTypeHistItem,
  useGetTenantRatesHistoryQuery,
  useLazyGetTenantRatesHistoryQuery,
} from "../../../../redux/apiSpecifications/apiFesf";
import { useAuthorization } from "../../../../hooks/useAuthorization";

const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;

  .message {
    font-size: 1.2em;
  }
`;

const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 4em 3em 2em 3em;
  gap: 1em;

  .title {
    font-size: 1.4em;
    margin-bottom: 1em;
    text-align: center;
  }

  .history {
    max-width: 95vw;
    overflow-x: auto;

    thead {
      background-color: #e5e5e5;
      th {
        padding: 0.25em 1em;
        border: 1px solid #eee;
        vertical-align: middle;
      }
    }

    tbody {
      .date {
        padding: 0 1em;
      }

      .second-row {
      }

      .row-separator {
        background-color: #eaeaea;
      }

      tr {
        &:last-child {
          .row-separator {
            display: none;
          }
        }
      }

      td {
        text-align: center;
        border: 1px solid #eaeaea;

        &.rate-type {
          text-align: left;
          padding: 0.25em 2em;
          font-weight: bold;
          text-transform: uppercase;
          font-size: 0.8em;
        }
      }
    }
  }

  .buttons {
    margin-top: 2em;
  }
`;

/**
 * RatesClientModalHistory properties
 * @property {number} tenantId - tenant's id
 * @property {string} tenantName - name of the tenant
 * @property {() => void} onClose - callback to close the modal from the parent
 */
interface IRatesClientModalHistoryProps {
  tenantId: number;
  tenantName: string;
  onClose: () => void;
}

/**
 * @description Show the change in the clients' rates
 * @param {IRatesClientModalHistoryProps} props - component properties
 */
const RatesClientModalHistory = ({
  tenantId,
  tenantName,
  onClose,
}: IRatesClientModalHistoryProps) => {
  //Common variables
  const { t } = useTranslation();
  const activeRole = useSelector((state: RootState) => state.user.activeRole);
  const getAuthorization = useAuthorization().getAuthorization;

  //Local state
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const [ratesCode, setRatesCode] = useState<string[]>([]);
  const [tenantRatesHistory, setTenantRatesHistory] = useState<
    TenantRatesByTypeHist | undefined
  >();

  // //Fetching data
  const [getTenantRatesHistory] = useLazyGetTenantRatesHistoryQuery();

  // useEffects
  useEffect(() => {
    (async () => {
      setIsLoading(true);

      const response = await getTenantRatesHistory({
        authorization: await getAuthorization(),
        id: tenantId,
        activeRole: activeRole!,
      });

      if (response.data) {
        const newRatesCode: string[] = Array.from(
          new Set(
            response.data
              .map((item) =>
                item.tenantRateConfig?.map((rate) => rate.practiceTypeCode)
              )
              .flat()
              .filter((code) => code !== undefined) as string[]
          )
        );
        setRatesCode(newRatesCode);
        setTenantRatesHistory(response.data);
      }

      setIsLoading(false);
    })();
  }, [tenantId]);

  // conditional content
  const showLoader = isLoading;
  const showError = !isLoading && error;
  const showContent = !isLoading && !error && tenantRatesHistory;

  return (
    <Modal open={true} onCancel={onClose} footer={null} width={"auto"}>
      {showLoader && (
        <LoadingInTabWrapper>
          <div
            className="box"
            data-testid={""}
            style={{ marginBottom: "10em" }}
          >
            {IconLoading}
          </div>
        </LoadingInTabWrapper>
      )}

      {showError && (
        <ErrorWrapper>
          <div className="message">
            {t("rate-clients-history-modal-error-message")}
          </div>
        </ErrorWrapper>
      )}

      {showContent && (
        <ModalContent
          name={tenantName}
          history={tenantRatesHistory}
          ratesCode={ratesCode}
          onClose={onClose}
          data-testid={""}
        />
      )}
    </Modal>
  );
};

type IModalContentProps = {
  name: string | undefined;
  ratesCode: string[];
  history: TenantRatesByTypeHistItem[] | undefined;
  onClose: () => void;
};

const getRateValue = (
  rate: TenantRatesByTypeHistItem,
  rateCode: string,
  rateType: string
) => {
  const rateValue = rate.tenantRateConfig?.find(
    (trc) => trc.practiceTypeCode === rateCode
  )?.practiceTypeBaseRateAmount;

  return !rateValue
    ? ""
    : rateType === "rate-base"
    ? `${rateValue}€`
    : rateType === "rate-perc"
    ? `${rateValue}%`
    : "";
};

const ModalContent = (props: IModalContentProps) => {
  const { name, history, ratesCode, onClose } = props;
  const { t } = useTranslation();

  const firstItem = history ? history[0] : undefined;

  return (
    <ModalContentWrapper>
      <div className="title">
        {t("rate-clients-history-modal-title")} {name}
      </div>
      <div className="history-wrapper">
        <table className="history">
          <thead>
            <tr>
              <th>{t("rate-clients-history-modal-tenant-date-from")}</th>
              <th>{t("rate-clients-history-modal-tenant-date-to")}</th>
              <th className="header-rate-type"></th>
              {ratesCode.map((rateCode) => (
                <th key={rateCode} className="rate-name">
                  <Tooltip title={t(`rate-name-tooltip-${rateCode}`)}>
                    {t(`rate-name-label-${rateCode}`)}
                  </Tooltip>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {history?.map((item: TenantRatesByTypeHistItem, i) => (
              <Fragment key={i}>
                <tr className="history-item">
                  <td rowSpan={2} className="date">
                    {item.validityStartDate}
                  </td>
                  <td rowSpan={2} className="date">
                    {item.validityEndDate}
                  </td>
                  <td className="rate-type">{t("rate-name-base-rate")}</td>

                  {ratesCode.map((rateCode) => (
                    <td key={rateCode} className="rate-name">
                      {getRateValue(item, rateCode, "rate-base")}
                    </td>
                  ))}
                </tr>
                <tr className="history-item second-row">
                  <td className="rate-type">{t("rate-name-penalty-perc")}</td>

                  {ratesCode.map((rateCode) => (
                    <td key={rateCode} className="rate-value">
                      {getRateValue(item, rateCode, "rate-perc")}
                    </td>
                  ))}
                </tr>
                <tr>
                  <td
                    colSpan={ratesCode.length + 3}
                    className="row-separator"
                  ></td>
                </tr>
              </Fragment>
            ))}
          </tbody>
        </table>
      </div>
      <div className="buttons">
        <ButtonConfirm onClick={() => onClose()}>
          {t("button-close")!}
        </ButtonConfirm>
      </div>
    </ModalContentWrapper>
  );
};

export default RatesClientModalHistory;
