import React, { useCallback, useEffect, useMemo, useState } from "react";
import ConcludePracticePresentational from "./ConcludePracticePresentational";
import {
  AppraisalDocProcessingOutput,
  AppraisalFinalizeResponse,
  AppraisalQuestionnaire,
  DocumentFiles,
  FinalisePracticeApiResponse,
  GetQuestionnaireApiResponse,
  PracticeAttachment,
  PracticeDocument,
  PracticeDocumentsByCategory,
  ReadOnlyPractice,
  RejectPracticeApiResponse,
  RejectionRequest,
  UploadAdzApiResponse,
  useConfirmQuestionnaireMutation,
  useFinalisePracticeMutation,
  useLazyGetPracticeDetailQuery,
  useLazyGetQuestionnaireQuery,
  useRejectPracticeMutation,
  useUploadAdzMutation,
  useUploadPracticeDocumentMutation,
} from "../../../../../../redux/apiSpecifications/apiCrud";
import { ADZPreviewStatuses } from "./ADZPreview";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { SerializedError } from "@reduxjs/toolkit";
import { useAuthorization } from "../../../../../../hooks/useAuthorization";
import { fileToBase64 } from "../../../../../../utils/fileUtils";
import { removeMimeType } from "../../../../../../utils/base64";
import { PrimaId } from "../../../../../../config/const";
import { set } from "yaml/dist/schema/yaml-1.1/set";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../redux/store";
import CongruityReport from "./CongruityReport";
import { Alert, Space } from "antd";
import { useTranslation } from "react-i18next";
import { useNotifications } from "../../../../../../hooks/useNotifications";

// Types

export type ConcludePracticeStatuses =
  | "select-is-negative"
  | "load-adz"
  | "loading-adz"
  | "loading-document"
  | "adz-valid"
  | "adz-invalid"
  | "adz-valid-with-warnigns"
  | "adz-without-agreement"
  | "questionnare"
  | "load-proof-of-access-error"
  | "solve-missing-agreement"
  | "saving-questionnaire"
  | "saving-questionnaire-error"
  | "questionnaire-saved"
  | "finalizing-practice"
  | "practice-finalized"
  | "finalizing-practice-error"
  | "retrieve-practice-status"
  | "practice-status-refreshed"
  | "loading-questionnaire"
  | "rejecting-practice"
  | "practice-rejected"
  | "rejecting-practice-error";

/**
 * @desccription
 * @interface IConcludePracticeProps
 * @property {ReadOnlyPractice} ipractice - object practice
 */

export type UpdateQuestionnaireDataType = {
  questionnaire: {
    agreement: string;
    isVocalAgreement?: boolean;
  };
  documents?: PracticeDocument[];
  status: ConcludePracticeStatuses;
};

const removeMimetypeFromQuestionnarieAttachments = (
  questionnaire: AppraisalQuestionnaire
) => {
  const updatedQuestionnaire: AppraisalQuestionnaire = {
    ...questionnaire,
    documents: (questionnaire.documents || []).map((d) => ({
      ...d,
      attachmentsFiles: (d.attachmentsFiles || [])
        .filter((a) => !!a.base64File)
        .map((a) => ({
          ...a,
          base64File: removeMimeType(a.base64File!),
        })),
    })),
  };

  return updatedQuestionnaire;
};

interface IConcludePracticeProps {
  practice: ReadOnlyPractice | undefined;
  onRefreshPractice: (practice: ReadOnlyPractice) => void;
}

/**
 * ConcludePractice component
 *
 * @component
 * @example
 * <ConcludePractice />
 */
const ConcludePractice: React.FC<IConcludePracticeProps> = (props) => {
  const { t } = useTranslation();

  // Common variables
  const { getAuthorization } = useAuthorization();
  const { activeRole } = useSelector((state: RootState) => state.user);
  const { displaySuccess, displayFetchBaseQueryErrorNotification } =
        useNotifications();

  // Local state
  const [practice, setPractice] = useState<ReadOnlyPractice | undefined>(
    props.practice
  );
  const [status, setStatus] =
    useState<ConcludePracticeStatuses>("select-is-negative");

  const [adzDocument, setAdzDocument] = useState<
    PracticeAttachment | undefined
  >();

  const [adzResult, setAdzResult] =
    React.useState<AppraisalDocProcessingOutput>();
  const [adzStatus, setAdzStatus] = React.useState<ADZPreviewStatuses>("empty");
  const [adzErrorMessage, setADZErrorMessage] = React.useState<string>();

  const [questionnaireResult, setQuestionnaireResult] =
    useState<AppraisalQuestionnaire>({} as AppraisalQuestionnaire);

  const [refreshedPracticeStatus, setRefreshedPracticeStatus] =
    useState<string>(practice?.status || "");

  const [isFinalized, setIsFinalized] = useState<boolean>(false);

  // TEMP
  const [desiredOutput, setDesiredOutput] = React.useState<number>(104);
  const [desiredClient, setDesiredClient] = React.useState<number>(104);
  const [desiredSaveQuestionnaireResult, setDesiredSaveQuestionnaireResult] =
    React.useState<number>(104);
  const [desiderdUpdatedStatus, setDesiderdUpdatedStatus] =
    React.useState<string>("CO");

  const isLocalhost = window.location.href.indexOf("localhost") > -1;

  const isPrima = (() => {
    if (isLocalhost) return desiredClient === 1; // temp x local testing

    return practice?.idTenant === PrimaId;
  })();

  // API
  const [uploadADZ] = useUploadAdzMutation();
  const [uploadDocument] = useUploadPracticeDocumentMutation();
  const [saveQuestionnaire] = useConfirmQuestionnaireMutation();
  const [finalizePractice] = useFinalisePracticeMutation();
  const [retrievePractice] = useLazyGetPracticeDetailQuery();
  const [loadQuestionnaire] = useLazyGetQuestionnaireQuery();
  const [rejectPractice] = useRejectPracticeMutation();

  // Use effects
  useEffect(() => {
    setPractice(props.practice);
  }, [props.practice]);

  useEffect(() => {
    if (props.practice?.status === "RE") {
      reloadQuestionnaire(status);
    }
  }, []);

  /**
   * Check the result of the ADZ
   */
  useEffect(() => {
    if (!adzResult) {
      // setAdzStatus("empty");
      // setStatus("load-adz");
      return;
    }

    const errors =
      adzResult?.alerts?.filter((a) => a.alertType === "error") || [];
    const warnings =
      adzResult?.alerts?.filter((a) => a.alertType === "warning") || [];

    let updatedADZStatus: ADZPreviewStatuses = "empty";
    let updatedStatus: ConcludePracticeStatuses = "load-adz";

    if (adzErrorMessage) {
      updatedStatus = "adz-invalid";
      updatedADZStatus = "invalid";
    } else {
      if (errors?.length === 0) {
        if (warnings?.length === 0) {
          // no errors & no warnings
          updatedStatus = "questionnare";
          updatedADZStatus = "valid";
        } else {
          // no errors & warnings
          // WCC001 - "Accordo non Presente"
          if (warnings?.find(({ alertCode }) => alertCode === "WCC001")) {
            updatedStatus = "adz-without-agreement";
            updatedADZStatus = "invalid";
          } else {
            updatedStatus = "adz-valid-with-warnigns";
            updatedADZStatus = "valid";
          }
        }
      } else {
        // errors
        updatedStatus = "adz-invalid";
        updatedADZStatus = "invalid";
      }
    }

    setAdzStatus(updatedADZStatus);
    setStatus(updatedStatus);
  }, [adzResult, adzErrorMessage]);

  // if it's concluded or in review then load questionnaire
  useEffect(() => {
    if (
      practice?.id === undefined ||
      ["CO", "IV", "CI", "CR", "CF"].includes(practice?.status || "") == false
    )
      return;
    (async () => {
      setStatus("loading-questionnaire");

      const { data, isSuccess } = await loadQuestionnaire({
        authorization: await getAuthorization(),
        id: practice?.id!,
      });
      if (isSuccess) {
        setQuestionnaireResult(data as AppraisalQuestionnaire);
        setAdzStatus("valid");
      } else {
        // to do
      }

      if (["CO", "CI", "CR", "CF"].includes(practice?.status || "")) {
        setStatus("practice-finalized");
      } else if (practice?.status === "IV") {
        setStatus("questionnaire-saved");
      }
    })();
  }, [practice?.id, practice?.status]);

  const showCongruitReport = useMemo(() => {
    return (
      ["IV", "CP", "CO", "CI", "CR", "CF", "RE"].includes(practice?.status || "") &&
      practice?.practiceTypeCode === "7"
    );
  }, [practice]);

  const showQuestionnaire = useMemo(() => {
    return ["CI", "CR", "CF"].includes(practice?.status || "");
  }, [practice]);

  // HANDLES
  /**
   * Handles the loading of an ADZ file
   *
   * @param {React.ChangeEvent<HTMLInputElement>} e - The file select event object
   * @returns {Promise<void>}
   */

  const handleResetLoadADZ = () => {
    setRefreshedPracticeStatus("CP");
    setStatus("select-is-negative");
  };

  const handleLoadADZ = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!e || !e.target || !e.target.files) return;

      const selectedFile = e.target.files[0];
      if (!selectedFile) return;

      let base64File = await fileToBase64(selectedFile);
      base64File = removeMimeType(base64File);

      setStatus("loading-adz");
      setAdzStatus("empty");
      setADZErrorMessage(undefined);

      const response = await uploadADZ({
        authorization: await getAuthorization(),
        id: isLocalhost ? desiredOutput : practice?.id!, // temp x local testing
        adzUpload: {
          id: 0,
          originalName: selectedFile.name,
          base64File,
          isNegative: questionnaireResult.isNegative,
        },
      });

      const correctResponse = response as { data: UploadAdzApiResponse };
      const errorResponse = response as {
        error: FetchBaseQueryError | SerializedError;
      };

      if (correctResponse.data) {
        const output = correctResponse.data as AppraisalDocProcessingOutput;
        const message = correctResponse.data as string;

        //let agreement = output.isConservativeAgreementFile ? "S" : "N";
        // let agreement =
        //   output.isAgreementError || output.isAgreementWarning ? "N" : "S";
        // if (output.isForcedAgreement) agreement = "SN";
        // if (output.isVerbalAgreement) agreement = "SN";

        setAdzDocument({
          id: 0,
          originalName: selectedFile.name,
          base64File,
          name: "ADZ",
        });

        setQuestionnaireResult({
          isNegative: questionnaireResult.isNegative,
          negativeReasonCode: questionnaireResult.negativeReasonCode,
          accessProof: output.isAccessProofFile,
          agreement: output.agreement,
          isVerbalAgreement: output.isVerbalAgreement,
          isForcedAgreement: output.isForcedAgreement,
          isConsistent: "",
          isUneconomic: output.isUneconomic,
          notes: "",
          documents: [],
        });
        if (message && message.length > 0) {
          // it's like an error
          setADZErrorMessage(message);
          setAdzResult(undefined);
        } else {
          setAdzResult(output);
        }
      } else if (errorResponse.error) {
        setStatus("adz-invalid");
      }
    },
    [
      desiredOutput,
      questionnaireResult.isNegative,
      questionnaireResult.negativeReasonCode,
    ]
  );

  const handleAddProofOfAccess = useCallback(
    async (practiceDocument: PracticeDocument) => {
      const prevStatus = status;
      setStatus("loading-document");
      const response = await uploadDocument({
        authorization: await getAuthorization(),
        id: practice?.id!,
        practiceDocument,
      });
      const correctResponse = response as {
        data: PracticeDocumentsByCategory;
      };
      const errorResponse = response as {
        error: FetchBaseQueryError | SerializedError;
      };

      if (correctResponse.data) {
        // not need to update practice documents
        setStatus(prevStatus);
      } else if (errorResponse.error) {
        setStatus("load-proof-of-access-error");
      }
    },
    [status]
  );

  const handleRestart = () => setStatus("load-adz");

  const handleStartQuestionnaire = () => setStatus("questionnare");

  const handleResolveMissingAgreement = () =>
    setStatus("solve-missing-agreement");

  const handleUpdateLocalQuestionnaire = ({
    questionnaire,
    documents,
    status: newStatus,
  }: UpdateQuestionnaireDataType) => {
    setStatus(newStatus);

    const updatedADZStatus = newStatus === "questionnare" ? "valid" : "empty";
    setAdzStatus(updatedADZStatus);

    const updatedQuestionnaireResult = {
      ...questionnaireResult,
      agreement: questionnaire.agreement,
      isVerbalAgreement: questionnaire.isVocalAgreement,
      documents: [
        ...(questionnaireResult.documents || []),
        ...(documents || []),
      ],
    };

    setQuestionnaireResult(updatedQuestionnaireResult);
  };

  const handleNoConfirmAgreement = () => {
    setStatus("load-adz");
    setAdzStatus("empty");
  };

  const handleChangeIsNegative = (value: boolean | undefined) => {
    const updatedQuestionnaireResult = {
      ...questionnaireResult,
      isNegative: value,
      negativeReasonCode: "",
    };
    setQuestionnaireResult(updatedQuestionnaireResult);
  };

  const handleChangeNegativeReason = (value: string | undefined) => {
    const updatedQuestionnaireResult = {
      ...questionnaireResult,
      negativeReasonCode: value,
    };
    setQuestionnaireResult(updatedQuestionnaireResult);
  };

  const handleReset = () => {
    setStatus("select-is-negative");
    setAdzStatus("empty");
    setADZErrorMessage(undefined);
    setAdzResult(undefined);
    handleChangeIsNegative(undefined);
    handleChangeNegativeReason("");
  };

  const handleSaveQuestionnaire = async (
    appraisalQuestionnaire: AppraisalQuestionnaire
  ) => {
    setStatus("saving-questionnaire");

    const response = await saveQuestionnaire({
      authorization: await getAuthorization(),
      id: isLocalhost ? desiredSaveQuestionnaireResult : practice?.id!, // temp x local testing
      appraisalQuestionnaire: removeMimetypeFromQuestionnarieAttachments(
        appraisalQuestionnaire
      ),
    });

    const errorResponse = response as {
      error: FetchBaseQueryError | SerializedError;
    };

    if (isLocalhost) {
      if (desiredSaveQuestionnaireResult === 1) {
        setStatus("questionnaire-saved");
        retrievePracticeStatus();
      } else {
        setStatus("saving-questionnaire-error");
      }
    } else {
      // this time I have to check the error, because the correct response is empty
      if (errorResponse.error) {
        setStatus("saving-questionnaire-error");
      } else {
        setStatus("questionnaire-saved");
        retrievePracticeStatus();
      }
    }
  };

  const handleConcludePracticeST = async () => {
    setStatus("finalizing-practice");

    const response = await finalizePractice({
      authorization: await getAuthorization(),
      id: isLocalhost ? desiredSaveQuestionnaireResult : practice?.id!, // temp x local testing
      activeRole: activeRole!,
      appraisalQuestionnaire: {},
    });

    const errorResponse = response as {
      error: FetchBaseQueryError | SerializedError;
    };

    if (isLocalhost) {
      if (desiredSaveQuestionnaireResult === 1) {
        setStatus("practice-finalized");
        retrievePracticeStatus();
        setIsFinalized(true);
      } else {
        setStatus("finalizing-practice-error");
      }
    } else {
      // this time I have to check the error, because the correct response is empty
      if (errorResponse.error) {
        setStatus("finalizing-practice-error");
      } else {
        setStatus("practice-finalized");
        retrievePracticeStatus();
      }
    }
  };

  const handleConcludePractice = async (
    appraisalQuestionnaire: AppraisalQuestionnaire | null
  ) => {
    if (!appraisalQuestionnaire) {
      handleConcludePracticeST();

      return;
    }

    setStatus("finalizing-practice");

    const updatedQuestionnaire: AppraisalQuestionnaire = {
      ...questionnaireResult,

      isNegative: appraisalQuestionnaire.isNegative,
      negativeReasonCode: appraisalQuestionnaire.negativeReasonCode,
      accessProof: appraisalQuestionnaire.accessProof,
      agreement: appraisalQuestionnaire.agreement,
      isConsistent: appraisalQuestionnaire.isConsistent,
      isUneconomic: appraisalQuestionnaire.isUneconomic,
      notes: appraisalQuestionnaire.notes,
      documents: appraisalQuestionnaire.documents
    };

    setQuestionnaireResult(updatedQuestionnaire);

    const response = await finalizePractice({
      authorization: await getAuthorization(),
      id: isLocalhost ? desiredSaveQuestionnaireResult : practice?.id!, // temp x local testing
      activeRole: activeRole!,
      appraisalQuestionnaire:
        removeMimetypeFromQuestionnarieAttachments(updatedQuestionnaire),
    });

    const errorResponse = response as {
      error: FetchBaseQueryError | SerializedError;
    };

    if (isLocalhost) {
      if (desiredSaveQuestionnaireResult === 1) {
        setStatus("practice-finalized");
        retrievePracticeStatus();
        setIsFinalized(true);
      } else {
        setStatus("finalizing-practice-error");
      }
    } else {
      // this time I have to check the error, because the correct response is empty
      if (errorResponse.error) {
        setStatus("finalizing-practice-error");

        displayFetchBaseQueryErrorNotification(errorResponse.error);
      } else {
        const correctResponse = response as {
          data: AppraisalFinalizeResponse;
        };
        const practiceStatus = correctResponse.data.practiceStatus;
        setStatus("practice-finalized");
        practiceStatus === 'IV' 
          ? displaySuccess(t("appraisals-conclude-practice-verify-status-message")) 
            : displaySuccess(t("appraisals-conclude-practice-success-status-message"));
       

        retrievePracticeStatus();
      }
    }
  };

  const retrievePracticeStatus = async () => {
    // to do

    setStatus("retrieve-practice-status");

    const { data, error, isSuccess, status } = await retrievePractice({
      activeRole: activeRole!,
      authorization: await getAuthorization(),
      id: practice?.id!,
    });

    if (isSuccess && data) {
      const practiceDetails = data as ReadOnlyPractice;
      const updatedStatus = isLocalhost
        ? desiderdUpdatedStatus
        : practiceDetails.status || "";
      setRefreshedPracticeStatus(updatedStatus);
      setStatus("practice-status-refreshed");
      props.onRefreshPractice(practiceDetails);
    } else {
      console.log(error);
    }

    setStatus("practice-status-refreshed");
  };

  const handleAddConservativeAgreement = (doc: DocumentFiles) => {
    const updatedQuestionnaireResult: AppraisalQuestionnaire = {
      ...questionnaireResult,
      agreement: "S",
      documents: [...(questionnaireResult.documents || []), doc],
    };

    setQuestionnaireResult(updatedQuestionnaireResult);
  };

  const handleRemoveConservativeAgreement = () => {
    const updatedQuestionnaireResult: AppraisalQuestionnaire = {
      ...questionnaireResult,
      agreement: "SN",
      documents: (questionnaireResult.documents || []).filter(
        (d) => d.documentTypeCode !== "ACC"
      ),
    };

    setQuestionnaireResult(updatedQuestionnaireResult);
  };

  const handleModifyQuestionnaire = () => {
    setStatus("questionnare");
  };

  const handleRejectPractice = async (rejectRequest: RejectionRequest) => {
    setStatus("rejecting-practice");

    const response = await rejectPractice({
      authorization: await getAuthorization(),
      id: practice?.id!,
      rejectionRequest: rejectRequest,
    });

    const correctResponse = response as { data: ReadOnlyPractice };

    const errorResponse = response as {
      error: FetchBaseQueryError | SerializedError;
    };

    if (errorResponse.error) {
      setStatus("rejecting-practice-error");
    } else {
      setStatus("practice-rejected");

      // retrievePracticeStatus();
      props.onRefreshPractice(correctResponse.data);
      reloadQuestionnaire("practice-rejected");
      setRefreshedPracticeStatus("RE");
    }
  };

  const reloadQuestionnaire = async (nextStatus: ConcludePracticeStatuses) => {
    setStatus("loading-questionnaire");

    const { data, isSuccess } = await loadQuestionnaire({
      authorization: await getAuthorization(),
      id: practice?.id!,
    });
    if (isSuccess) setQuestionnaireResult(data as AppraisalQuestionnaire);

    setStatus(nextStatus);
  };

  return showCongruitReport && !showQuestionnaire ? (
    <CongruityReport
      idPractice={practice?.id}
      concludePractice={handleConcludePractice}
      status={status}
      isConcludePracticeTab={true}
      practiceStatus={practice?.status}
      onRejectPractice={handleRejectPractice}
    />
  ) : (
    <>
      <ConcludePracticePresentational
        status={status}
        practiceStatus={practice?.status || ""}
        questionnaireResult={questionnaireResult}
        adzDocument={adzDocument}
        adzStatus={adzStatus}
        adzResult={adzResult}
        adzErrorMessage={adzErrorMessage}
        idPractice={practice?.id}
        isPrima={isPrima}
        isFinalized={isFinalized}
        practiceRefreshedStatus={refreshedPracticeStatus}
        onLoadADZ={handleLoadADZ}
        onRestart={handleRestart}
        onStartQuestionnaire={handleStartQuestionnaire}
        onResolveMissingAgreement={handleResolveMissingAgreement}
        onUpdateQuestionnaire={handleUpdateLocalQuestionnaire}
        onAddProofOfAccess={handleAddProofOfAccess}
        onSaveQuestionnaire={handleSaveQuestionnaire}
        onConcludePractice={handleConcludePractice}
        onChangeIsNegative={handleChangeIsNegative}
        onChangeIsNegativeReason={handleChangeNegativeReason}
        onReset={handleReset}
        onAddConservativeAgreement={handleAddConservativeAgreement}
        onRemoveConservativeAgreement={handleRemoveConservativeAgreement}
        onModifyQuestionnaire={handleModifyQuestionnaire}
        onRejectPractice={handleRejectPractice}
        onResetLoadADZ={handleResetLoadADZ}
      />

      {practice?.status === "RE" && (
        <Space direction="vertical" style={{ width: "100%" }}>
          <Alert
            message={t("rejected-practice-info-message", {
              rejectionReason: t(
                `rejection-reason-description-${practice?.rejectionReasonCode}`
              ),
              rejectionNote: practice?.rejectionReasonNote,
            })}
            type="info"
          />
        </Space>
      )}

      {practice?.status === "RE" && practice?.isViewed && (
        <Space direction="vertical" style={{ width: "100%" }}>
          <Alert
            message={t("rejected-practice-appraiser-acknowledgment", {
              viewDate: practice?.isViewedDate,
            })}
            type="info"
          />
        </Space>
      )}

      {isLocalhost && (
        <div>
          <select
            value={desiredOutput}
            onChange={(e) => setDesiredOutput(parseInt(e.target.value))}
          >
            <option value={1}>OK</option>
            <option value={2}>errors</option>
            <option value={3}>blocking warnigs</option>
            <option value={4}>easy warning</option>
            <option value={5}>errors & warnings</option>
            <option value={6}>message</option>
            <option value={7}>no button</option>
          </select>

          <select
            value={desiredClient}
            onChange={(e) => setDesiredClient(parseInt(e.target.value))}
          >
            <option value={1}>Prima</option>
            <option value={2}>Altro</option>
          </select>
          <select
            value={desiredSaveQuestionnaireResult}
            onChange={(e) =>
              setDesiredSaveQuestionnaireResult(parseInt(e.target.value))
            }
          >
            <option value={1}>Ok</option>
            <option value={2}>error</option>
          </select>

          <select
            value={desiderdUpdatedStatus}
            onChange={(e) => setDesiderdUpdatedStatus(e.target.value)}
          >
            <option value={"CO"}>conclusa</option>
            <option value={"AC"}>timer</option>
          </select>
          <button onClick={handleReset}>reset</button>
        </div>
      )}
    </>
  );
};

export default ConcludePractice;
