import { useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Alert, Collapse, Space } from "antd";
import { FormRow } from "../../../../../style/form";
import {
  DatePickerStyled,
  InputNumberStyled,
  InputTextStyled,
  SelectStyled,
  SwitchStyled,
} from "../../../../../style/Input";

import { RowSpacer } from "../../../../../style/containers";
import SelectSignatureType from "../../../../Inputs/SelectSignatureType";
import {
  Address,
  AppraisalQuestionnaire,
  AppraisalSubject,
  ReadOnlyPractice,
  useLazyGetQuestionnaireQuery,
} from "../../../../../redux/apiSpecifications/apiCrud";
import SelectPracticeType from "../../../../Inputs/SelectPracticeType";
import SelectPracticeStatus from "../../../../Inputs/SelectPracticeStatus";
import {
  AppraisalBranchCodeTypes,
  AppraisalStatusTypes,
  AppraisalTypeTypes,
} from "../../../../../config/const";
import { SignatureTypes } from "../../../../../types/claim.types";
import AppraisalsList from "../../../../Widgets/Appraisals/AppraisalsList";
import {
  checkEmailFormat,
  checkTelephone,
} from "../../../../../validation/common";
import { ValidationStatuses } from "../../../../../types/common.types";

import { mainTabs } from "../../../../../config/testIds";
import ApprailsaLocationAndSubjects from "./ApprailsaLocationAndSubjects/AppraisalLocationAndSubjects";
import SelectClaimType from "../../../../Inputs/SelectClaimType";
import PanelHeaderWithButtons, {
  PanelStyled,
} from "../../Commons/PanelHeaderWithButtons";
import { ContentWrapper, FormRowTitle } from "../../Commons/Wrappers";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import {
  formalValidationFiscalCode,
  validateVatNumber,
} from "../../../../../validation/subjectValidation";
import AppraisalDetailsRates from "./AppraisalDetailsRates";
import styled from "styled-components";
import AppraisalDetailsFee from "./AppraisalDetailsFee";
import { Form } from "react-router-dom";
import { useAuthorization } from "../../../../../hooks/useAuthorization";
import Questionnaire from "./Questionnaire";
const testIds = mainTabs.tabs.appraisalDetails;

const AppraisalDetailsTabDetailsWrapper = styled(ContentWrapper)`
  input[required]::placeholder,
  .required input::placeholder {
    color: coral;
    background-color: lightyellow;
  }

  overflow: auto;
  max-height: calc(100vh - 16em);
  padding-right: 2em;

  .empty-field {
    flex: 1;
  }

  .half-field {
    flex: 1;
    display: flex;
    flex-direction: row;
    gap: 1em;
  }

  .urgent-row {
    flex: 1;
    display: flex;
    flex-direction: row;
    gap: 1em;
    align-items: center;
    justify-content: flex-start;

    .is-urgent-wrapper {
      width: 10em;
    }

    .urgent-reason-wrapper {
      flex: 1;
    }

    .select-urgency-reason {
      width: 100%;
    }
  }
`;

const isNotEmptyOrNull = (str: string | undefined | null) =>
  !!str && str.length > 0;

interface IAppraisalDetailsTabDetailsProps {
  practice: ReadOnlyPractice | undefined;
  onChange: (value: any, field: string) => void;
  onSave: () => Promise<boolean>;
  defaultActiveKey?: string[];
  isActive?: boolean;
  disableButton: (value: boolean) => void;
  onValidateFields: (errors: string[]) => void;
}

const AppraisalDetailsTabDetails = (
  props: IAppraisalDetailsTabDetailsProps
) => {
  const { t } = useTranslation();

  const {
    practice,
    onChange,
    onSave,
    disableButton,
    defaultActiveKey = ["practice", "questionnaire"],
    isActive,
    onValidateFields,
  } = props;

  const [emailValidation, setEmailValidation] = useState<{
    status: ValidationStatuses;
    help: string;
  }>({ status: "", help: "" });

  const [questionnaire, setQuestionnaire] =
    useState<AppraisalQuestionnaire | null>(null);

  const { getAuthorization } = useAuthorization();

  const { activeRole = "" } = useSelector((state: RootState) => state.user);

  const [loadQuestionnaire] = useLazyGetQuestionnaireQuery();

  useEffect(() => {
    validateFieldsForSave();
  }, [practice]);

  // if it's concluded or in review then load questionnaire
  useEffect(() => {
    if (
      ["D1", "D2", "E1", "E4", "NF", "G1", "G2"].includes(activeRole) ===
        false ||
      practice?.id === undefined ||
      ["CO", "CI", "CR", "CF"].includes(practice?.status || "") === false ||
      practice.practiceTypeCode === "7"
    )
      return;

    (async () => {
      const { data, isSuccess } = await loadQuestionnaire({
        authorization: await getAuthorization(),
        id: practice?.id!,
      });

      if (isSuccess) {
        setQuestionnaire(data as AppraisalQuestionnaire);
      } else {
        // to do
      }
    })();
  }, [practice?.id, practice?.status, activeRole]);

  const handleOnChange = (value: any, field: string, isValid?: boolean) => {
    if (field === "appraiser") {
      if (value.email?.length > 0 && !checkEmailFormat(value.email)) {
        setEmailValidation({
          status: "error",
          help: t("appraisals-details-appraiser-invalid-email"),
        });
      } else setEmailValidation({ status: "success", help: "" });
    }
    onChange(value, field);
  };

  const validateSubject = (subject: AppraisalSubject) => {
    const errors = [];

    if (subject) {
      const subjectType = (subject.subjectType ?? "").trim();
      const name = (subject.name ?? "").trim();
      const lastname = (subject.lastname ?? "").trim();
      const businessName = (subject.businessName ?? "").trim();

      if (subjectType === "PF") {
        if (name === "") errors.push("name-mandatory-message");
        if (lastname === "") errors.push("lastname-mandatory-message");
      } else if (subjectType === "PG") {
        if (businessName === "") errors.push("business-name-mandatory-message");
      }

      const email = subject.email ?? "";
      const pec = subject.pec ?? "";
      const phone = subject.phone ?? "";

      const isEmailEmpty = email === "";
      const isPecEmpty = pec === "";
      const isPhoneEmpty = phone === "";

      if (!isEmailEmpty && !checkEmailFormat(email)) {
        errors.push("email-not-valid-message");
      }

      if (!isPecEmpty && !checkEmailFormat(pec)) {
        errors.push("pec-not-valid-message");
      }

      if (!isPhoneEmpty && !checkTelephone(phone)) {
        errors.push("phone-not-valid-message");
      }

      if (isEmailEmpty && isPecEmpty && isPhoneEmpty) {
        errors.push("email-or-pec-or-phone-mandatory-message");
      }

      const street = (subject.address?.street ?? "").trim();
      // const civic = (subject.address?.civic ?? "").trim();
      const postalCode = (subject.address?.postalCode ?? "").trim();
      const city = (subject.address?.city ?? "").trim();

      if (street === "") errors.push("street-mandatory-message");

      // if (civic === "") errors.push("civic-mandatory-message");

      if (postalCode === "") errors.push("postal-code-mandatory-message");

      if (city === "") errors.push("city-mandatory-message");
    }

    return errors;
  };

  const validateFieldsForSave = () => {
    if (practice === undefined) return;

    const newFormErrors: string[] = [];

    const damageTypeCode = (practice.damageTypeCode ?? "").trim();
    const practiceTypeCode = (practice.practiceTypeCode ?? "").trim();
    const compensationAdjusterEmail = (
      practice.compensationAdjusterEmail ?? ""
    ).trim();
    const firstDocumentationDate = (
      practice.firstDocumentationDate ?? ""
    ).trim();
    const occurrenceDate = (practice.claim?.occurrenceDate ?? "").trim();
    const expirationDateIvass = (practice?.expirationDateIvass ?? "").trim();

    const _guarantees = (practice?.policy?.guarantees || [])[0] || {};

    const _insuredProperties =
      (practice?.policy?.insuredProperties || [])[0] || {};

    const plateNumber = (_insuredProperties?.plateNumber ?? "").trim();
    const chassisNumber = (_insuredProperties?.chassisNumber ?? "").trim();

    if (damageTypeCode === "")
      newFormErrors.push("damage-type-code-mandatory-message");
    if (practiceTypeCode === "")
      newFormErrors.push("practice-type-code-mandatory-message");
    if (compensationAdjusterEmail === "")
      newFormErrors.push("compensation-adjuster-email-mandatory-message");
    if (firstDocumentationDate === "")
      newFormErrors.push("first-documentation-date-mandatory-message");
    if (occurrenceDate === "")
      newFormErrors.push("occurrence-date-mandatory-message");
    if (expirationDateIvass === "")
      newFormErrors.push("expiration-date-ivass-mandatory-message");

    if ((_guarantees.overdraftAmount ?? null) === null)
      newFormErrors.push("overdraft-amount-mandatory-message");
    if ((_guarantees.maximumAmount ?? null) === null)
      newFormErrors.push("maximum-amount-mandatory-message");
    if ((_guarantees.excessAmount ?? null) === null)
      newFormErrors.push("excess-amount-mandatory-message");

    if (plateNumber === "")
      newFormErrors.push("plate-number-mandatory-message");
    if (chassisNumber === "")
      newFormErrors.push("chassis-number-mandatory-message");

    const ownerValidation = validateSubject(practice?.owner!);

    ownerValidation.forEach((error) => {
      newFormErrors.push(`owner-${error}`);
    });

    const communicationRecipientValidation = validateSubject(
      practice?.communicationRecipient!
    );

    communicationRecipientValidation.forEach((error) => {
      newFormErrors.push(`communication-recipient-${error}`);
    });

    const appraisalLocationValidation = validateSubject(
      practice?.appraisalLocation!
    );

    appraisalLocationValidation.forEach((error) => {
      newFormErrors.push(`appraisal-location-${error}`);
    });

    onValidateFields(newFormErrors);
  };

  const guarantees = practice?.policy?.guarantees || [];
  const { overdraftAmount, excessAmount, maximumAmount } = guarantees[0] || {};

  const handleOnChangeGuaranteeAmount = (value: any, field: string) => {
    const updatedGuarantees = [...guarantees];

    if (updatedGuarantees.length === 0) {
      updatedGuarantees.push({});
    }

    updatedGuarantees[0] = { ...updatedGuarantees[0], [field]: value };

    const updatedPolicy = {
      ...practice?.policy,
      guarantees: updatedGuarantees,
    };
    onChange(updatedPolicy, "policy");
  };

  const insuredProperties = practice?.policy?.insuredProperties || [];
  const { plateNumber, vehicleTypeCode, chassisNumber, modelName, brand } =
    insuredProperties[0] || {};
  //const vehicleModel = `${brand} ${modelName}`;
  const vehicleModel = modelName;

  const handleOnChangeInsuredProperty = (value: any, field: string) => {
    const updatedinsuredProperties = [...insuredProperties];

    if (updatedinsuredProperties.length === 0) {
      updatedinsuredProperties.push({});
    }

    updatedinsuredProperties[0] = {
      ...updatedinsuredProperties[0],
      [field]: value,
    };

    const updatedPolicy = {
      ...practice?.policy,
      insuredProperties: updatedinsuredProperties,
    };
    onChange(updatedPolicy, "policy");
  };

  const showPanelExpertizer = (role: string) => {
    return ["F1"].includes(role);
  };

  const showRates =
    ["D2", "E1", "NF", "G1", "G2"].includes(activeRole) &&
    practice?.appraiser &&
    practice?.appraiser.isInternal !== true;

  const showFee = (status: string | undefined, activeRole: string) => {
    return (
      ["G1", "G2"].includes(activeRole!) &&
      ["CO", "CI", "CR", "CF"].includes(status || "")
    );
  };

  const registeredLetterError = useMemo(() => {
    if (
      practice?.registeredLetterErrors &&
      practice.registeredLetterErrors.length > 0
    ) {
      return practice.registeredLetterErrors[0].fileContent;
    } else return null;
  }, [practice?.registeredLetterErrors]);

  const isInvoiced = practice?.status === "CF";

  return (
    <AppraisalDetailsTabDetailsWrapper data-testid={testIds.container}>
      <Collapse defaultActiveKey={defaultActiveKey}>
        {questionnaire && (
          <PanelStyled
            key={"questionnaire"}
            header={
              <PanelHeaderWithButtons
                text={t(
                  "appraisals-details-tab-details-collapsable-questionnaire"
                )}
              />
            }
          >
            <Questionnaire questionnaire={questionnaire} isPracticeInvoiced={isInvoiced} />

            {/* <pre>{JSON.stringify(questionnaire, null, 2)}</pre> */}
          </PanelStyled>
        )}

        <PanelStyled
          key={"practice"}
          header={
            <PanelHeaderWithButtons
              text={t("appraisals-details-tab-details-collapsable-practice")}
              showBlockedIcon={practice?.isBlocked}
            />
          }
        >
          <FormRow>
            {/* STATO PRATICA */}
            {activeRole !== "F1" && (
              <SelectPracticeStatus
                disabled={true}
                onChange={(value) => {}}
                value={practice?.status as AppraisalStatusTypes}
                dataTestId={testIds.collapsables.details.status}
              />
            )}

            <RowSpacer />

            {/* ID INCARICO DEKRA*/}
            <InputTextStyled
              disabled={true}
              label={t(
                "appraisals-dashboard-communications-list-header-id-assignment"
              )}
              value={practice?.practiceNumber}
              dataTestId={testIds.collapsables.details.practiceNumber}
              readOnly={true}
            />

            <RowSpacer />

            {/* ID INCARICO CLIENTE*/}
            <InputTextStyled
              disabled={true}
              label={t("search-appraisals-id-practice-company-label")}
              value={practice?.practiceNumberCompany}
              dataTestId={testIds.collapsables.details.practiceNumber}
              readOnly={true}
            />
          </FormRow>
          <FormRow>
            <div className="urgent-row">
              <div className="is-urgent-wrapper">
                {/* URGENTE */}
                <SwitchStyled
                  label={t("select-practice-urgent-label")}
                  onChange={(value) => handleOnChange(value, "isUrgent")}
                  unCheckedChildren={t("switch-no")}
                  checkedChildren={t("switch-yes")}
                  checked={practice?.isUrgent}
                  disabled={
                    ["E1", "E2", "E3", "E4", "G1", "G2"].includes(activeRole) ||
                    practice?.isBlocked ||
                    isInvoiced
                  }
                  readOnly={practice?.isBlocked}
                  dataTestId={testIds.collapsables.details.isUrgent}
                />
              </div>
              <div className="urgent-reason-wrapper">
                {/* MOTIVAZIONE */}
                <SelectStyled
                  classNameInput="select-urgency-reason"
                  label={t("select-practice-urgency-reason-label")}
                  onChange={(value) => handleOnChange(value, "urgencyReason")}
                  value={practice?.urgencyReason}
                  disabled={
                    ["E1", "E2", "E3", "E4", "G1", "G2"].includes(activeRole) ||
                    practice?.isBlocked ||
                    isInvoiced
                  }
                  options={[
                    { value: "", label: "" },
                    {
                      value: "DC",
                      label: t("select-practice-urgency-reason-DC"),
                    },
                    {
                      value: "TU",
                      label: t("select-practice-urgency-reason-TU"),
                    },
                    {
                      value: "RP",
                      label: t("select-practice-urgency-reason-RP"),
                    },
                  ]}
                />
              </div>
            </div>

            <RowSpacer />
            <div className="empty-field"></div>
          </FormRow>
          <FormRow>
            {/* CODICE TIPOLOGIA DANNO */}
            <InputTextStyled
              label={t(
                "appraisals-details-tab-details-appraisals-damage-type-label"
              )}
              placeholder={t(
                "appraisals-details-tab-details-appraisals-damage-type-placeholder"
              )}
              onChange={(value) => handleOnChange(value, "damageTypeCode")}
              value={practice?.damageTypeCode}
              disabled={true}
              readOnly={practice?.isBlocked}
              required={true}
              dataTestId={testIds.collapsables.details.damageTypeCode}
            />
            <RowSpacer />
            {/* TIPOLOGIA PERIZIA */}
            <SelectPracticeType
              value={practice?.practiceTypeCode as AppraisalTypeTypes}
              onChange={(value) => handleOnChange(value, "practiceTypeCode")}
              disabled={true}
              dataTestId={testIds.collapsables.details.practiceTypeCode}
            />
          </FormRow>
          <FormRow>
            <div
              style={{
                flex: 1,
                display: "flex",
              }}
            >
              {/* DATA SCADENZA SLA */}
              <DatePickerStyled
                label={t(
                  "appraisals-details-tab-details-appraisals-sla-expiring-date-label"
                )}
                placeholder={t(
                  "appraisals-details-tab-details-appraisals-customer-expiring-date-placeholder"
                )}
                required={false}
                onChange={(value) => handleOnChange(value, "expirationDateSLA")}
                value={practice?.expirationDateSLA}
                disabled={
                  ["E1", "E2", "E3", "E4", "F1", "G1", "G2"].includes(
                    activeRole
                  ) ||
                  practice?.isBlocked ||
                  isInvoiced
                }
              />
              <RowSpacer />
              {/* DATA A TRATTENERE
              <DatePickerStyled
                label={t(
                  "appraisals-details-tab-details-appraisals-hold-request-date-label"
                )}
                placeholder={t(
                  "appraisals-details-tab-details-appraisals-hold-request-date-placeholder"
                )}
                required={practice?.isHoldRequest}
                onChange={(value) => handleOnChange(value, "holdRequestDate")}
                value={practice?.holdRequestDate}
                disabled={activeRole === "E3" || practice?.isBlocked}
              />
              <RowSpacer /> */}

              {/* DATA SCADENZA CLIENTE */}
              {activeRole === "E3" && <div className="empty-field"></div>}
              {activeRole !== "E3" && (
                <>
                  <DatePickerStyled
                    label={t(
                      "appraisals-details-tab-details-appraisals-customer-expiring-date-label"
                    )}
                    placeholder={t(
                      "appraisals-details-tab-details-appraisals-customer-expiring-date-placeholder"
                    )}
                    required={false}
                    onChange={(value) =>
                      handleOnChange(value, "expirationDateClient")
                    }
                    value={practice?.expirationDateClient}
                    disabled={
                      ["E1", "E2", "E3", "E4", "F1", "G1", "G2"].includes(
                        activeRole
                      ) ||
                      practice?.isBlocked ||
                      isInvoiced
                    }
                  />
                  <RowSpacer />
                </>
              )}
              {/* DATA UDIENZA DEPOSITO (solo x CTP) */}
              {practice?.practiceTypeCode === "8" ? (
                <DatePickerStyled
                  label={t(
                    "appraisals-details-tab-details-appraisals-hearing-date-label"
                  )}
                  placeholder={t(
                    "appraisals-details-tab-details-appraisals-hearing-date-placeholder"
                  )}
                  required={false}
                  onChange={(value) => handleOnChange(value, "hearingDate")}
                  value={practice?.hearingDate}
                  disabled={
                    activeRole === "E3" || practice?.isBlocked || isInvoiced
                  }
                />
              ) : (
                <div className="empty-field"></div>
              )}
            </div>
            <RowSpacer />
            <div
              style={{
                flex: 1,
                display: "flex",
              }}
            >
              {/* EMAIL LIQUIDATORE */}
              <InputTextStyled
                label={t(
                  "appraisals-details-tab-details-appraisals-appraiser-email-label"
                )}
                placeholder={t(
                  "appraisals-details-tab-details-appraisals-appraiser-email-placeholder"
                )}
                required={true}
                onChange={(email) =>
                  handleOnChange(email, "compensationAdjusterEmail")
                }
                value={practice?.compensationAdjusterEmail}
                validateStatus={emailValidation.status}
                help={emailValidation.help}
                disabled={
                  ["E1", "E2", "E3", "E4", "D1", "G1", "G2"].includes(
                    activeRole
                  ) ||
                  practice?.isBlocked ||
                  isInvoiced
                }
                readOnly={practice?.isBlocked}
                dataTestId={testIds.collapsables.details.emailAppraiser}
              />
            </div>
          </FormRow>

          <FormRow>
            {/* DOCUMENTALE */}
            <SwitchStyled
              label={t(
                "appraisals-details-tab-details-appraisals-is-documental-label"
              )}
              onChange={(value) => handleOnChange(value, "isDocumental")}
              unCheckedChildren={t("switch-no")}
              checkedChildren={t("switch-yes")}
              checked={practice?.isDocumental}
              disabled={true}
              readOnly={practice?.isBlocked || isInvoiced}
              dataTestId={testIds.collapsables.details.isDocumental}
            />
            <RowSpacer />

            {/* RIPARA CLIENTE */}
            <SwitchStyled
              label={t(
                "appraisals-details-tab-details-appraisals-customer-repair-label"
              )}
              onChange={(value) => handleOnChange(value, "isClientRepair")}
              unCheckedChildren={t("switch-no")}
              checkedChildren={t("switch-yes")}
              checked={practice?.isClientRepair}
              disabled={true}
              readOnly={practice?.isBlocked || isInvoiced}
              dataTestId={testIds.collapsables.details.isCustomRepair}
            />
            <RowSpacer />
            {/* DA CONCORDARE */}
            <SwitchStyled
              label={t(
                "appraisals-details-tab-details-appraisals-agreed-label"
              )}
              onChange={(value) => handleOnChange(value, "isAgreedAmount")}
              unCheckedChildren={t("switch-no")}
              checkedChildren={t("switch-yes")}
              checked={practice?.isAgreedAmount}
              disabled={true}
              readOnly={practice?.isBlocked || isInvoiced}
              dataTestId={testIds.collapsables.details.isAgreed}
            />
            <RowSpacer />
            {/* CESSIONE DEL CREDITO */}
            <SwitchStyled
              label={t(
                "appraisals-details-tab-details-appraisals-credit-assignment-label"
              )}
              onChange={(value) => handleOnChange(value, "isCreditTransfert")}
              unCheckedChildren={t("switch-no")}
              checkedChildren={t("switch-yes")}
              checked={practice?.isCreditTransfert}
              disabled={true}
              readOnly={practice?.isBlocked || isInvoiced}
              dataTestId={testIds.collapsables.details.isCreditAssignment}
            />
            <RowSpacer />
            {/* CONTROPARTE */}
            <SwitchStyled
              label={t(
                "appraisals-details-tab-details-appraisals-isCounterpart-label"
              )}
              onChange={(value) => handleOnChange(value, "isCounterpart")}
              unCheckedChildren={t("switch-no")}
              checkedChildren={t("switch-yes")}
              checked={practice?.isCounterpart}
              disabled={true}
              readOnly={practice?.isBlocked || isInvoiced}
              dataTestId={testIds.collapsables.details.isCounterpart}
            />
            <RowSpacer />
            {/* SECONDO INCARICO */}
            <SwitchStyled
              label={t(
                "appraisals-details-tab-details-appraisals-second-assignament-label"
              )}
              onChange={(value) => handleOnChange(value, "isSecondPractice")}
              unCheckedChildren={t("switch-no")}
              checkedChildren={t("switch-yes")}
              checked={practice?.isSecondPractice}
              disabled={
                ["E1", "E2", "E3", "E4", "F1", "G1", "G2"].includes(
                  activeRole
                ) ||
                practice?.isBlocked ||
                isInvoiced
              }
              readOnly={practice?.isBlocked}
              dataTestId={testIds.collapsables.details.secondAssignment}
            />
          </FormRow>

          <FormRow>
            {/* TRASFORMATA IN DOCUMENTALE */}
            <SwitchStyled
              label={t(
                "appraisals-details-tab-details-appraisals-is-isTransformed-documental-label"
              )}
              onChange={(value) =>
                handleOnChange(value, "isTransformedDocumental")
              }
              unCheckedChildren={t("switch-no")}
              checkedChildren={t("switch-yes")}
              checked={practice?.isTransformedDocumental}
              disabled={
                ["E2", "E3", "E4", "F1", "G1", "G2"].includes(activeRole) ||
                practice?.isBlocked ||
                isInvoiced
              }
              readOnly={practice?.isBlocked}
            />
            <RowSpacer />
            {/* INTERVENTO LEGALE */}
            <SwitchStyled
              label={t("select-practice-lawyer-label")}
              onChange={(value) => handleOnChange(value, "isLawyer")}
              unCheckedChildren={t("switch-no")}
              checkedChildren={t("switch-yes")}
              checked={practice?.isLawyer}
              disabled={
                ["E1", "E2", "E3", "E4", "G1", "G2"].includes(activeRole) ||
                practice?.isBlocked ||
                isInvoiced
              }
              readOnly={practice?.isBlocked}
            />
            <RowSpacer />

            {/* RICHIESTA A TRATTENERE */}
            <SwitchStyled
              label={t(
                "appraisals-details-tab-details-appraisals-is-hold-request-label"
              )}
              onChange={(value) => handleOnChange(value, "isHoldRequest")}
              unCheckedChildren={t("switch-no")}
              checkedChildren={t("switch-yes")}
              checked={practice?.isHoldRequest}
              disabled={
                ["E1", "E2", "E3", "E4", "F1", "G1", "G2"].includes(
                  activeRole
                ) ||
                practice?.isBlocked ||
                isInvoiced
              }
              readOnly={practice?.isBlocked}
            />
            <RowSpacer />
            <div className="empty-field"></div>
            <RowSpacer />
            <div className="empty-field"></div>
            <RowSpacer />
            <div className="empty-field"></div>
          </FormRow>

          {(practice?.notes ?? "").trim() !== "" && (
            <FormRow>
              <div style={{ flex: 1 }}>
                <Alert
                  message={t(
                    "appraisals-details-tab-details-appraisals-customer-note",
                    {
                      notes: practice?.notes,
                    }
                  )}
                  type="info"
                />
              </div>
              <RowSpacer />
              <div style={{ flex: 1 }}></div>
            </FormRow>
          )}
        </PanelStyled>

        <PanelStyled
          key={"claim"}
          header={
            <PanelHeaderWithButtons
              text={t("appraisals-details-tab-details-collapsable-claim")}
            />
          }
          data-testid={testIds.collapsables.claim.header}
        >
          <FormRow>
            {/* CODICE SINISTRO */}
            <InputTextStyled
              label={t("appraisals-details-tab-details-claim-code-label")}
              disabled={true}
              onChange={(value) => {}}
              value={practice?.claim?.number}
              dataTestId={testIds.collapsables.claim.claimCode}
            />
            <RowSpacer />
            {/* DATA PRIMA DOCUMENTAZIONE */}
            <DatePickerStyled
              label={t(
                "appraisals-details-tab-details-appraisals-first-documentation-date-label"
              )}
              placeholder={t(
                "appraisals-details-tab-details-appraisals-first-documentation-date-placeholder"
              )}
              required={true}
              onChange={(value) =>
                handleOnChange(value, "firstDocumentationDate")
              }
              value={practice?.firstDocumentationDate}
              disabled={true}
            />
            <RowSpacer />
            <div style={{ display: "flex", flex: 1 }}>
              {/* TIPO SINISTRO */}
              <SelectClaimType
                value={
                  practice?.claim?.claimsBranchCode as AppraisalBranchCodeTypes
                }
                onChange={(claimsBranchCode) =>
                  handleOnChange(
                    {
                      ...practice?.claim,
                      claimsBranchCode,
                    },
                    "claim"
                  )
                }
                disabled={true}
                dataTestId={testIds.collapsables.claim.claimType}
              />
              <RowSpacer />
            </div>
          </FormRow>
          <FormRow>
            {/* DATA ACCADIMENTO */}
            <DatePickerStyled
              label={t(
                "appraisals-details-tab-details-appraisals-occurrence-date-label"
              )}
              required={true}
              placeholder={t(
                "appraisals-details-tab-details-appraisals-occurrence-date-placeholder"
              )}
              onChange={(value) =>
                handleOnChange(
                  {
                    ...practice?.claim,
                    occurrenceDate: value,
                  },
                  "claim"
                )
              }
              value={practice?.claim?.occurrenceDate}
              disabled={true}
            />
            <RowSpacer />
            {/* SCADENZA IVASS */}
            <DatePickerStyled
              label={t(
                "appraisals-details-tab-details-appraisals-ivass-expiring-date-label"
              )}
              placeholder={t(
                "appraisals-details-tab-details-appraisals-ivass-expiring-date-placeholder"
              )}
              required={true}
              onChange={(value) => handleOnChange(value, "expirationDateIvass")}
              value={practice?.expirationDateIvass}
              disabled={true}
            />
            <RowSpacer />
            {/* TIPO FIRMA */}
            <SelectSignatureType
              value={
                practice?.claim?.responsibility?.signatureType as SignatureTypes
              }
              onChange={(value) =>
                handleOnChange(
                  {
                    ...practice?.claim,
                    responsibility: {
                      ...practice?.claim?.responsibility,
                      signatureType: value,
                    },
                  },
                  "claim"
                )
              }
              disabled={true}
              dataTestId={testIds.collapsables.claim.signatureType}
            />
          </FormRow>
          <FormRow>
            {/* SCOPERTO */}
            <InputTextStyled
              type="number"
              label={t(
                "appraisals-details-tab-details-appraisals-overdraft-label"
              )}
              placeholder={t(
                "appraisals-details-tab-details-appraisals-overdraft-placeholder"
              )}
              onChange={(value) =>
                handleOnChangeGuaranteeAmount(value, "overdraftAmount")
              }
              required={true}
              value={overdraftAmount}
              disabled={true}
              dataTestId={testIds.collapsables.claim.overdraft}
            />
            <RowSpacer />
            {/* MASSIMALE */}
            <InputTextStyled
              type="number"
              label={t(
                "appraisals-details-tab-details-appraisals-ceiling-label"
              )}
              placeholder={t(
                "appraisals-details-tab-details-appraisals-ceiling-placeholder"
              )}
              onChange={(value) =>
                handleOnChangeGuaranteeAmount(value, "maximumAmount")
              }
              required={true}
              value={maximumAmount}
              disabled={true}
              dataTestId={testIds.collapsables.claim.ceiling}
            />
            <RowSpacer />
            {/* FRANCHIGIA */}
            <InputTextStyled
              type="number"
              label={t(
                "appraisals-details-tab-details-appraisals-deductible-label"
              )}
              placeholder={t(
                "appraisals-details-tab-details-appraisals-deductible-placeholder"
              )}
              required={true}
              onChange={(value) =>
                handleOnChangeGuaranteeAmount(value, "excessAmount")
              }
              value={excessAmount}
              disabled={true}
              dataTestId={testIds.collapsables.claim.deductible}
            />
          </FormRow>
        </PanelStyled>

        <PanelStyled
          key={"vehicle-owner"}
          header={
            <PanelHeaderWithButtons
              text={t(
                "appraisals-details-tab-details-collapsable-vehicle-owner"
              )}
            />
          }
        >
          <FormRow>
            <FormRowTitle>
              {t("appraisals-details-tab-details-vehicle-title")}
            </FormRowTitle>
          </FormRow>
          <FormRow>
            {/* TARGA */}
            <InputTextStyled
              label={t(
                "appraisals-details-tab-details-appraisals-vehicle-plate-label"
              )}
              placeholder={t(
                "appraisals-details-tab-details-appraisals-vehicle-plate-placeholder"
              )}
              onChange={(value) =>
                handleOnChangeInsuredProperty(
                  value.toUpperCase(),
                  "plateNumber"
                )
              }
              required={true}
              value={plateNumber}
              disabled={
                ["E2", "E3", "F1", "G1", "G2"].includes(activeRole) ||
                practice?.isBlocked ||
                isInvoiced
              }
              readOnly={practice?.isBlocked}
              dataTestId={testIds.collapsables.vehicle.plate}
            />
            <RowSpacer />
            {/* VIN */}
            <InputTextStyled
              label={t("appraisals-details-tab-details-appraisals-vin-label")}
              placeholder={t(
                "appraisals-details-tab-details-appraisals-vin-placeholder"
              )}
              onChange={(value) =>
                handleOnChangeInsuredProperty(value, "chassisNumber")
              }
              required={true}
              value={chassisNumber}
              disabled={
                ["E2", "E3", "F1", "G1", "G2"].includes(activeRole) ||
                practice?.isBlocked ||
                isInvoiced
              }
              readOnly={practice?.isBlocked}
              dataTestId={testIds.collapsables.vehicle.vin}
            />
            <RowSpacer />
            {/* MODELLO */}
            <InputTextStyled
              label={t("appraisals-details-tab-details-appraisals-model-label")}
              onChange={(value) =>
                handleOnChangeInsuredProperty(value, "modelName")
              }
              value={vehicleModel}
              disabled={
                ["E2", "E3", "F1", "G1", "G2"].includes(activeRole) ||
                practice?.isBlocked ||
                isInvoiced
              }
              readOnly={practice?.isBlocked}
              dataTestId={testIds.collapsables.vehicle.model}
            />
            <RowSpacer />
            {/* VALORE COMMERCIALE */}
            <InputNumberStyled
              label={t(
                "appraisals-details-tab-details-appraisals-commercial-value-label"
              )}
              onChange={(value) => handleOnChange(value, "commercialValue")}
              value={practice?.commercialValue}
              min={0}
              disabled={
                ["E2", "E3", "F1", "G1", "G2"].includes(activeRole) ||
                practice?.isBlocked ||
                isInvoiced
              }
              readOnly={practice?.isBlocked}
            />
          </FormRow>
          <br />
          {registeredLetterError && (
            <Space direction="vertical" style={{ width: "100%" }}>
              <Alert
                message={
                  <Trans
                    i18nKey="registered-letter-error-message-template"
                    values={{
                      error_message: registeredLetterError,
                    }}
                    components={{
                      1: <br />,
                      bold: <strong />,
                    }}
                  />
                }
                type="error"
              />

              <br />
            </Space>
          )}
          <FormRow>
            <ApprailsaLocationAndSubjects
              owner={practice?.owner}
              communicationRecipient={
                practice?.communicationRecipient || undefined
              }
              appraisalLocation={practice?.appraisalLocation || undefined}
              history={practice?.appraisalLocationHist || []}
              onChange={handleOnChange}
              roleActive={activeRole}
              practiceBlocked={practice?.isBlocked}
              isInvoiced={isInvoiced}
            />
          </FormRow>
        </PanelStyled>

        {showRates && (
          <PanelStyled
            key={"rates"}
            header={
              <PanelHeaderWithButtons
                text={t("appraisals-details-tab-details-collapsable-rates")}
              />
            }
          >
            <AppraisalDetailsRates practice={practice} />
          </PanelStyled>
        )}

        {showFee(practice?.status, activeRole!) && (
          <PanelStyled
            header={t("appraisals-details-tab-activities-collapsable-fee")}
            key="fee"
          >
            <AppraisalDetailsFee
              practice={practice}
              // practiceFee={practice?.fee!}
              // onSend={handleFeeSend}
            />
          </PanelStyled>
        )}

        {showPanelExpertizer(activeRole as string) && (
          <PanelStyled
            key={"expertizer"}
            header={
              <PanelHeaderWithButtons
                text={t(
                  "appraisals-details-tab-details-collapsable-expertizer"
                )}
              />
            }
          >
            <FormRow>
              {/* DATA APPUNTAMENTO */}
              <DatePickerStyled
                label={t(
                  "appraisals-details-tab-details-appraisals-date-appoinment-label"
                )}
                onChange={() => {}}
                value={practice?.appointment?.appointmentDate}
                readOnly={true}
                disabled={
                  activeRole === "E3" || practice?.isBlocked || isInvoiced
                }
                dataTestId={testIds.collapsables.details.dateAppoinment}
              />
              <RowSpacer />
              {/* NOMINATIVO STUDIO/PERITO */}
              <InputTextStyled
                label={t(
                  "appraisals-details-tab-details-appraisals-expertizer-label-label"
                )}
                onChange={() => {}}
                value={
                  practice?.appraiser?.subject?.businessName
                    ? practice?.appraiser?.subject?.businessName
                    : practice?.appraiser?.subject?.name +
                      " " +
                      practice?.appraiser?.subject?.lastname
                }
                readOnly={true}
                disabled={practice?.isBlocked || isInvoiced}
                dataTestId={testIds.collapsables.details.appraisalsExpertizer}
              />
              <RowSpacer />
              {/* DATA RESTITUZIONE */}
              <DatePickerStyled
                label={t(
                  "appraisals-details-tab-details-appraisals-date-return-label"
                )}
                onChange={() => {}}
                value={practice?.returnDateTime}
                readOnly={true}
                disabled={
                  activeRole === "E3" || practice?.isBlocked || isInvoiced
                }
                dataTestId={testIds.collapsables.details.dateReturn}
              />
              <RowSpacer />
            </FormRow>
          </PanelStyled>
        )}

        <PanelStyled
          key={"related-practices"}
          header={
            <PanelHeaderWithButtons
              text={t(
                "appraisals-details-tab-details-collapsable-related-practices"
              )}
            />
          }
        >
          <AppraisalsList
            items={practice?.relatedPractices}
            removeWrapper={true}
            isActive={isActive}
            onRefresh={() => {}}
          />
        </PanelStyled>
      </Collapse>
    </AppraisalDetailsTabDetailsWrapper>
  );
};

export default AppraisalDetailsTabDetails;
