import styled from "styled-components";
import { WidgetWrapper } from "../../../../style/containers";
import { useTranslation } from "react-i18next";
import { useMemo, useState } from "react";
import { SelectStyled } from "../../../../style/Input";
import { SelectPair } from "../../../../types/common.types";
import { useExpertizeScoreContext } from "../ExpertizeScoreContext";
import AppraiserScoreTenantConfigForm from "./AppraiserScoreTenantConfigForm";
import AppraiserScoreConfigForm from "./AppraiserScoreConfigForm";
import { Prompt } from "../Prompt/Prompt";
import { Popconfirm } from "antd";
import { ButtonConfirm } from "../../../Layout/Buttons";
import { updateObject } from "../../../../utils/updateObject";

export const ConfigureThresholdWeightWrapper = styled(WidgetWrapper)`
    display: block;
    align-items: flex-start;
    max-width: 100%;
    overflow: auto;
    padding: 2em;

    .buttons-wrapper {
        width: 100%;
        margin-top: 2em;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1em;
    }
`;

const ConfigurationPanelWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 1em;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    .inner-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .header {
            text-align: center;
            font-size: 1em;
            text-transform: uppercase;
            letter-spacing: 2px;
            margin-bottom: 2em;
        }

        td {
            .square-text {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding-right: 1em;
            }
        }
    }

    .input-percentage {
        input::-webkit-inner-spin-button,
        input::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        input {
            -moz-appearance: textfield;
            padding-right: 1.8em;
        }
    }
`;

const AppraiserScoreTenantWrapper = styled(Wrapper)`
    grid-area: variables;

    .label {
        padding-right: 1em;
    }

    .value {
        width: 6em;
        height: 2em;
        border-radius: 5px;
        margin-right: 1em;
        margin-left: 6em;
    }

    .header {
        text-align: center;
        font-size: 1em;
        text-transform: uppercase;
        letter-spacing: 2px;
        margin-bottom: 2em;
    }

    div.tenant-configs-box {
        display: flex;
        flex-direction: column;
        gap: 3em;
        width: 100%;

        div.tenant-config-box {
            div.title {
                text-align: center;
                background-color: rgb(245, 245, 245);
            }
        }
    }
`;

const ConfigureThresholdWeight = () => {
    const { t } = useTranslation();

    const {
        appraiserScoreClusterList,
        selectedClusterId,
        selectedClusterConfig,
        isFormChanged,
        onChangeSelectedCluster,
        onChangeSelectedClusterConfig,
        onSubmitConfigureThresholdWeightForm,
    } = useExpertizeScoreContext();

    const [showPrompt, setShowPrompt] = useState<boolean>(false);
    const [clusterToSelect, setClusterToSelect] = useState<number | undefined>(
        undefined
    );

    const clusterOptions = useMemo(() => {
        const clusterOptions = appraiserScoreClusterList?.map(
            (cluster) =>
                ({
                    value: cluster.id,
                    label: cluster.clusterName,
                } as SelectPair)
        );

        return clusterOptions;
    }, [appraiserScoreClusterList]);

    const handleOnChangeScoreTenantConfig = (
        tenantId: number,
        field: string,
        value: string
    ) => {
        const newAppraiserScoreTenantConfigs =
            selectedClusterConfig?.appraiserScoreTenantConfigs?.map(
                (scoreTenant) => {
                    if (scoreTenant.tenantId !== tenantId) return scoreTenant;

                    let updatedData = updateObject(scoreTenant, value, field);

                    return {
                        ...updatedData,
                    };
                }
            );

        onChangeSelectedClusterConfig(
            "appraiserScoreTenantConfigs",
            newAppraiserScoreTenantConfigs
        );
    };

    const handleOnChangeCluster = (clusterId: number) => {
        if (isFormChanged) {
            setClusterToSelect(clusterId);

            setShowPrompt(true);
        } else onChangeSelectedCluster(clusterId);
    };

    const handleOnCancelPrompt = () => {
        setShowPrompt(false);
    };

    const handleOnConfirmPrompt = () => {
        setShowPrompt(false);

        // onCancelFormChanges();

        onChangeSelectedCluster(clusterToSelect!);
    };

    const handleOnSubmitConfigureThresholdWeightForm = () => {
        onSubmitConfigureThresholdWeightForm();
    };

    return (
        <ConfigureThresholdWeightWrapper>
            <SelectStyled
                label={"Cluster"}
                options={clusterOptions!}
                onChange={handleOnChangeCluster}
                value={selectedClusterId}
            />

            <ConfigurationPanelWrapper>
                <AppraiserScoreConfigForm />

                <AppraiserScoreTenantWrapper>
                    <div className="header">
                        {t(
                            "expert-score-configure-threshold-average-expert-label"
                        )}
                    </div>

                    <div className="tenant-configs-box">
                        {selectedClusterConfig?.appraiserScoreTenantConfigs?.map(
                            (appraiserScoreTenantConfig) => {
                                return (
                                    <AppraiserScoreTenantConfigForm
                                        key={
                                            appraiserScoreTenantConfig.tenantId
                                        }
                                        appraiserScoreTenantConfig={
                                            appraiserScoreTenantConfig
                                        }
                                        onChange={(field, value) =>
                                            handleOnChangeScoreTenantConfig(
                                                appraiserScoreTenantConfig.tenantId!,
                                                field,
                                                value
                                            )
                                        }
                                    />
                                );
                            }
                        )}
                    </div>
                </AppraiserScoreTenantWrapper>
            </ConfigurationPanelWrapper>

            <div className="buttons-wrapper">
                <Popconfirm
                    placement="top"
                    icon={null}
                    title={t("configuration-panel-button-save-confirm")!}
                    onConfirm={handleOnSubmitConfigureThresholdWeightForm}
                    okText={t("switch-yes")!}
                    cancelText={t("switch-no")!}
                >
                    <ButtonConfirm disabled={!isFormChanged} onClick={() => {}}>
                        {t("button-save")!}
                    </ButtonConfirm>
                </Popconfirm>
            </div>

            <Prompt
                open={showPrompt}
                onCancel={handleOnCancelPrompt}
                onConfirm={handleOnConfirmPrompt}
            />

            {/* <pre>{JSON.stringify(selectedClusterConfig, null, 2)}</pre> */}
        </ConfigureThresholdWeightWrapper>
    );
};

export default ConfigureThresholdWeight;
