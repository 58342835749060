import { Modal, Tabs } from "antd";
import {
  MainTabContentWrapper,
  RefreshInTabWrapper,
} from "../../../../style/DashbordWidgetWrappers";
import ResumeInfo, { ResumeInfoItem } from "../Commons/ResumeInfo";
import { TabsWrapper } from "../Commons/Wrappers";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import AppraiserDetailsSubject from "./Subject/AppraiserDetailsSubject";
import AppraiserDetailsManagement from "./Management/AppraiserDetailsManagement";
import { IconLoading } from "../../../../config/icons";
import { mainTabs } from "../../../../config/testIds";
import moment from "moment";
import AppraiserDetailsContacts from "./Contacts/AppraiserDetailsContacts";
import AppraiserDetailsAddresses from "./Addresses/AppraiserDetailsAddresses";
import AppraiserDetailsRates from "./Rates/AppraiserDetailsRates";
import { DateFormat } from "../../../../config/const";
import AppraiserAgenda from "./Agenda/AppraiserAgenda";
import AppraiserAgendaNF from "./Agenda/AppraiserAgendaNF";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { AppraiserUser } from "../../../../redux/apiSpecifications/apiFesf";
import {
  clearPendingChanges,
  setAppraiserActiveTab,
  setAppraiserDefaultStatusValues,
  setAppraiserError,
  setAppraiserMainData,
  setAppraiserStatus,
} from "../../../../redux/features/appraisersSlice";
import styled from "styled-components";
import { ButtonCancel, ButtonConfirm } from "../../../Layout/Buttons";

const testIds = mainTabs.tabs.supplierNetwork.appraiserDetails;

const PengindChangesModal = styled.div`
  padding: 2em;
  font-size: 1.2em;
`;

export type LoadingStatus = "idle" | "loading" | "saving";

interface IAppraiserDetailsPresentationalProps {
  idAppraiser: number | undefined;
  onSave: () => Promise<boolean | void>;
}

const AppraiserDetailsPresentational = (
  props: IAppraiserDetailsPresentationalProps
) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const activeRole = useSelector((state: RootState) => state.user.activeRole);
  const appraiserData = useSelector((state: RootState) =>
    state.appraisers.appraisers.find((a) => a.id === props.idAppraiser)
  );

  const {
    mainData: appraiser,
    errorMessage: error,
    status: loadingStatus,
    defaultSettings,
    hasPendingChanges,
    pendingChanges,
    validationErrors,
    activeTab,
  } = appraiserData || {};

  const [forceReload, setForceReload] = useState<number>(0);

  const [resumeInfoItems, setResumeInfoItems] = useState<ResumeInfoItem[]>([]);

  const [defaultAgendaDate, setDefaultAgendaDate] = useState<
    string | undefined
  >(defaultSettings?.agendaDate);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [tabToSwitch, setTabToSwitch] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (defaultSettings?.tab && appraiser?.idAppraiser)
      dispatch(
        setAppraiserActiveTab({
          id: appraiser!.idAppraiser!,
          tab: defaultSettings?.tab,
        })
      );
    //setActiveKey(defaultSettings?.tab);
    if (defaultSettings?.agendaDate)
      setDefaultAgendaDate(defaultSettings?.agendaDate);
  }, [defaultSettings?.tab, defaultSettings?.agendaDate]);

  useEffect(() => {
    let items: ResumeInfoItem[] = [];

    if (appraiser?.subjectType === "PF")
      items = [
        {
          label: t(
            "supplier-network-appraiser-details-resume-info-nominative-label"
          ),
          value: `${appraiser.name} ${appraiser.lastname}`,
        },
        {
          label: t(
            "supplier-network-appraiser-details-resume-info-fiscal-code-label"
          ),
          value: appraiser.fiscalCode || "",
        },
      ];

    if (appraiser?.subjectType === "PG")
      items = [
        {
          label: t(
            "supplier-network-appraiser-details-resume-info-nominative-label"
          ),
          value: appraiser.businessName || "",
        },
        {
          label: t(
            "supplier-network-appraiser-details-resume-info-p-iva-label"
          ),
          value: appraiser.pIva || "",
        },
      ];

    setResumeInfoItems(items);
  }, [appraiser]);

  const handleShowAgenda = (date: string, status: string, province: string) => {
    const newStatus =
      status === "B" ? "add-blocked" : status === "S" ? "add-suspension" : "";

    dispatch(
      setAppraiserDefaultStatusValues({
        id: appraiser!.id!,
        defaultProvinces: [province.toLowerCase()],
        selectedDayDate: date,
        status: newStatus,
        statusCode: "",
      })
    );

    handleChangeTab("agenda");
  };

  const handleChangeAppraiser = (newAppraiser: AppraiserUser) => {
    dispatch(setAppraiserMainData({ appraiser: newAppraiser, isUpdate: true }));
  };

  const items = [
    {
      key: "subject",
      label: t("supplier-network-appraiser-details-tab-subject-label"),
      children: (
        <AppraiserDetailsSubject
          appraiser={appraiser}
          onChange={handleChangeAppraiser}
        />
      ),
    },
    {
      key: "contacts",
      label: t("supplier-network-appraiser-details-tab-contacts-label"),
      children: (
        <AppraiserDetailsContacts
          appraiser={appraiser}
          onChange={handleChangeAppraiser}
        />
      ),
    },
    {
      key: "addresses",
      label: t("supplier-network-appraiser-details-tab-addresses-label"),
      children: (
        <AppraiserDetailsAddresses
          appraiser={appraiser}
          onChange={handleChangeAppraiser}
        />
      ),
    },
    {
      key: "management",
      label: t("supplier-network-appraiser-details-tab-management-label"),
      children: (
        <AppraiserDetailsManagement
          idAppraiser={appraiser?.idAppraiser}
          onShowAgenda={handleShowAgenda}
          searchDate={moment().format(DateFormat)}
        />
      ),
    },
    {
      key: "agenda",
      label: t("supplier-network-appraiser-details-tab-agenda-label"),
      children: ["E2", "E3"].includes(activeRole || "") ? (
        <AppraiserAgenda idAppraiser={appraiser?.idAppraiser || -1} />
      ) : (
        <AppraiserAgendaNF
          idAppraiser={appraiser?.idAppraiser || -1}
          defaultDate={defaultAgendaDate}
        />
      ),
    },
  ];

  if (appraiser?.isInternal !== true)
    items.push({
      key: "rates",
      label: t("supplier-network-appraiser-details-tab-rates-label"),
      children: (
        <AppraiserDetailsRates
          idAppraiser={appraiser?.idAppraiser!}
          forceReload={forceReload}
        />
      ),
    });

  const handleChangeTab = (key: string) => {
    if (hasPendingChanges) {
      setTabToSwitch(key);
      setShowModal(true);
    } else {
      if (appraiser?.idAppraiser)
        dispatch(
          setAppraiserActiveTab({ id: appraiser!.idAppraiser!, tab: key! })
        );
      if (["rates", "management", "subject"].includes(key))
        setForceReload(Date.now());
    }
  };

  const handleCancelChangeTab = () => {
    dispatch(clearPendingChanges(appraiser?.id!));
    setShowModal(false);

    if (appraiser?.idAppraiser)
      dispatch(
        setAppraiserActiveTab({
          id: appraiser!.idAppraiser!,
          tab: tabToSwitch!,
        })
      );
  };

  const handleConfirmChangeTab = async () => {
    await props.onSave();
    setShowModal(false);
    if (appraiser?.idAppraiser)
      dispatch(
        setAppraiserActiveTab({
          id: appraiser!.idAppraiser!,
          tab: tabToSwitch!,
        })
      );
  };

  const handleCloseModalError = () => {
    dispatch(
      setAppraiserStatus({
        id: props.idAppraiser!,
        status: "idle",
      })
    );
  };

  const hasErrors =
    (validationErrors?.subject?.length || 0) > 0 ||
    (validationErrors?.contacts?.length || 0) > 0 ||
    (validationErrors?.addresses?.length || 0) > 0 ||
    (validationErrors?.management?.length || 0) > 0 ||
    (validationErrors?.rates?.length || 0) > 0 ||
    (validationErrors?.agenda?.length || 0) > 0;

  const disableSaveButton = !hasPendingChanges || hasErrors;

  return (
    <MainTabContentWrapper data-testid={testIds.container}>
      {["idle", "error"].includes(loadingStatus || "") === false && (
        <RefreshInTabWrapper>
          <div className="box" data-testid={""}>
            {IconLoading}
          </div>
        </RefreshInTabWrapper>
      )}

      {loadingStatus === "error" && (
        <Modal open={true} footer={null} onCancel={handleCloseModalError}>
          <div style={{ padding: "2em" }}>{error}</div>
        </Modal>
      )}

      <ResumeInfo
        items={resumeInfoItems}
        button1Text={t("button-save")!}
        button1OnClick={props.onSave}
        button1Disabled={disableSaveButton}
        formErrors={[]}
      />

      <TabsWrapper>
        <Tabs
          items={items}
          tabPosition="left"
          activeKey={activeTab}
          onChange={handleChangeTab}
          destroyInactiveTabPane={true}
        />
      </TabsWrapper>

      {showModal && (
        <Modal
          open={showModal}
          onCancel={() => setShowModal(false)}
          width="400px"
          footer={[
            <ButtonCancel onClick={handleCancelChangeTab}>
              {t("switch-no")!}
            </ButtonCancel>,
            <ButtonConfirm onClick={handleConfirmChangeTab}>
              {t("switch-yes")!}
            </ButtonConfirm>,
          ]}
        >
          <PengindChangesModal>
            {t("appraiser-details-pending-changes-1")}
            <br />
            {t("appraiser-details-pending-changes-2")}
          </PengindChangesModal>
        </Modal>
      )}
    </MainTabContentWrapper>
  );
};

export default AppraiserDetailsPresentational;
