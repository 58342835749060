import React, { useMemo } from "react";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { mainTabs } from "../../../../../config/testIds";
import { IconLoading } from "../../../../../config/icons";
import {
    PracticeRate,
    ReadOnlyPractice,
    useUpdatePracticeRatesMutation,
} from "../../../../../redux/apiSpecifications/apiCrud";
import { t } from "i18next";
import { InputNumberStyled } from "../../../../../style/Input";
import { ButtonConfirm } from "../../../../Layout/Buttons";
import { Alert, Input, Popconfirm, Space } from "antd";
import { LoadingInTabWrapper } from "../../../../../style/DashbordWidgetWrappers";
import { RestorePageOutlined } from "@mui/icons-material";
import { useAuthorization } from "../../../../../hooks/useAuthorization";
import { FormRow } from "../../../../../style/form";

const testIds = mainTabs.tabs.historicalActions;

// Styled Components
const AppraisalDetailsRatesWrapper = styled.div`
    .table-rates {
        margin-bottom: 2em;
    }

    th {
        font-weight: normal;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: 0.9em;
        padding: 1em 0;
    }

    .button-wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .loading-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;

        .box {
            height: 5em;
            width: 5em;
        }
    }
`;

/**
 * @description Props od AppraisalDetailsRates
 * @practice practice data
 */
interface IAppraisalDetailsRatesProps {
    practice: ReadOnlyPractice | undefined;
}

type FePracticeRate = PracticeRate & {
    rateDelta?: number;
    baseRateDelta?: number;
    baseFinalRate?: number;
};

const initBaseRate = (rates: FePracticeRate[]) => {
    const rate = rates[0] || {};

    return [
        {
            ...rate,
            baseRate: rate.baseRate || rate.rate,
            baseRateType: rate.baseRateType || rate.rateType,
            basePenaltyPercentage:
                rate.basePenaltyPercentage || rate.penaltyPercentage,
            baseDelta: rate.baseDelta || rate.delta,
            baseCashPercentage: rate.baseCashPercentage || rate.cashPercentage,
        },
    ];
};

/**
 * @description Show the rates of the practice
 */
const AppraisalDetailsRates = ({ practice }: IAppraisalDetailsRatesProps) => {
    // Common variables
    const { t } = useTranslation();
    const { activeRole } = useSelector((state: RootState) => state.user);
    const { getAuthorization } = useAuthorization();

    // Local state
    const [hasChanged, setHasChanged] = useState(false);
    const [rates, setRates] = useState<
        FePracticeRate[] | PracticeRate[] | undefined
    >(initBaseRate(practice?.rates || []));
    const [savingStatus, setSavingStatus] = useState<"idle" | "saving">("idle");
    const [error, setError] = useState<string | undefined>(undefined);

    // Use mutations
    const [updateRates] = useUpdatePracticeRatesMutation();

    // Use Effects
    useEffect(() => {
        const updateRates = practice?.rates?.map((r) => {
            const rate = r.rate ? r.rate : 0;
            const delta = r.delta ? r.delta : 0;
            const rateDelta = rate * (1 + delta / 100);
            const cashPercentage = r.cashPercentage ? r.cashPercentage : 0;
            const finalRate =
                rateDelta + (rateDelta * (1 * cashPercentage)) / 100;

            const baseRate = r.baseRate ? r.baseRate : rate;
            const baseDelta = r.baseDelta ? r.baseDelta : delta;
            const baseRateDelta = baseRate * (1 + baseDelta / 100);
            const baseCashPercentage = r.baseCashPercentage
                ? r.baseCashPercentage
                : cashPercentage;
            const baseFinalRate =
                baseRateDelta +
                (baseRateDelta * (1 * baseCashPercentage)) / 100;

            return {
                ...r,
                delta,
                cashPercentage,
                rate,
                rateDelta,
                finalRate,
                baseCashPercentage,
                baseDelta,
                baseRate,
                baseFinalRate,
            };
        });

        setRates(updateRates);
    }, [practice?.rates]);

    const handleChange = (val: number, field: string, index: number) => {
        const newRates =
            rates?.map((rate, i) => {
                if (i !== index) return rate;

                if (field === "delta") {
                    const rateDelta = (rate.rate || 0) * (1 + val / 100);
                    const cashPercentage = rate.cashPercentage || 0;
                    const _finalRate =
                        rateDelta + (rateDelta * (1 * cashPercentage)) / 100;

                    return {
                        ...rate,
                        delta: val,
                        rateDelta: Math.round(rateDelta * 100) / 100,
                        finalRate: Math.round(_finalRate * 100) / 100,
                    };
                }

                if (field === "rateDelta") {
                    const _rate = rate.rate || 0;
                    const _delta = ((val - _rate) / _rate) * 100;
                    const cashPercentage = rate.cashPercentage || 0;
                    const _finalRate = val + (val * (1 * cashPercentage)) / 100;

                    return {
                        ...rate,
                        rateDelta: val,
                        delta: Math.round(_delta * 100) / 100,
                        finalRate: Math.round(_finalRate * 100) / 100,
                    };
                }

                return rate;
            }) || [];

        setRates(newRates);
        setHasChanged(true);
    };

    const handleChangeNotes = (val: string, index: number) => {
        const newRates =
            rates?.map((rate, i) => {
                if (i !== index) return rate;

                return {
                    ...rate,
                    notes: val,
                };
            }) || [];

        setRates(newRates);
        setHasChanged(true);
    };

    const handleSave = async () => {
        setSavingStatus("saving");

        if (!practice || !rates || !activeRole) return;

        const idPractice = practice.id!;
        // const practiceRate = rates.find((r) => r.isActive)!;
        // const response = await updateRates({
        //   authorization: await getAuthorization(),
        //   idPractice,
        //   practiceRate,
        //   activeRole,
        // });
        const response = await updateRates({
            authorization: await getAuthorization(),
            idPractice,
            practiceRate: rates[rates.length - 1],
            activeRole,
        });

        const correctResponse = response as { data: ReadOnlyPractice };
        const errorResponse = response as { error: string };

        if (correctResponse?.data?.id) {
            //
        } else {
            setError("rate-practice-error-saving");
        }

        setHasChanged(false);
        setSavingStatus("idle");
    };

    const showDisclaimerAppraiser = (() => {
        //const activeRate = rates?.find((r) => r.isActive);
        const activeRate = rates ? rates[rates.length - 1] : undefined;
        return activeRate?.rate == 0 || activeRate?.rate == 0;
    })();

    return (
        <AppraisalDetailsRatesWrapper data-testid={""}>
            {savingStatus === "saving" && (
                <div className="loading-wrapper">
                    <div className="box" data-testid={""}>
                        {IconLoading}
                    </div>
                </div>
            )}

            {savingStatus === "idle" && error && (
                <div className="error">{error}</div>
            )}

            {showDisclaimerAppraiser && (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "2em 0",
                    }}
                >
                    <Space direction="vertical">
                        <Alert
                            message={t("rate-practice-message-empty-rate")}
                            type="info"
                        />
                    </Space>
                </div>
            )}

            {savingStatus === "idle" && !error && (rates?.length || 0) > 0 && (
                <>
                    <table className="table-rates">
                        <thead>
                            <tr>
                                <th></th>
                                <th>{t("rate-practice-base-rate-label")}</th>
                                <th>{t("rate-practice-penalty-label")}</th>
                                <th>{t("rate-practice-delta-label")}</th>
                                <th>{t("rate-practice-rate-label")}</th>
                                <th>{t("rate-practice-cash-label")}</th>
                                <th>{t("rate-practice-total-label")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rates?.map((rate, i) => (
                                <>
                                    <Rate
                                        key={i}
                                        isActive={i === rates.length - 1}
                                        rate={rate}
                                        onChange={(val, field) => handleChange(val, field, i)} activeRole={activeRole!}                                  />
                                </>
                            ))}
                        </tbody>
                    </table>

                    {rates && (
                        <FormRow className="message-text">
                            <Input.TextArea
                                // maxLength={1000}
                                onChange={(e) =>
                                    handleChangeNotes(
                                        e.target.value,
                                        rates.length - 1
                                    )
                                }
                                value={rates[rates.length - 1].notes ?? ""}
                                className="message-text"
                                readOnly={["G1", "G2"].includes(activeRole!) || practice?.status === "CF"}
                                placeholder={
                                    t("rate-practice-note-placeholder")!
                                }
                            />
                        </FormRow>
                    )}

                    <div className="button-wrapper">
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                gap: "2em",
                                flexDirection: "column",
                                alignItems: "center",
                            }}
                        >
                            <Space direction="vertical">
                                <Alert
                                    message={t("rate-practice-message")}
                                    type="info"
                                />
                            </Space>

                            <Popconfirm
                                title={
                                    t(
                                        "edit-expertizer-client-permissions-button-save-confirm"
                                    )!
                                }
                                onConfirm={handleSave}
                                onCancel={() => {}}
                                okText={
                                    <div data-testid={""}>
                                        {t("switch-yes")!}
                                    </div>
                                }
                                cancelText={t("switch-no")!}
                            >
                                <ButtonConfirm
                                    onClick={() => {}}
                                    dataTestId={""}
                                    disabled={!hasChanged || activeRole === "G2" || practice?.status === "CF"}
                                >
                                    {t("button-confirm")!}
                                </ButtonConfirm>
                            </Popconfirm>
                        </div>
                    </div>
                </>
            )}
        </AppraisalDetailsRatesWrapper>
    );
};

const RateWrapper = styled.tr<{ isActive?: boolean }>`
    .input-field {
        border-radius: 1em;
        background-color: ${(props) =>
            props.isActive ? "transparent" : "#eee"};
        overflow: hidden;
    }

    .ant-form-item {
        margin: 0.25em 2em;
    }
`;

interface IRateProps {
    rate: FePracticeRate;
    isActive: boolean;
    activeRole:string;
    onChange: (val: number, field: string) => void;
}

const Rate = ({ rate, isActive, activeRole, onChange }: IRateProps) => {
    return (
        <RateWrapper isActive={isActive}>
            <td>{t(`rate-name-tooltip-${rate.rateType}`)}</td>
            <td>
                <InputNumberStyled
                    controls={false}
                    readOnly={true}
                    classNameInput="input-field"
                    value={(rate.rate || 0).toFixed(2)}
                    onChange={(val) => onChange(val, "rate")}
                />
            </td>
            <td>
                <InputNumberStyled
                    readOnly={true}
                    classNameInput="input-field"
                    value={(rate.penaltyPercentage || 0).toFixed(2)}
                    onChange={(val) => onChange(val, "penaltyPercentage")}
                    onDoubleClick={() =>
                        onChange(rate?.penaltyPercentage || 0 * -1, "delta")
                    }
                />
            </td>
            <td>
                <InputNumberStyled
                    classNameInput="input-field"
                    controls={false}
                    value={(rate.delta || 0).toFixed(2)}
                    readOnly={["G1", "G2"].includes(activeRole!)}
                    onChange={(val) => onChange(val, "delta")}
                    onClick={(e) => e.target.select()}
                />
            </td>
            <td>
                <InputNumberStyled
                    classNameInput="input-field"
                    controls={false}
                    value={(rate.rateDelta || 0).toFixed(2)}
                    readOnly={["G1", "G2"].includes(activeRole!)}
                    onChange={(val) => onChange(val, "rateDelta")}
                    onClick={(e) => e.target.select()}
                />
            </td>
            <td>
                <InputNumberStyled
                    readOnly={true}
                    controls={false}
                    classNameInput="input-field"
                    value={(rate.cashPercentage || 0).toFixed(2)}
                    onChange={(val) => onChange(val, "cashPercentage")}
                />
            </td>
            <td>
                <InputNumberStyled
                    readOnly={true}
                    classNameInput="input-field"
                    controls={false}
                    value={(rate.finalRate || 0).toFixed(2)}
                    onChange={(val) => onChange(val, "finalRate")}
                />
            </td>
        </RateWrapper>
    );
};

interface IRateBaseProps {
    rate: FePracticeRate;
}
const BaseRate = ({ rate }: IRateBaseProps) => {
    if (rate.baseRateType === rate.rateType || rate.baseRateType == null)
        return null;

    return (
        <RateWrapper isActive={false}>
            <td>{t(`rate-name-tooltip-${rate.baseRateType}`)}</td>
            <td>
                <InputNumberStyled
                    controls={false}
                    readOnly={true}
                    classNameInput="input-field"
                    value={(rate.baseRate || 0).toFixed(2)}
                    onChange={(val) => {}}
                />
            </td>
            <td>
                <InputNumberStyled
                    readOnly={true}
                    classNameInput="input-field"
                    value={(rate.basePenaltyPercentage || 0).toFixed(2)}
                    onChange={(val) => {}}
                />
            </td>
            <td>
                <InputNumberStyled
                    classNameInput="input-field"
                    controls={false}
                    value={(rate.baseDelta || 0).toFixed(2)}
                    readOnly={true}
                    onChange={(val) => {}}
                />
            </td>
            <td>
                <InputNumberStyled
                    classNameInput="input-field"
                    controls={false}
                    value={(rate.baseRateDelta || 0).toFixed(2)}
                    readOnly={true}
                    onChange={(val) => {}}
                />
            </td>
            <td>
                <InputNumberStyled
                    readOnly={true}
                    controls={false}
                    classNameInput="input-field"
                    value={(rate.baseCashPercentage || 0).toFixed(2)}
                    onChange={(val) => {}}
                />
            </td>
            <td>
                <InputNumberStyled
                    readOnly={true}
                    classNameInput="input-field"
                    controls={false}
                    value={(rate.baseFinalRate || 0).toFixed(2)}
                    onChange={(val) => {}}
                />
            </td>
        </RateWrapper>
    );
};
export default AppraisalDetailsRates;
