import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  Appointment,
  AppraiserManagementData,
  AppraiserRate,
  AppraiserUser,
  DailyAgenda,
  District,
  Province,
  ProvincesAndZones,
  Zone,
} from "../apiSpecifications/apiFesf";
import { DayContent } from "../../components/Inputs/AgendaBase";
import moment from "moment";
import { SelectedDayType } from "../../components/MainTabs/MainTabContent/AppraiserDetails/Agenda/ProvinceList";
import { SelectedProvince } from "../../components/MainTabs/MainTabContent/AppraiserDetails/Agenda/AppraiserAgenda";
import {
  AppraiserStatusValues,
  ProvinceSelection,
  RangeValue,
} from "../../components/MainTabs/MainTabContent/AppraiserDetails/Agenda/AppraiserAgendaAppraiserStatus";

export type AppStatus = "idle" | "loading" | "saving" | "error";
export type PendingChanges =
  | ""
  | "subject"
  | "contacts"
  | "addresses"
  | "management"
  | "agenda"
  | "rates";

export type AppraiserDefaultSettings = {
  tab?: string;
  agendaDate?: string;
  agendaStatus?: string;
  agendaReason?: string;
  agendaProvince?: string;
};

export type AppraiserRateData = {
  appraiserData: AppraiserRate;
  modified: boolean;
};

export type AppraiserActiveStatusValues = {
  provinces: ProvinceSelection[] | undefined;
  range: RangeValue | undefined;
  status: string | undefined;
  statusCode?: string | undefined;
};

export type AppraiserState = {
  id: number;
  status: AppStatus;
  errorMessage?: string;
  defaultSettings?: AppraiserDefaultSettings;
  mainData?: AppraiserUser;
  managementData?: AppraiserManagementData;
  myAgenda?: {
    days: DailyAgenda[];
    districts: District[];
    selectedDay?: DailyAgenda;
    selectionType?: SelectedDayType;
    selectedProvince?: SelectedProvince;
    selectedAppointments?: Appointment[];
    defaultStatusValues?: AppraiserStatusValues;
    activeStatusValues?: AppraiserActiveStatusValues;
    forceToUpdate?: number;
  };
  ratesData: AppraiserRateData[];
  hasPendingChanges: boolean;
  pendingChanges: PendingChanges[];
  validationErrors: {
    subject: string[];
    contacts: string[];
    addresses: string[];
    management?: string[];
    rates?: string[];
    agenda?: string[];
  };
  activeTab: string;
};

export interface IAppraisersState {
  appraisers: AppraiserState[];
  zones: Zone[];
  provinces: Province[];
}

const addArrayItemIfNotExists = (array: string[], item: string) => {
  if (!array.includes(item)) return [...array, item];
  return array;
};

const buildInitialState = () => {
  return {
    appraisers: [],
    zones: [],
    provinces: [],
  } as IAppraisersState;
};

export const appraisersSlice = createSlice({
  name: "appraisers",
  initialState: buildInitialState(),
  reducers: {
    // APPRAISER MAIN DATA
    addAppraiser(
      state,
      action: PayloadAction<{
        id: number;
        defaultSettings: AppraiserDefaultSettings;
      }>
    ) {
      const appraiser = state.appraisers.find(
        (a) => a.id === action.payload.id
      );

      if (appraiser) {
        appraiser.hasPendingChanges = false;
        appraiser.pendingChanges = [];
      } else
        state.appraisers.push({
          id: action.payload.id,
          status: "loading",
          defaultSettings: action.payload.defaultSettings,
          myAgenda: {
            days: [],
            districts: [],
            selectedDay: {
              date: moment().format("DD/MM/YYYY"),
              content: [] as DayContent[],
            },
          },
          ratesData: [],
          hasPendingChanges: false,
          pendingChanges: [],
          validationErrors: {
            subject: [],
            contacts: [],
            addresses: [],
            management: [],
            rates: [],
          },
          activeTab: "subject",
        } as AppraiserState);
    },
    removeAppraiser(state, action: PayloadAction<number>) {
      state.appraisers = state.appraisers.filter(
        (a) => a.id !== action.payload
      );
    },
    setAppraiserStatus(
      state,
      action: PayloadAction<{
        id: number;
        status: AppStatus;
        errorMessage?: string;
      }>
    ) {
      state.appraisers = state.appraisers.map((a) =>
        a.id !== action.payload.id
          ? a
          : ({
              ...a,
              status: action.payload.status,
              errorMessage: action.payload.errorMessage
                ? action.payload.errorMessage
                : a.errorMessage,
            } as AppraiserState)
      );
    },
    clearPendingChanges(state, action: PayloadAction<number>) {
      state.appraisers = state.appraisers.map((a) =>
        a.id !== action.payload
          ? a
          : ({
              ...a,
              hasPendingChanges: false,
              pendingChanges: [],
              status: "idle",
            } as AppraiserState)
      );
    },
    setValidation(
      state,
      action: PayloadAction<{
        id: number;
        validationErrors: {
          subject?: string[] | undefined;
          contacts?: string[] | undefined;
          addresses?: string[] | undefined;
          management?: string[] | undefined;
          rates?: string[] | undefined;
          agenda?: string[] | undefined;
        };
      }>
    ) {
      state.appraisers = state.appraisers.map((a) =>
        a.id !== action.payload.id
          ? a
          : ({
              ...a,
              validationErrors: {
                subject: action.payload.validationErrors.subject || [],
                contacts: action.payload.validationErrors.contacts || [],
                addresses: action.payload.validationErrors.addresses || [],
                management: action.payload.validationErrors.management || [],
                rates: action.payload.validationErrors.rates || [],
                agenda: action.payload.validationErrors.agenda || [],
              },
            } as AppraiserState)
      );
    },
    setAppraiserMainData(
      state,
      action: PayloadAction<{ appraiser: AppraiserUser; isUpdate?: boolean }>
    ) {
      state.appraisers = state.appraisers.map((a) =>
        a.id !== action.payload.appraiser.idAppraiser
          ? a
          : ({
              ...a,
              id: action.payload.appraiser.idAppraiser,
              mainData: action.payload.appraiser,
              status: "idle",
              hasPendingChanges: a.hasPendingChanges || action.payload.isUpdate,
              pendingChanges: action.payload.isUpdate
                ? addArrayItemIfNotExists(a.pendingChanges, "subject")
                : a.pendingChanges,
            } as AppraiserState)
      );
    },
    setAppraiserManagementData(
      state,
      action: PayloadAction<{
        id: number;
        data: AppraiserManagementData;
        isUpdate: boolean;
      }>
    ) {
      state.appraisers = state.appraisers.map((a) =>
        a.id !== action.payload.id
          ? a
          : ({
              ...a,
              id: action.payload.id,
              managementData: action.payload.data,
              status: "idle",
              hasPendingChanges: a.hasPendingChanges || action.payload.isUpdate,
              pendingChanges: action.payload.isUpdate
                ? addArrayItemIfNotExists(a.pendingChanges, "management")
                : a.pendingChanges,
            } as AppraiserState)
      );
    },
    setZonesAndProvinces(state, action: PayloadAction<ProvincesAndZones>) {
      state.zones = action.payload.zones || [];
      state.provinces = action.payload.provinces || [];
    },
    // APPRAISER AGENDA
    setAppraiserAgendaDays(
      state,
      action: PayloadAction<{
        id: number;
        days: DailyAgenda[];
        dayToSelect?: string;
        districts: District[];
      }>
    ) {
      const day = action.payload.days.find(
        (d) => d.date === action.payload.dayToSelect
      ) || {
        date: action.payload.dayToSelect,
        onSuspendedDistricts: [],
        onBlockedDistricts: [],
        onClosedDistricts: [],
        onIllnessDistricts: [],
        onLeaveDistricts: [],
        onPatronSaintDistricts: [],
        appointments: [],
      };

      state.appraisers = state.appraisers.map((a) => {
        return a.id !== action.payload.id
          ? a
          : ({
              ...a,
              myAgenda: {
                ...a.myAgenda,
                days: action.payload.days,
                districts: action.payload.districts,
                selectedDay: { ...day },
              },
              status: "idle",
              defaultSettings: undefined,
              activeStatusValues: undefined,
              forceToUpdate: 0,
            } as AppraiserState);
      });
    },
    setAppraiserAgendaSelectDay(
      state,
      action: PayloadAction<{
        id: number;
        date: string;
        type: SelectedDayType;
        range?: RangeValue | undefined;
      }>
    ) {
      const day = state.appraisers
        ?.find((a) => a.id === action.payload.id)
        ?.myAgenda?.days.find((d) => d.date === action.payload.date) || {
        appointments: [],
        date: action.payload.date,
        onBlockedDistricts: [],
        onClosedDistricts: [],
        onIllnessDistricts: [],
        onLeaveDistricts: [],
        onPatronSaintDistricts: [],
        onSuspendedDistricts: [],
      };

      const type = action.payload.type;
      const { status, statusCode } =
        type === "appointments"
          ? { status: "appointments", statusCode: "" }
          : type === "leave"
          ? { status: "leave", statusCode: "FGE" }
          : type === "saint"
          ? { status: "saint", statusCode: "FSP" }
          : type === "illness"
          ? { status: "illness", statusCode: "SMP" }
          : { status: "", statusCode: "" };

      let appraiser = state.appraisers.find((a) => a.id === action.payload.id);

      appraiser!.myAgenda = {
        days: appraiser!.myAgenda?.days || [],
        districts: appraiser!.myAgenda?.districts || [],
        forceToUpdate: appraiser!.myAgenda?.forceToUpdate || 0,
        selectedDay: { ...day },
        selectionType: action.payload.type,
        selectedProvince: undefined,
        selectedAppointments: undefined,
        defaultStatusValues: {
          provinces: [],
          range: action.payload.range,
          status,
          statusCode,
        },
        activeStatusValues: {
          provinces: [],
          range: undefined,
          status: "",
          statusCode: "",
        },
      };

      appraiser!.hasPendingChanges = false;
      appraiser!.pendingChanges = appraiser!.pendingChanges.filter(
        (pc) => pc !== "agenda"
      );

      // state.appraisers = state.appraisers.map((a) =>
      //   a.id !== action.payload.id
      //     ? a
      //     : ({
      //         ...a,
      //         myAgenda: {
      //           ...a.myAgenda,
      //           selectedDay: { ...day },
      //           selectionType: action.payload.type,
      //           selectedProvince: undefined,
      //           selectedAppointments: undefined,
      //           defaultStatusValues: {
      //             provinces: [],
      //             range: action.payload.range,
      //             status,
      //             statusCode,
      //           },
      //           activeStatusValues: {
      //             provinces: [],
      //             range: undefined,
      //             status: "",
      //             statusCode: "",
      //           },
      //         },
      //         hasPendingChanges: false,
      //         pendingChanges: a.pendingChanges.filter((pc) => pc !== "agenda"),
      //       } as AppraiserState)
      // );
    },
    setAppraiserAgendaSelectProvince(
      state,
      action: PayloadAction<{
        id: number;
        range?: RangeValue | undefined;
        province: SelectedProvince;
        appointments: Appointment[];
        defaultProvinces: string[];
        status?: string;
        statusCode?: string;
      }>
    ) {
      state.appraisers = state.appraisers.map((a) => {
        return a.id !== action.payload.id
          ? a
          : ({
              ...a,
              myAgenda: {
                ...a.myAgenda,
                selectedProvince: action.payload.province,
                selectedAppointments: action.payload.appointments,
                defaultStatusValues: {
                  ...a.myAgenda?.defaultStatusValues,
                  provinces: action.payload.defaultProvinces,
                  range: action.payload.range,
                  status:
                    action.payload.status ||
                    a.myAgenda?.defaultStatusValues?.status,
                  statusCode:
                    action.payload.statusCode ||
                    a.myAgenda?.defaultStatusValues?.statusCode,
                },
                activeStatusValues: {
                  ...a.myAgenda?.activeStatusValues,
                  range: action.payload.range,
                  provinces: a.myAgenda?.districts.map((d) => ({
                    ...d,
                    selected: action.payload.defaultProvinces.includes(
                      d.districtCode || ""
                    ),
                    status:
                      action.payload.status ||
                      a.myAgenda?.defaultStatusValues?.status,
                    statusCode:
                      action.payload.statusCode ||
                      a.myAgenda?.defaultStatusValues?.statusCode,
                  })),
                },
              },
              hasPendingChanges: false,
              pendingChanges: a.pendingChanges.filter((pc) => pc !== "agenda"),
            } as AppraiserState);
      });
    },
    setAppraiserDefaultStatusValues(
      state,
      action: PayloadAction<{
        id: number;
        defaultProvinces: string[];
        selectedDayDate: string;
        status: string;
        statusCode?: string;
        hasPendingChanges?: boolean;
        pendingChanges?: PendingChanges;
      }>
    ) {
      state.appraisers = state.appraisers.map((a) => {
        const newAppraiser =
          a.id !== action.payload.id
            ? a
            : ({
                ...a,
                myAgenda: {
                  ...a.myAgenda,
                  selectedDay: {
                    ...a.myAgenda?.selectedDay,
                    date: action.payload.selectedDayDate,
                  },
                  defaultStatusValues: {
                    ...a.myAgenda?.defaultStatusValues,
                    provinces: action.payload.defaultProvinces,
                    status: action.payload.status,
                    statusCode: action.payload.statusCode,
                  },
                  activeStatusValues: {
                    ...a.myAgenda?.defaultStatusValues,
                    provinces: a.myAgenda?.districts.map((d) => ({
                      ...d,
                      selected: action.payload.defaultProvinces.includes(
                        d.districtCode || ""
                      ),
                    })),
                  },
                },
                hasPendingChanges: action.payload.hasPendingChanges
                  ? true
                  : a.hasPendingChanges,
                pendingChanges: action.payload.pendingChanges
                  ? addArrayItemIfNotExists(
                      a.pendingChanges,
                      action.payload.pendingChanges
                    )
                  : a.pendingChanges,
              } as AppraiserState);
        return newAppraiser;
      });
    },
    setAppraiserActiveStatusValues(
      state,
      action: PayloadAction<{
        id: number;
        range: RangeValue | undefined;
        provinces: ProvinceSelection[];
        status: string;
        statusCode: string;
        isUpdate: boolean;
      }>
    ) {
      state.appraisers = state.appraisers.map((a) => {
        const newAppraiser =
          a.id !== action.payload.id
            ? a
            : ({
                ...a,
                myAgenda: {
                  ...a.myAgenda,
                  activeStatusValues: {
                    range: action.payload.range,
                    provinces: action.payload.provinces,
                    status: action.payload.status,
                    statusCode: action.payload.statusCode,
                  },
                },
                hasPendingChanges: action.payload.isUpdate,
                pendingChanges: action.payload.isUpdate
                  ? addArrayItemIfNotExists(a.pendingChanges, "agenda")
                  : a.pendingChanges,
              } as AppraiserState);
        return newAppraiser;
      });
    },
    forceUpdate(
      state,
      action: PayloadAction<{ id: number; agenda?: boolean }>
    ) {
      if (action.payload.agenda) {
        const appraiser = state.appraisers.find(
          (a) => a.id === action.payload.id
        );
        appraiser!.myAgenda!.forceToUpdate = Date.now();
      }
    },
    setAppraiserRatesData(
      state,
      action: PayloadAction<{
        id: number;
        ratesData: AppraiserRateData[];
        isUpdate: boolean;
      }>
    ) {
      state.appraisers = state.appraisers.map((a) =>
        a.id !== action.payload.id
          ? a
          : ({
              ...a,
              id: action.payload.id,
              ratesData: action.payload.ratesData,
              status: "idle",
              hasPendingChanges: a.hasPendingChanges || action.payload.isUpdate,
              pendingChanges: action.payload.isUpdate
                ? addArrayItemIfNotExists(a.pendingChanges, "rates")
                : a.pendingChanges,
            } as AppraiserState)
      );
    },
    setAppraiserError(
      state,
      action: PayloadAction<{ id: number; message: string }>
    ) {
      state.appraisers = state.appraisers.map((a) =>
        a.id !== action.payload.id
          ? a
          : ({
              ...a,
              status: "error",
              errorMessage: action.payload.message,
            } as AppraiserState)
      );
    },
    setAppraiserActiveTab(
      state,
      action: PayloadAction<{ id: number; tab: string }>
    ) {
      state.appraisers = state.appraisers.map((a) =>
        a.id !== action.payload.id
          ? a
          : ({
              ...a,
              activeTab: action.payload.tab,
            } as AppraiserState)
      );
    },
  },
});

export const {
  addAppraiser,
  removeAppraiser,
  setAppraiserStatus,
  clearPendingChanges,
  setValidation,
  setZonesAndProvinces,
  setAppraiserMainData,
  setAppraiserManagementData,
  setAppraiserAgendaDays,
  setAppraiserAgendaSelectDay,
  setAppraiserAgendaSelectProvince,
  setAppraiserDefaultStatusValues,
  setAppraiserActiveStatusValues,
  setAppraiserRatesData,
  setAppraiserError,
  forceUpdate,
  setAppraiserActiveTab,
} = appraisersSlice.actions;

export default appraisersSlice.reducer;
