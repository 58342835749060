import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { IconLoading } from "../../config/icons";
import iniIcon from "../../images/ini-icon.png";
import dekra from "../../style/dekra";
import { Tooltip } from "antd";
import { FaFileDownload } from "react-icons/fa";
import React, { useEffect } from "react";
import { PracticeAttachment } from "../../redux/apiSpecifications/apiCrud";

const FileWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 12em;
    font-size: 0.9em;
    line-height: 1em;
    text-align: center;
`;

const PreviewWrapper = styled.div`
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
`;

const LoadingWrapper = styled.div`
    background-color: transparent;
    display: flex;
    justify-content: center;
    padding-top: 20%;
    .box {
        width: 5em;
    }
`;

const FileOverlay = styled.div`
    max-width: 100px;
    max-height: 60px;
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    opacity: 0;
    &:hover {
        z-index: 4;
        background-color: #fff;
        box-shadow: 0 0 5px #aaa;
        border-radius: 5px;
        opacity: 1;
    }
`;

const FilePreview = styled.img`
	max-width: 60px;
	max-height: 60px;
	position: absolute;
	z-index: 3;
	display: flex
	align-items: center;
	justyfy-content: center;
	&:hover {
		z-index: 1;
    
	}
`;

const IconWrapper = styled.div`
    color: ${dekra.primaryColor};
    cursor: pointer;
    font-size: 2em;
`;

interface IINIAttachmentPracticeProps {
    onDownload: () => void;
    isLoading?: boolean;
    attachment?: PracticeAttachment;
}

const INIAttachmentPractice = ({
    isLoading,
    onDownload,
    attachment,
}: IINIAttachmentPracticeProps) => {
    const { t } = useTranslation();

    const refDownloadINI = React.useRef<HTMLAnchorElement>(null);

    const [toDownload, setToDownload] = React.useState(false);

    const fileIcon = <FilePreview src={iniIcon} />;

    const handleOnDownloadFile = async () => {
        if (attachment?.base64File) {
            refDownloadINI?.current?.click();
            return;
        } else {
            await onDownload();
            setToDownload(true);
        }
    };

    useEffect(() => {
        if (!toDownload || !attachment?.base64File) return;

        refDownloadINI?.current?.click();

        setToDownload(false);
    }, [toDownload, attachment?.base64File]);

    console.info(attachment);

    return (
        <FileWrapper>
            <PreviewWrapper>
                {isLoading && (
                    <LoadingWrapper>
                        <div className="box">{IconLoading}</div>
                    </LoadingWrapper>
                )}
                {!isLoading && (
                    <>
                        {fileIcon}
                        <FileOverlay>
                            <IconWrapper onClick={handleOnDownloadFile}>
                                <Tooltip
                                    title={t(
                                        "appraisal-details-tab-documents-icon-download-tooltip"
                                    )}
                                >
                                    <FaFileDownload />
                                </Tooltip>
                            </IconWrapper>
                        </FileOverlay>
                    </>
                )}
            </PreviewWrapper>
            <div>INI</div>
            <a
                style={{ display: "none" }}
                ref={refDownloadINI}
                download={attachment?.originalName}
                href={attachment?.base64File}
            >
                download
            </a>
        </FileWrapper>
    );
};

export default INIAttachmentPractice;
