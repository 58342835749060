import {
  CommunicationCategory,
  CommunicationsBySenderTypeAndCategories,
  DashboardD2Summary,
  DashboardE1Summary,
  DashboardE2Summary,
  DashboardE4Summary,
  DashboardF1Summary,
  DashboardSummary,
  PracticeBreakDown,
  PracticesInProgressSummary,
  PracticesToManageE2,
  RcaCvtPair,
  RegisteredLetterError,
  TotalByCategory,
  UserRequestsSummary,
} from "../../../redux/apiSpecifications/apiCrud";
import { AccountingSummary } from "../../../redux/apiSpecifications/apiFesf";

export const getNow = () => Math.floor(Date.now() / 1000);

export const addNumbers = (a: number | undefined, b: number | undefined) =>
  (a || 0) + (b || 0);

export const AggregateCommunications = (
  comms: CommunicationsBySenderTypeAndCategories[]
): CommunicationsBySenderTypeAndCategories[] => {
  if (!comms) return [] as CommunicationsBySenderTypeAndCategories[];

  const uniqueSenders = Array.from(
    new Set(comms.map((com) => com?.senderType).flat())
  );

  const retVal = uniqueSenders.map((senderType) => {
    // all categories of a single sender type
    const allSenderCategories = comms
      .filter((com) => com.senderType === senderType)
      .map((com) => com.communicationsByCategory)
      .flat()
      .filter((cat) => !!cat);

    // unique categories code of this sender
    const uniqueSenderCategoriesCode = Array.from(
      new Set(allSenderCategories.map((cat) => cat?.categoryCode))
    );

    // for each category, loop all tenants of this sender and aggregate for communicationCatetgory and totalByStatusValue
    const communicationsByCategory = uniqueSenderCategoriesCode.map(
      (catCode) => {
        // all communicationsByCategory of this kind for all tenants of this sender
        const tenantsCategoriesByCode = allSenderCategories.filter(
          (sCat) => sCat?.categoryCode === catCode
        );

        // es: tenantsCategoriesByCode contains all "Sollecito" communications of all tenants of sender type "Cliente"
        // tenantsCategoriesByCode = [{ ...nuove}, {...lette}, {...bozza}, {...inviate}]

        const { id, categoryCode } = tenantsCategoriesByCode[0] || {};
        return tenantsCategoriesByCode.reduce(
          (aggr, item) => {
            const totalsByCategoryAndStatus = [
              ...(aggr?.totalsByCategoryAndStatus || []),
            ];
            // check if item categories status is already contained into aggr
            // if yes => add total
            // if not => push another status object
            item?.totalsByCategoryAndStatus?.forEach((cs) => {
              const categoryStatusInAggr = totalsByCategoryAndStatus.find(
                (aggrCs) => aggrCs.statusCode === cs.statusCode
              );
              if (!!categoryStatusInAggr) {
                categoryStatusInAggr.totalByStatusValue! +=
                  cs?.totalByStatusValue || 0;
              } else {
                totalsByCategoryAndStatus.push({ ...cs });
              }
            });

            const category = {
              id: aggr?.id,
              categoryCode: aggr?.categoryCode,
              totalByCategory:
                (aggr?.totalByCategory || 0) + (item?.totalByCategory || 0),
              totalsByCategoryAndStatus,
            };
            return category;
          },
          {
            id,
            categoryCode,
            totalByCategory: 0,
            totalsByCategoryAndStatus: [],
          } as CommunicationCategory
        );
      }
    );

    return {
      senderType,
      communicationsByCategory,
    } as CommunicationsBySenderTypeAndCategories;
  });

  return retVal;
};

export const filterStatusByDiscardedD2 = (
  data: DashboardD2Summary
): DashboardD2Summary => {
  const filteredPracticesDiscarded = (
    data?.dashboardSummary?.practices || []
  ).filter((practice) => practice.practiceStatusAggr === "1");

  const practicesDiscarded = {
    total: filteredPracticesDiscarded.length,
    urgent: {
      rca: filteredPracticesDiscarded.filter(
        (practice) =>
          practice.priority === 1 && practice.claimsBranchGroup === "RCA"
      ).length,
      cvt: filteredPracticesDiscarded.filter(
        (practice) =>
          practice.priority === 1 && practice.claimsBranchGroup === "CVT"
      ).length,
    },
    due: {
      rca: filteredPracticesDiscarded.filter(
        (practice) =>
          practice.priority === 2 && practice.claimsBranchGroup === "RCA"
      ).length,
      cvt: filteredPracticesDiscarded.filter(
        (practice) =>
          practice.priority === 2 && practice.claimsBranchGroup === "CVT"
      ).length,
    },
    new: {
      rca: filteredPracticesDiscarded.filter(
        (practice) =>
          practice.priority === 3 && practice.claimsBranchGroup === "RCA"
      ).length,
      cvt: filteredPracticesDiscarded.filter(
        (practice) =>
          practice.priority === 3 && practice.claimsBranchGroup === "CVT"
      ).length,
    },
    suspended: {
      rca: filteredPracticesDiscarded.filter(
        (practice) =>
          practice.priority === 4 && practice.claimsBranchGroup === "RCA"
      ).length,
      cvt: filteredPracticesDiscarded.filter(
        (practice) =>
          practice.priority === 4 && practice.claimsBranchGroup === "CVT"
      ).length,
    },
  };

  const result = {
    dashboardSummary: {
      totalDailyPractices: 0,
      totalAssignedPractices: 0,
      totalDiscardedPractices: 0,
      practicesToManage: practicesDiscarded,
      communicationSummary: undefined,
      practices: filteredPracticesDiscarded,
    },
    practicesInProgress: undefined,
    practicesToBeVerified: undefined,
    practicesCancelled: undefined,
  };

  return result;
};

export const filterStatusByDiscardedD1 = (
  dashboardSummary: DashboardSummary
): DashboardSummary => {
  const filteredPracticesDiscarded = (dashboardSummary?.practices || []).filter(
    (practice) => practice.practiceStatusAggr === "1"
  );

  const practicesDiscarded = {
    total: filteredPracticesDiscarded.length,
    urgent: {
      rca: filteredPracticesDiscarded.filter(
        (practice) =>
          practice.priority === 1 && practice.claimsBranchGroup === "RCA"
      ).length,
      cvt: filteredPracticesDiscarded.filter(
        (practice) =>
          practice.priority === 1 && practice.claimsBranchGroup === "CVT"
      ).length,
    },
    due: {
      rca: filteredPracticesDiscarded.filter(
        (practice) =>
          practice.priority === 2 && practice.claimsBranchGroup === "RCA"
      ).length,
      cvt: filteredPracticesDiscarded.filter(
        (practice) =>
          practice.priority === 2 && practice.claimsBranchGroup === "CVT"
      ).length,
    },
    new: {
      rca: filteredPracticesDiscarded.filter(
        (practice) =>
          practice.priority === 3 && practice.claimsBranchGroup === "RCA"
      ).length,
      cvt: filteredPracticesDiscarded.filter(
        (practice) =>
          practice.priority === 3 && practice.claimsBranchGroup === "CVT"
      ).length,
    },
    suspended: {
      rca: filteredPracticesDiscarded.filter(
        (practice) =>
          practice.priority === 4 && practice.claimsBranchGroup === "RCA"
      ).length,
      cvt: filteredPracticesDiscarded.filter(
        (practice) =>
          practice.priority === 4 && practice.claimsBranchGroup === "CVT"
      ).length,
    },
  };

  const result = {
    totalDailyPractices: 0,
    totalAssignedPractices: 0,
    totalDiscardedPractices: 0,
    practicesToManage: practicesDiscarded,
    communicationSummary: undefined,
    practices: filteredPracticesDiscarded,
  };

  return result;
};
export const AggregateSummaryDataD2 = (
  tenantsSummaryData: DashboardD2Summary[]
): DashboardD2Summary => {
  const allPractices = tenantsSummaryData
    .map((tenantSummary) => tenantSummary?.dashboardSummary?.practices)
    .flat();

  const totalDailyPractices = tenantsSummaryData.reduce(
    (acc, cur) => addNumbers(acc, cur.dashboardSummary?.totalDailyPractices),
    0
  );
  const totalAssignedPractices = tenantsSummaryData.reduce(
    (acc, cur) => addNumbers(acc, cur.dashboardSummary?.totalAssignedPractices),
    0
  );
  const totalDiscardedPractices = tenantsSummaryData.reduce(
    (acc, cur) =>
      addNumbers(acc, cur.dashboardSummary?.totalDiscardedPractices),
    0
  );

  const allTenantsCommunicationSummary = tenantsSummaryData
    .map(
      (t) =>
        t.dashboardSummary?.communicationSummary ||
        ([] as CommunicationsBySenderTypeAndCategories[])
    )
    .flat();

  const communicationSummary = AggregateCommunications(
    allTenantsCommunicationSummary
  );

  const totalPracticesInProgress = {
    total: tenantsSummaryData.reduce(
      (acc, cur) => addNumbers(acc, cur?.practicesInProgress?.total),
      0
    ),
    byIsDocumentalFlag: {
      documental: {
        total: tenantsSummaryData.reduce(
          (acc, cur) =>
            addNumbers(
              acc,
              cur?.practicesInProgress?.byIsDocumentalFlag?.documental?.total
            ),
          0
        ),
        assignedToInternal: tenantsSummaryData.reduce(
          (acc, cur) =>
            addNumbers(
              acc,
              cur?.practicesInProgress?.byIsDocumentalFlag?.documental
                ?.assignedToInternal
            ),
          0
        ),
        assignedToExternal: tenantsSummaryData.reduce(
          (acc, cur) =>
            addNumbers(
              acc,
              cur?.practicesInProgress?.byIsDocumentalFlag?.documental
                ?.assignedToExternal
            ),
          0
        ),
      },
      inspective: {
        total: tenantsSummaryData.reduce(
          (acc, cur) =>
            addNumbers(
              acc,
              cur?.practicesInProgress?.byIsDocumentalFlag?.inspective?.total
            ),
          0
        ),
        assignedToInternal: tenantsSummaryData.reduce(
          (acc, cur) =>
            addNumbers(
              acc,
              cur?.practicesInProgress?.byIsDocumentalFlag?.inspective
                ?.assignedToInternal
            ),
          0
        ),
        assignedToExternal: tenantsSummaryData.reduce(
          (acc, cur) =>
            addNumbers(
              acc,
              cur?.practicesInProgress?.byIsDocumentalFlag?.inspective
                ?.assignedToExternal
            ),
          0
        ),
      },
    },
    byHasAppointmentFlag: {
      yes: tenantsSummaryData.reduce(
        (acc, cur) =>
          addNumbers(acc, cur?.practicesInProgress?.byHasAppointmentFlag?.yes),
        0
      ),
      no: tenantsSummaryData.reduce(
        (acc, cur) =>
          addNumbers(acc, cur?.practicesInProgress?.byHasAppointmentFlag?.no),
        0
      ),
    },
  } as PracticesInProgressSummary;

  const totalPracticesToBeVerified = tenantsSummaryData.reduce(
    (acc, cur) => addNumbers(acc, cur?.practicesToBeVerified),
    0
  );

  const totalPracticesCancelled = tenantsSummaryData.reduce(
    (acc, cur) => addNumbers(acc, cur?.practicesCancelled),
    0
  );

  let totalUserRequestsSummary: UserRequestsSummary[] = [];

  let totaleRegisteredLetterErrors: RegisteredLetterError[] = [];

  tenantsSummaryData.forEach((tenantSummaryData) => {
    tenantSummaryData.dashboardSummary?.userRequestsSummary?.forEach(
      (userRequestSenderType) => {
        userRequestSenderType.totalsByCategory?.forEach(
          (currentTotalByCategory) => {
            let userRequestsSummary = totalUserRequestsSummary.find(
              (_) => _.senderType === userRequestSenderType.senderType
            );

            if (userRequestsSummary === undefined) {
              userRequestsSummary = {
                senderType: userRequestSenderType.senderType,
                recipientType: "",
                totalsByCategory: [{ ...currentTotalByCategory }],
              };

              totalUserRequestsSummary.push(userRequestsSummary);
            } else {
              let totalByCategory = userRequestsSummary?.totalsByCategory?.find(
                (_) => _.category === currentTotalByCategory.category
              );

              if (totalByCategory === undefined) {
                userRequestsSummary?.totalsByCategory?.push({
                  ...currentTotalByCategory,
                });
              } else {
                if (totalByCategory) {
                  totalByCategory.total =
                    totalByCategory?.total! + currentTotalByCategory.total!;
                }
              }
            }
          }
        );
      }
    );

    tenantSummaryData.dashboardSummary?.registeredLetterErrors!.forEach(
      (error) => {
        totaleRegisteredLetterErrors.push(error);
      }
    );
  });

  const aggregatedData = {
    dashboardSummary: {
      totalPractices: totalDailyPractices,
      totalDailyPractices,
      totalAssignedPractices,
      totalDiscardedPractices,
      practicesToManage: {
        total: totalDailyPractices,
        urgent: {
          rca: allPractices.filter(
            (practice) =>
              practice?.priority === 1 && practice.claimsBranchGroup === "RCA"
          ).length,
          cvt: allPractices.filter(
            (practice) =>
              practice?.priority === 1 && practice.claimsBranchGroup === "CVT"
          ).length,
        },
        due: {
          rca: allPractices.filter(
            (practice) =>
              practice?.priority === 2 && practice.claimsBranchGroup === "RCA"
          ).length,
          cvt: allPractices.filter(
            (practice) =>
              practice?.priority === 2 && practice.claimsBranchGroup === "CVT"
          ).length,
        },
        new: {
          rca: allPractices.filter(
            (practice) =>
              practice?.priority === 3 && practice.claimsBranchGroup === "RCA"
          ).length,
          cvt: allPractices.filter(
            (practice) =>
              practice?.priority === 3 && practice.claimsBranchGroup === "CVT"
          ).length,
        },
        suspended: {
          rca: allPractices.filter(
            (practice) =>
              practice?.priority === 4 && practice.claimsBranchGroup === "RCA"
          ).length,
          cvt: allPractices.filter(
            (practice) =>
              practice?.priority === 4 && practice.claimsBranchGroup === "CVT"
          ).length,
        },
      },
      userRequestsSummary: totalUserRequestsSummary,
      communicationSummary,
      practices: allPractices,
      registeredLetterErrors: totaleRegisteredLetterErrors,
    },
    practicesInProgress: totalPracticesInProgress,
    practicesToBeVerified: totalPracticesToBeVerified,
    practicesCancelled: totalPracticesCancelled,
  } as DashboardD2Summary;

  return aggregatedData;
};

export const AggregateSummaryDataD1 = (
  tenantsSummaryData: DashboardSummary[]
): DashboardSummary => {
  const allPractices = tenantsSummaryData
    .map((tenantSummary) => tenantSummary.practices)
    .flat();

  const { totalPractices, totalAssignedPractices, totalDiscardedPractices } =
    tenantsSummaryData.reduce(
      (acc, cur) => {
        return {
          totalPractices: addNumbers(cur?.totalPractices, acc.totalPractices),
          totalAssignedPractices: addNumbers(
            cur?.totalAssignedPractices,
            acc.totalAssignedPractices
          ),
          totalDiscardedPractices: addNumbers(
            cur?.totalDiscardedPractices,
            acc.totalDiscardedPractices
          ),
        };
      },
      {
        totalPractices: 0,
        totalAssignedPractices: 0,
        totalDiscardedPractices: 0,
      }
    );

  const allTenantsCommunicationSummary = tenantsSummaryData
    .map(
      (t) =>
        t.communicationSummary ||
        ([] as CommunicationsBySenderTypeAndCategories[])
    )
    .flat();
  const communicationSummary = AggregateCommunications(
    allTenantsCommunicationSummary
  );

  const totalDailyPractices = tenantsSummaryData.reduce(
    (acc, cur) => addNumbers(acc, cur?.totalDailyPractices),
    0
  );

  const aggregatedData = {
    totalPractices: totalPractices,
    totalDailyPractices: totalDailyPractices,
    totalAssignedPractices: totalAssignedPractices,
    totalDiscardedPractices: totalDiscardedPractices,
    practicesToManage: {
      total: totalPractices,
      urgent: {
        rca: allPractices.filter(
          (practice) =>
            practice?.priority === 1 && practice.claimsBranchGroup === "RCA"
        ).length,
        cvt: allPractices.filter(
          (practice) =>
            practice?.priority === 1 && practice.claimsBranchGroup === "CVT"
        ).length,
      },
      due: {
        rca: allPractices.filter(
          (practice) =>
            practice?.priority === 2 && practice.claimsBranchGroup === "RCA"
        ).length,
        cvt: allPractices.filter(
          (practice) =>
            practice?.priority === 2 && practice.claimsBranchGroup === "CVT"
        ).length,
      },
      new: {
        rca: allPractices.filter(
          (practice) =>
            practice?.priority === 3 && practice.claimsBranchGroup === "RCA"
        ).length,
        cvt: allPractices.filter(
          (practice) =>
            practice?.priority === 3 && practice.claimsBranchGroup === "CVT"
        ).length,
      },
      suspended: {
        rca: allPractices.filter(
          (practice) =>
            practice?.priority === 4 && practice.claimsBranchGroup === "RCA"
        ).length,
        cvt: allPractices.filter(
          (practice) =>
            practice?.priority === 4 && practice.claimsBranchGroup === "CVT"
        ).length,
      },
    },
    communicationSummary,
    practices: allPractices,
  } as DashboardSummary;

  const practicesToManage = {
    ...aggregatedData.practicesToManage,
    total: aggregatedData.practices?.length || 0,
  };

  let totalUserRequestsSummary: UserRequestsSummary[] = [];

  let totaleRegisteredLetterErrors: RegisteredLetterError[] = [];

  tenantsSummaryData.forEach((tenantSummaryData) => {
    tenantSummaryData.userRequestsSummary?.forEach((userRequestSenderType) => {
      userRequestSenderType.totalsByCategory?.forEach(
        (currentTotalByCategory) => {
          let userRequestsSummary = totalUserRequestsSummary.find(
            (_) => _.senderType === userRequestSenderType.senderType
          );

          if (userRequestsSummary === undefined) {
            userRequestsSummary = {
              senderType: userRequestSenderType.senderType,
              recipientType: "",
              totalsByCategory: [{ ...currentTotalByCategory }],
            };

            totalUserRequestsSummary.push(userRequestsSummary);
          } else {
            let totalByCategory = userRequestsSummary?.totalsByCategory?.find(
              (_) => _.category === currentTotalByCategory.category
            );

            if (totalByCategory === undefined) {
              userRequestsSummary?.totalsByCategory?.push({
                ...currentTotalByCategory,
              });
            } else {
              if (totalByCategory) {
                totalByCategory.total =
                  totalByCategory?.total! + currentTotalByCategory.total!;
              }
            }
          }
        }
      );
    });

    tenantSummaryData.registeredLetterErrors!.forEach((error) => {
      totaleRegisteredLetterErrors.push(error);
    });
  });

  aggregatedData.practicesToManage = practicesToManage;
  aggregatedData.userRequestsSummary = totalUserRequestsSummary;
  aggregatedData.registeredLetterErrors = totaleRegisteredLetterErrors;

  return aggregatedData;
};

const addPracticeBreakDownE2 = (
  item1: PracticeBreakDown | undefined,
  item2: PracticeBreakDown | undefined
) => ({
  appointment: {
    rca: addNumbers(item1?.appointment?.rca, item2?.appointment?.rca),
    cvt: addNumbers(item1?.appointment?.cvt, item2?.appointment?.cvt),
  } as RcaCvtPair,
  notAppointment: {
    rca: addNumbers(item1?.notAppointment?.rca, item2?.notAppointment?.rca),
    cvt: addNumbers(item1?.notAppointment?.cvt, item2?.notAppointment?.cvt),
  } as RcaCvtPair,
});

export const AggregateSummaryDataE2 = (
  tenantsSummaryData: DashboardE2Summary[]
): DashboardE2Summary => {
  const allPractices = tenantsSummaryData
    .map((tenantSummary) => tenantSummary?.practices)
    .flat()
    .filter((p) => p?.status !== "FZ");

  const totalPracticesToManage: PracticesToManageE2 = tenantsSummaryData.reduce(
    (practices, summary) => {
      return {
        total: addNumbers(practices.total, summary?.practicesToManage?.total),
        urgent: addPracticeBreakDownE2(
          practices?.urgent,
          summary?.practicesToManage?.urgent
        ),
        due: addPracticeBreakDownE2(
          practices?.due,
          summary?.practicesToManage?.due
        ),
        new: addPracticeBreakDownE2(
          practices?.new,
          summary?.practicesToManage?.new
        ),
        suspended: addPracticeBreakDownE2(
          practices?.suspended,
          summary?.practicesToManage?.suspended
        ),
      } as PracticesToManageE2;
    },
    {} as PracticesToManageE2
  );

  tenantsSummaryData
    .map((tenantSummary) => tenantSummary?.practicesToManage)
    .flat();

  const {
    totalRejected,
    totalConfirmationOfReceipt,
    totalAppointmentToBeScheduled,
    appointmentsScheduled,
    appointmentsScheduledToday,
    concludePractice,
    totalPractices,
    totalRcaPractices,
    totalCvtPractices,
    totalCommunications,
  } = tenantsSummaryData.reduce(
    (acc, cur) => ({
      totalRejected: addNumbers(acc.totalRejected, cur?.totalRejected),
      totalConfirmationOfReceipt: addNumbers(
        acc.totalConfirmationOfReceipt,
        cur?.totalConfirmationOfReceipt
      ),
      totalAppointmentToBeScheduled: addNumbers(
        acc.totalAppointmentToBeScheduled,
        cur?.totalAppointmentToBeScheduled
      ),
      appointmentsScheduled: addNumbers(
        acc.appointmentsScheduled,
        cur?.appointmentsScheduled
      ),
      appointmentsScheduledToday: addNumbers(
        acc.appointmentsScheduledToday,
        cur?.appointmentsScheduledToday
      ),
      concludePractice: addNumbers(acc.concludePractice, cur?.concludePractice),
      totalPractices: addNumbers(acc.totalPractices, cur?.totalPractices),
      totalRcaPractices: addNumbers(
        acc.totalRcaPractices,
        cur?.totalRcaPractices
      ),
      totalCvtPractices: addNumbers(
        acc.totalCvtPractices,
        cur?.totalCvtPractices
      ),
      totalCommunications: addNumbers(
        acc.totalCommunications,
        cur?.totalCommunications
      ),
    }),
    {}
  );

  const allTenantsCommunicationSummary = tenantsSummaryData
    .map(
      (t) =>
        t.communicationSummary ||
        ([] as CommunicationsBySenderTypeAndCategories[])
    )
    .flat();

  const communicationSummary = AggregateCommunications(
    allTenantsCommunicationSummary
  );

  let totalUserRequestsSummary: UserRequestsSummary[] = [];

  tenantsSummaryData.forEach((tenantSummaryData) => {
    tenantSummaryData.userRequestsSummary?.forEach((userRequestSenderType) => {
      userRequestSenderType.totalsByCategory?.forEach(
        (currentTotalByCategory) => {
          let userRequestsSummary = totalUserRequestsSummary.find(
            (_) => _.senderType === userRequestSenderType.senderType
          );

          if (userRequestsSummary === undefined) {
            userRequestsSummary = {
              senderType: userRequestSenderType.senderType,
              recipientType: "",
              totalsByCategory: [{ ...currentTotalByCategory }],
            };

            totalUserRequestsSummary.push(userRequestsSummary);
          } else {
            let totalByCategory = userRequestsSummary?.totalsByCategory?.find(
              (_) => _.category === currentTotalByCategory.category
            );

            if (totalByCategory === undefined) {
              userRequestsSummary?.totalsByCategory?.push({
                ...currentTotalByCategory,
              });
            } else {
              if (totalByCategory) {
                totalByCategory.total =
                  totalByCategory?.total! + currentTotalByCategory.total!;
              }
            }
          }
        }
      );
    });
  });

  const aggregatedData = {
    tenantId: 0,
    totalRejected,
    totalConfirmationOfReceipt,
    totalAppointmentToBeScheduled,
    appointmentsScheduled,
    appointmentsScheduledToday,
    concludePractice,
    totalPractices,
    totalRcaPractices,
    totalCvtPractices,
    practicesToManage: totalPracticesToManage,
    totalCommunications,
    communicationSummary,
    practices: allPractices,
    userRequestsSummary: totalUserRequestsSummary,
  } as DashboardE2Summary;

  return aggregatedData;
};

export const AggregateSummaryDataF1 = (
  tenantsSummaryData: DashboardF1Summary[]
): DashboardF1Summary => {
  const allPractices = tenantsSummaryData
    .map((tenantSummary) => tenantSummary?.practices)
    .flat();

  const allTenantsCommunicationSummary = tenantsSummaryData
    .map(
      (t) =>
        t.communicationSummary ||
        ([] as CommunicationsBySenderTypeAndCategories[])
    )
    .flat();

  const communicationSummary = AggregateCommunications(
    allTenantsCommunicationSummary
  );

  let totalUserRequestsSummary: UserRequestsSummary[] = [];

  tenantsSummaryData.forEach((tenantSummaryData) => {
    tenantSummaryData.userRequestsSummary?.forEach((userRequestSenderType) => {
      userRequestSenderType.totalsByCategory?.forEach(
        (currentTotalByCategory) => {
          let userRequestsSummary = totalUserRequestsSummary.find(
            (_) => _.senderType === userRequestSenderType.senderType
          );

          if (userRequestsSummary === undefined) {
            userRequestsSummary = {
              senderType: userRequestSenderType.senderType,
              recipientType: "",
              totalsByCategory: [{ ...currentTotalByCategory }],
            };

            totalUserRequestsSummary.push(userRequestsSummary);
          } else {
            let totalByCategory = userRequestsSummary?.totalsByCategory?.find(
              (_) => _.category === currentTotalByCategory.category
            );

            if (totalByCategory === undefined) {
              userRequestsSummary?.totalsByCategory?.push({
                ...currentTotalByCategory,
              });
            } else {
              if (totalByCategory) {
                totalByCategory.total =
                  totalByCategory?.total! + currentTotalByCategory.total!;
              }
            }
          }
        }
      );
    });
  });

  const aggregatedData = {
    communicationSummary,
    practices: allPractices,
    userRequestsSummary: totalUserRequestsSummary,
  } as DashboardF1Summary;

  return aggregatedData;
};

export const AggregateSummaryDataE1 = (
  tenantsSummaryData: DashboardE1Summary[]
): DashboardE1Summary => {
  const allPractices = tenantsSummaryData
    .map((tenantSummary) => tenantSummary?.practiceList)
    .flat();

  const allDocumentalCategories = tenantsSummaryData
    .map((el) => el.documentalByPriority)
    .flat()
    .map((c) => c?.category);
  const uniqueDocumentalCategories = Array.from(
    new Set(allDocumentalCategories)
  );
  const arrayDocumentalTotal = uniqueDocumentalCategories
    .map((c) =>
      tenantsSummaryData
        .map((el) => el.documentalByPriority?.filter((f) => f.category === c))
        .flat()
        .map((t) => t?.total)
    )
    .flat();
  const totalDocumentalByPriority = arrayDocumentalTotal.reduce(
    (acc, cur) => addNumbers(acc, cur),
    0
  );

  const allToBeVerifiedCategories = tenantsSummaryData
    .map((el) => el.toBeVerified)
    .flat()
    .map((c) => c?.category);
  const uniqueToBeVerifiedCategories = Array.from(
    new Set(allToBeVerifiedCategories)
  );
  const arrayToBeVerifiedTotal = uniqueToBeVerifiedCategories
    .map((c) =>
      tenantsSummaryData
        .map((el) => el.toBeVerified?.filter((f) => f.category === c))
        .flat()
        .map((t) => t?.total)
    )
    .flat();
  const totalToBeVerified = arrayToBeVerifiedTotal.reduce(
    (acc, cur) => addNumbers(acc, cur),
    0
  );

  const allNegativeToBeVerifiedCategories = tenantsSummaryData
    .map((el) => el.negativeToBeVerified)
    .flat()
    .map((c) => c?.category);
  const uniqueNegativeToBeVerifiedCategories = Array.from(
    new Set(allNegativeToBeVerifiedCategories)
  );
  const arrayNegativeToBeVerifiedTotal = uniqueNegativeToBeVerifiedCategories
    .map((c) =>
      tenantsSummaryData
        .map((el) => el.negativeToBeVerified?.filter((f) => f.category === c))
        .flat()
        .map((t) => t?.total)
    )
    .flat();
  const totalNegativeToBeVerified = arrayNegativeToBeVerifiedTotal.reduce(
    (acc, cur) => addNumbers(acc, cur),
    0
  );

  const allRejectedByCategoryCategories = tenantsSummaryData
    .map((el) => el.rejectedByCategory)
    .flat()
    .map((c) => c?.category);
  const uniqueRejectedByCategoryCategories = Array.from(
    new Set(allRejectedByCategoryCategories)
  );
  const arrayRejectedByCategoryTotal = uniqueRejectedByCategoryCategories
    .map((c) =>
      tenantsSummaryData
        .map((el) => el.rejectedByCategory?.filter((f) => f.category === c))
        .flat()
        .map((t) => t?.total)
    )
    .flat();
  const totalRejectedByCategory = arrayRejectedByCategoryTotal.reduce(
    (acc, cur) => addNumbers(acc, cur),
    0
  );

  const allToBeAssignedCategories = tenantsSummaryData
    .map((el) => el.toBeAssigned)
    .flat()
    .map((c) => c?.category);

  const uniqueToBeAssignedCategories = Array.from(
    new Set(allToBeAssignedCategories)
  );

  let array: TotalByCategory[] = [];

  tenantsSummaryData.forEach((_) => {
    _.toBeAssigned?.forEach((i) => {
      array.push(i);
    });
  });

  let totalToBeAssigned: TotalByCategory[] = [];

  uniqueToBeAssignedCategories.forEach((_) => {
    totalToBeAssigned.push({
      category: _,
      total: array
        .filter((item) => item.category === _)
        .reduce((sum, current) => sum + current.total!, 0),
    });
  });

  const allTenantsCommunicationSummary = tenantsSummaryData
    .map(
      (t) =>
        t.communicationSummary ||
        ([] as CommunicationsBySenderTypeAndCategories[])
    )
    .flat();

  const communicationSummary = AggregateCommunications(
    allTenantsCommunicationSummary
  );

  let totalUserRequestsSummary: UserRequestsSummary[] = [];

  tenantsSummaryData.forEach((tenantSummaryData) => {
    tenantSummaryData.userRequestsSummary?.forEach((userRequestSenderType) => {
      userRequestSenderType.totalsByCategory?.forEach(
        (currentTotalByCategory) => {
          let userRequestsSummary = totalUserRequestsSummary.find(
            (_) => _.senderType === userRequestSenderType.senderType
          );

          if (userRequestsSummary === undefined) {
            userRequestsSummary = {
              senderType: userRequestSenderType.senderType,
              recipientType: "",
              totalsByCategory: [{ ...currentTotalByCategory }],
            };

            totalUserRequestsSummary.push(userRequestsSummary);
          } else {
            let totalByCategory = userRequestsSummary?.totalsByCategory?.find(
              (_) => _.category === currentTotalByCategory.category
            );

            if (totalByCategory === undefined) {
              userRequestsSummary?.totalsByCategory?.push({
                ...currentTotalByCategory,
              });
            } else {
              if (totalByCategory) {
                totalByCategory.total =
                  totalByCategory?.total! + currentTotalByCategory.total!;
              }
            }
          }
        }
      );
    });
  });

  const aggregatedData = {
    documentalByPriority: totalDocumentalByPriority,
    communicationSummary: communicationSummary,
    practiceList: allPractices,
    toBeVerified: totalToBeVerified,
    negativeToBeVerified: totalNegativeToBeVerified,
    rejectedByCategory: totalRejectedByCategory,
    toBeAssigned: totalToBeAssigned,
    userRequestsSummary: totalUserRequestsSummary,
  } as DashboardE1Summary;

  return aggregatedData;
};

export const AggregateSummaryDataE4 = (
  tenantsSummaryData: DashboardE4Summary[]
): DashboardE4Summary => {
  const allPractices = tenantsSummaryData
    .map((tenantSummary) => tenantSummary?.practiceList)
    .flat();

  const allToBeVerifiedCategories = tenantsSummaryData
    .map((el) => el.toBeVerified)
    .flat()
    .map((c) => c?.category);
  const uniqueToBeVerifiedCategories = Array.from(
    new Set(allToBeVerifiedCategories)
  );
  const arrayToBeVerifiedTotal = uniqueToBeVerifiedCategories
    .map((c) =>
      tenantsSummaryData
        .map((el) => el.toBeVerified?.filter((f) => f.category === c))
        .flat()
        .map((t) => t?.total)
    )
    .flat();
  const totalToBeVerified = arrayToBeVerifiedTotal.reduce(
    (acc, cur) => addNumbers(acc, cur),
    0
  );

  const allNegativeToBeVerifiedCategories = tenantsSummaryData
    .map((el) => el.negativeToBeVerified)
    .flat()
    .map((c) => c?.category);
  const uniqueNegativeToBeVerifiedCategories = Array.from(
    new Set(allNegativeToBeVerifiedCategories)
  );
  const arrayNegativeToBeVerifiedTotal = uniqueNegativeToBeVerifiedCategories
    .map((c) =>
      tenantsSummaryData
        .map((el) => el.negativeToBeVerified?.filter((f) => f.category === c))
        .flat()
        .map((t) => t?.total)
    )
    .flat();
  const totalNegativeToBeVerified = arrayNegativeToBeVerifiedTotal.reduce(
    (acc, cur) => addNumbers(acc, cur),
    0
  );

  const allRejectedByCategoryCategories = tenantsSummaryData
    .map((el) => el.rejectedByCategory)
    .flat()
    .map((c) => c?.category);
  const uniqueRejectedByCategoryCategories = Array.from(
    new Set(allRejectedByCategoryCategories)
  );
  const arrayRejectedByCategoryTotal = uniqueRejectedByCategoryCategories
    .map((c) =>
      tenantsSummaryData
        .map((el) => el.rejectedByCategory?.filter((f) => f.category === c))
        .flat()
        .map((t) => t?.total)
    )
    .flat();
  const totalRejectedByCategory = arrayRejectedByCategoryTotal.reduce(
    (acc, cur) => addNumbers(acc, cur),
    0
  );

  const allToBeAssignedCategories = tenantsSummaryData
    .map((el) => el.toBeAssigned)
    .flat()
    .map((c) => c?.category);

  const uniqueToBeAssignedCategories = Array.from(
    new Set(allToBeAssignedCategories)
  );

  let array: TotalByCategory[] = [];

  tenantsSummaryData.forEach((_) => {
    _.toBeAssigned?.forEach((i) => {
      array.push(i);
    });
  });

  let totalToBeAssigned: TotalByCategory[] = [];

  uniqueToBeAssignedCategories.forEach((_) => {
    totalToBeAssigned.push({
      category: _,
      total: array
        .filter((item) => item.category === _)
        .reduce((sum, current) => sum + current.total!, 0),
    });
  });

  const allTenantsCommunicationSummary = tenantsSummaryData
    .map(
      (t) =>
        t.communicationSummary ||
        ([] as CommunicationsBySenderTypeAndCategories[])
    )
    .flat();

  const communicationSummary = AggregateCommunications(
    allTenantsCommunicationSummary
  );

  let totalUserRequestsSummary: UserRequestsSummary[] = [];

  tenantsSummaryData.forEach((tenantSummaryData) => {
    tenantSummaryData.userRequestsSummary?.forEach((userRequestSenderType) => {
      userRequestSenderType.totalsByCategory?.forEach(
        (currentTotalByCategory) => {
          let userRequestsSummary = totalUserRequestsSummary.find(
            (_) => _.senderType === userRequestSenderType.senderType
          );

          if (userRequestsSummary === undefined) {
            userRequestsSummary = {
              senderType: userRequestSenderType.senderType,
              recipientType: "",
              totalsByCategory: [{ ...currentTotalByCategory }],
            };

            totalUserRequestsSummary.push(userRequestsSummary);
          } else {
            let totalByCategory = userRequestsSummary?.totalsByCategory?.find(
              (_) => _.category === currentTotalByCategory.category
            );

            if (totalByCategory === undefined) {
              userRequestsSummary?.totalsByCategory?.push({
                ...currentTotalByCategory,
              });
            } else {
              if (totalByCategory) {
                totalByCategory.total =
                  totalByCategory?.total! + currentTotalByCategory.total!;
              }
            }
          }
        }
      );
    });
  });

  const aggregatedData = {
    communicationSummary: communicationSummary,
    practiceList: allPractices,
    toBeVerified: totalToBeVerified,
    negativeToBeVerified: totalNegativeToBeVerified,
    rejectedByCategory: totalRejectedByCategory,
    toBeAssigned: totalToBeAssigned,
    userRequestsSummary: totalUserRequestsSummary,
  } as DashboardE1Summary;

  return aggregatedData;
};

export const AggregateSummaryDataG1 = (
  tenantsSummaryData: AccountingSummary[]
): AccountingSummary => {
  const aggregatedData: AccountingSummary = {
    managedTotal: 0,
    toBeAccountedTotal: 0,
    reportList: [],
    practiceList: [],
  };

  tenantsSummaryData.forEach((_) => {
    aggregatedData.managedTotal! += _.managedTotal!;
    aggregatedData.toBeAccountedTotal! += _.toBeAccountedTotal!;
    aggregatedData.reportList?.push(..._.reportList!);
    aggregatedData.practiceList?.push(..._.practiceList!);
  });

  return aggregatedData;
};

export const AggregateSummaryDataG2 = (
  tenantsSummaryData: AccountingSummary[]
): AccountingSummary => {
  const aggregatedData: AccountingSummary = {
    managedTotal: 0,
    toBeAccountedTotal: 0,
    reportList: [],
    practiceList: [],
  };

  tenantsSummaryData.forEach((_) => {
    aggregatedData.managedTotal! += _.managedTotal!;
    aggregatedData.toBeAccountedTotal! += _.toBeAccountedTotal!;
    aggregatedData.reportList?.push(..._.reportList!);
    aggregatedData.practiceList?.push(..._.practiceList!);
  });

  return aggregatedData;
};
