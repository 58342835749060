import styled from "styled-components";
import { WidgetWrapper } from "../../../../style/DashbordWidgetWrappers";
import WidgetHeader from "../../../Widgets/WidgetHeader";
import { useTranslation } from "react-i18next";
import { Users } from "../../../../redux/apiSpecifications/apiFesf";
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import { useEffect, useMemo, useState } from "react";
import { UserItem } from "../UserItem";
import { CellWrapper, ClickableCellWrapper } from "../style/CellWrapper";

const AccountsToManageListWrapper = styled(WidgetWrapper)`
    flex-direction: column;
    align-items: stretch;
    max-height: 2.5em;
    transition: max-height 1s;
    &.open {
        max-height: 100em;
    }
`;

interface IAccountsToManageListProps {
    users: Users;
    onEditUserContact: (userItem: UserItem) => void;
    onEditUserConfig: (userItem: UserItem) => void;
}

const AccountsToManageList = (props: IAccountsToManageListProps) => {
    const { t } = useTranslation();

    const { users, onEditUserContact, onEditUserConfig } = props;

    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 5,
    });

    const [isOpen, setIsOpen] = useState(users!.length > 0);

    useEffect(() => {
        setIsOpen(users!.length > 0);
    }, [users]);

    const data = useMemo(
        () =>
            users?.map((user) => {
                return {
                    id: user.id,
                    idSubject: user.idSubject,
                    firstname: user.firstname,
                    surname: user.surname,
                    account: user.userName,
                    email: user.email,
                    contact: user.firstname + " " + user.surname,
                    roles: user.roles,
                    rolesText: user.roles
                        ?.map((r) => t(`role-description-${r.roleCode!}`))
                        .join(", "),
                    tenants: user.tenants,
                    tenantsText: user.tenants
                        ?.map((t) => t.companyName)
                        .join(", "),
                    isActive: user.isActive,
                    userConfigLists: {
                        allRoles: user.roles,
                        allTenants: user.tenants,
                    },
                } as UserItem;
            }) || [],
        [users]
    );

    const handleSelectContanct = (row: UserItem) => {
        onEditUserContact(row);
    };

    const handleSelectConfig = (row: UserItem) => {
        onEditUserConfig(row);
    };

    const columns = useMemo<MRT_ColumnDef<UserItem>[]>(
        () => [
            {
                accessorKey: "account",
                header: t("accounts-to-manage-list-account-column-header"),
                size: 100,
                Cell: ({ row }) => (
                    <CellWrapper>{row.original.account}</CellWrapper>
                ),
            },
            {
                accessorKey: "contact",
                header: t("accounts-to-manage-list-contact-column-header"),
                size: 200,
                Cell: ({ row }) => (
                    <ClickableCellWrapper
                        onClick={() => handleSelectContanct(row.original)}
                    >
                        {row.original.idSubject !== null ? row.original.contact : t("accounts-to-manage-list-complete-registry-placeholder")}
                    </ClickableCellWrapper>
                ),
            },
            {
                accessorKey: "roles",
                header: t("accounts-to-manage-list-roles-column-header"),
                size: 400,
                Cell: ({ row }) => (
                    <ClickableCellWrapper
                        onClick={() => handleSelectConfig(row.original)}
                    >
                        {row.original.rolesText !== ""
                            ? row.original.rolesText
                            : t("accounts-to-manage-list-select-roles-placeholder")}
                    </ClickableCellWrapper>
                ),
            },
            {
                accessorKey: "tenants",
                header: t(
                    "accounts-to-manage-list-customer-management-column-header"
                ),
                size: 400,
                maxSize: 9001,
                Cell: ({ row }) => (
                    <ClickableCellWrapper
                        onClick={() => handleSelectConfig(row.original)}
                    >
                        {row.original.tenantsText !== ""
                            ? row.original.tenantsText
                            : t("accounts-to-manage-list-select-tenants-placeholder")}
                    </ClickableCellWrapper>
                ),
            },
        ],
        [users]
    );

    const handleOnOpen = () => setIsOpen(true);
    const handleOnClose = () => setIsOpen(false);

    return (
        <AccountsToManageListWrapper
            className={`${isOpen ? "open" : ""}`}
            // gridArea={gridArea}
        >
            <WidgetHeader
                title={t("accounts-to-manage-title")}
                isActive={false}
                // filterReset={handleFilterReset}
                onOpen={handleOnOpen}
                onClose={handleOnClose}
                isOpen={isOpen}
            />
            <div className="body">
                {data.length > 0 && (
                    <MaterialReactTable
                        muiTablePaperProps={{ style: { width: "100%" } }}
                        columns={columns}
                        data={data}
                        enableDensityToggle={false}
                        enableColumnOrdering
                        muiTableBodyCellProps={{
                            sx: {
                                textAlign: "left",
                            },
                        }}
                        onPaginationChange={setPagination}
                        state={{ pagination }}
                        autoResetPageIndex={false}
                    />
                )}
            </div>
        </AccountsToManageListWrapper>
    );
};

export default AccountsToManageList;
