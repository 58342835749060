import {
    BaseQueryFn,
    createApi,
    FetchArgs,
    fetchBaseQuery,
    FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { baseUrlCrud } from "../../../config/const";
import { RootState } from "../../store";
import { checkToken } from "./baseApi";

const baseQuery = fetchBaseQuery({
    baseUrl: baseUrlCrud,
    prepareHeaders: (headers, { getState }) => {
        const { authorization } = (getState() as RootState)?.user;

        if (authorization !== "") {
            headers.set("Authorization", authorization);
        }

        return headers;
    },
});

const baseQueryWithReauth: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args, api, extraOptions) => {
    await checkToken(api);

    let result = await baseQuery(args, api, extraOptions);

    return result;
};

export const baseSplitApiCrud = createApi({
    reducerPath: "apiCrud",
    baseQuery: baseQueryWithReauth, // fetchBaseQuery({ baseUrl: baseUrlCrud }),
    endpoints: () => ({}),
});
