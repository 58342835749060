import { useTranslation } from "react-i18next";
import { WidgetWrapper } from "../../../../style/containers";
import styled from "styled-components";
import { useMemo } from "react";
import { useExpertizeScoreContext } from "../ExpertizeScoreContext";
import ConfigureClusterInput from "./ConfigureClusterInput";
import { ConfigureClusterActionType } from "../types";
import { Alert, Popconfirm } from "antd";
import { ButtonConfirm } from "../../../Layout/Buttons";

export const ConfigureClusterWrapper = styled(WidgetWrapper)`
    flex-direction: column;
    align-items: unset;
    padding: 2em;
    gap: 2em;

    .buttons-wrapper {
        width: 100%;
        margin-top: 2em;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1em;
    }
`;

const ConfigureCluster = () => {
    const { t } = useTranslation();

    const {
        appraiserScoreClusterList,
        provincesOptions,
        isFormChanged,
        onAddDistrictToCluster,
        onRemoveDistrictToCluster,
        onSubmitConfigureClusterForm,
    } = useExpertizeScoreContext();

    const availableProvincesOptions = useMemo(() => {
        const assignedProvinces: number[] = [];

        appraiserScoreClusterList?.forEach((cluster) => {
            assignedProvinces.push(...cluster.districts!);
        });

        return provincesOptions?.filter((province) => {
            return !assignedProvinces.includes(Number(province.value));
        });
    }, [appraiserScoreClusterList]);

    const handleOnAction = (
        action: ConfigureClusterActionType,
        payload: any
    ) => {
        if (action === "add-district") {
            onAddDistrictToCluster(
                Number(payload.clusterId),
                Number(payload.districtId)
            );
        } else if (action === "remove-district") {
            onRemoveDistrictToCluster(
                Number(payload.clusterId),
                Number(payload.districtId)
            );
        }
    };

    const handleOnSubmitConfigureClusterForm = () => {
        onSubmitConfigureClusterForm();
    };

    return (
        <ConfigureClusterWrapper>
            <Alert
                message={
                    availableProvincesOptions!.length > 0
                        ? availableProvincesOptions
                              ?.map((province) => province.label)
                              .join(", ")
                        : t(
                              "expert-score-configure-cluster-all-provinces-assigned"
                          )
                }
                type="info"
            />

            {appraiserScoreClusterList?.map((cluster) => (
                <ConfigureClusterInput
                    key={cluster.id}
                    cluster={cluster}
                    availableProvincesOptions={availableProvincesOptions}
                    onAction={handleOnAction}
                />
            ))}

            {/* <pre>{JSON.stringify(availableProvincesOptions, null, 2)}</pre> */}

            <div className="buttons-wrapper">
                <Popconfirm
                    placement="top"
                    icon={null}
                    title={t("configuration-panel-button-save-confirm")!}
                    onConfirm={handleOnSubmitConfigureClusterForm}
                    okText={t("switch-yes")!}
                    cancelText={t("switch-no")!}
                >
                    <ButtonConfirm disabled={!isFormChanged || availableProvincesOptions?.length! > 0} onClick={() => {}}>
                        {t("button-save")!}
                    </ButtonConfirm>
                </Popconfirm>
            </div>
        </ConfigureClusterWrapper>
    );
};

export default ConfigureCluster;
