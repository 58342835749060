import jwtDecode from "jwt-decode";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import moment from "moment";
import { getLoginRequest } from "../config/azureConfig";
import { setAuthorization } from "../redux/features/userSlice";
import { useMsal } from "@azure/msal-react";

export const useAuthorization = () => {
  const { authorization } = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();
  const { instance, accounts } = useMsal();

  const getAuthorization = async () => {
    // this is to disable auth on unit test
    //if (window.location.href.includes("localhost")) return authorization;

    let _authorization = authorization.replace("Bearer ", "");
    let decoded: any = jwtDecode(_authorization);

    const now = moment();
    const expriring = moment(decoded.exp * 1000).add(1, "minutes");

    const isBefore = expriring.isBefore(now);

    if (isBefore) {
      // refresh token
      const loginRequest = getLoginRequest();
      let response: any;

      try {
        response = await instance.acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        });
      } catch (ex) {
        console.log("exeption  acquireTokenSilent");
        response = await instance.acquireTokenPopup({
          ...loginRequest,
          account: accounts[0],
        });
      }

      if (!response) {
        return authorization;
      }

      const newAuthorization = `Bearer ${response.accessToken}`;

      dispatch(setAuthorization(newAuthorization));

      return newAuthorization;
    }
    return authorization;
  };

  return { getAuthorization };
};
