import { useDispatch } from "react-redux";
import {
    NotificationState,
    NotificationsActions,
} from "../redux/features/notificationsSlice";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { SerializedError } from "@reduxjs/toolkit";

const instanceOf = <T>(value: any, fieldName: string): value is T =>
    fieldName in value;

type ProblemDetails = {
    title: string;
};

export const useNotifications = () => {
    const dispatch = useDispatch();

    const displayNotification = (notification: NotificationState) => {
        dispatch(NotificationsActions.addNotification(notification));
    };

    const displaySuccess = (message: string) => {
        dispatch(
            NotificationsActions.addNotification({
                type: "success",
                message: message,
            })
        );
    }

    const displayFetchBaseQueryErrorNotification = (
        error: FetchBaseQueryError | SerializedError
    ) => {
        let message: string = "Error";

        const isFetchBaseQueryError = (
            error: any
        ): error is FetchBaseQueryError =>
            (error as FetchBaseQueryError).status !== undefined;

        if (isFetchBaseQueryError(error)) {
            if (error.status === "FETCH_ERROR") {
                message = error["error"];
            } else if (["400", "404"].includes(error.status.toString())) {
                if (Array.isArray(error["data"])) {
                    message = error["data"].map((m) => m).join(" | ");
                } else if (typeof error["data"] === "string") {
                    message = error["data"];
                } else {
                    const data = error.data! as ProblemDetails;

                    message = data.title;
                }
            } else {
                console.log(error);
            }
        } else {
            // Gestire il type SerializedError
        }

        dispatch(
            NotificationsActions.addNotification({
                type: "error",
                message: message,
            })
        );
    };

    return {
        displayNotification,
        displaySuccess,
        displayFetchBaseQueryErrorNotification,
    } as const;
};
