import { PracticeHistory } from "../../../../../../redux/apiSpecifications/apiCrud";
import AdzUploadedDescription from "./AdzUploadedDescription";
import AppointmentCreatedDescription from "./AppointmentCreatedDescription";
import AppointmentModifiedDescription from "./AppointmentModifiedDescription";
import AppraisalLocationModifiedDescription from "./AppraisalLocationModifiedDescription";
import AppraiserAssignedDescription from "./AppraiserAssignedDescription";
import AppraiserChangedDescription from "./AppraiserChangedDescription";
import AssignmentAcceptedDescription from "./AssignmentAcceptedDescription";
import EmailDeliveredDescription from "./EmailDeliveredDescription";
import EmailSentDescription from "./EmailSentDescription";
import PracticeCancelledDescription from "./PracticeCancelledDescription";
import PracticeFeeModifiedDescription from "./PracticeFeeModifiedDescription";
import PracticeModifiedDescription from "./PracticeModifiedDescription";
import PracticeRateModifiedDescription from "./PracticeRateModifiedDescription";
import PracticeRejectedDescription from "./PracticeRejectedDescription";
import PracticeRejectedForOutOfCoverageDescription from "./PracticeRejectedForOutOfCoverageDescription";
import PracticeRestoredDescription from "./PracticeRestoredDescription";
import PracticeSuspendedDescription from "./PracticeSuspendedDescription";
import RegisteredLetterReceiptDescription from "./RegisteredLetterReceiptDescription";
import RegisteredLetterSentDescription from "./RegisteredLetterSentDescription";
import SmsSentDescription from "./SmsSentDescription";

interface IModifyDescriptionProps {
  item: PracticeHistory;
}

const ModifyDescription = ({ item }: IModifyDescriptionProps) => {
  switch (item.modifyType) {
    case "PRMOD":
      return (
        <PracticeModifiedDescription
          modifyDetailsJson={item.modifyDetailsJson!}
        />
      );
    case "PRASS":
      return (
        <AppraiserAssignedDescription
          modifyDetailsJson={item.modifyDetailsJson!}
        />
      );
    case "APMOD":
      return (
        <AppraiserChangedDescription
          modifyDetailsJson={item.modifyDetailsJson!}
        />
      );
    case "APPFIX":
      return (
        <AppointmentCreatedDescription
          modifyDetailsJson={item.modifyDetailsJson!}
        />
      );
    case "APPMOD":
      return (
        <AppointmentModifiedDescription
          modifyDetailsJson={item.modifyDetailsJson!}
        />
      );
    case "PRACC":
      return (
        <AssignmentAcceptedDescription
          modifyDetailsJson={item.modifyDetailsJson!}
        />
      );
    case "PRRES":
      return <PracticeRestoredDescription />;
    case "PRCAN":
      return <PracticeCancelledDescription
        modifyDetailsJson={item.modifyDetailsJson!}
      />;
    case "PRSUS":
      return <PracticeSuspendedDescription
        modifyDetailsJson={item.modifyDetailsJson!}
      />;
    case "PRREJ":
      return (
        <PracticeRejectedDescription
          modifyDetailsJson={item.modifyDetailsJson!}
        />
      );
    case "PRREFZ": //"PRREJ":
      return (
        <PracticeRejectedForOutOfCoverageDescription
          modifyDetailsJson={item.modifyDetailsJson!}
        />
      );
    case "INVSMS":
      return <SmsSentDescription modifyDetailsJson={item.modifyDetailsJson!} />;
    case "INVEM":
      return (
        <EmailSentDescription modifyDetailsJson={item.modifyDetailsJson!} />
      );
    case "CONSEM":
      return (
        <EmailDeliveredDescription modifyDetailsJson={item.modifyDetailsJson!} />
      );
    case "INVRAC":
      return (
        <RegisteredLetterSentDescription
          modifyDetailsJson={item.modifyDetailsJson!}
        />
      );
    case "RICINV":
      return (
        <RegisteredLetterReceiptDescription
          modifyDetailsJson={item.modifyDetailsJson!}
        />
      );
    case "PRFEM":
      return (
        <PracticeFeeModifiedDescription
          modifyDetailsJson={item.modifyDetailsJson!}
        />
      );
    case "PRRAM":
      return (
        <PracticeRateModifiedDescription
          modifyDetailsJson={item.modifyDetailsJson!}
        />
      );
    case "ADZ":
      return (
        <AdzUploadedDescription modifyDetailsJson={item.modifyDetailsJson!} />
      );
     case "APLOC":
      return (
        <AppraisalLocationModifiedDescription modifyDetailsJson={item.modifyDetailsJson!} />
      ) 
    case "PRMIG":
      return <>{item.modifyDescription}</>;
  }

  return <span>????</span>;
};

export default ModifyDescription;
