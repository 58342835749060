import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useExpertizeScoreContext } from "../ExpertizeScoreContext";
import ExpertizerScoreInputText from "../ExpertizerScoreInputText";

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    .inner-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .header {
            text-align: center;
            font-size: 1em;
            text-transform: uppercase;
            letter-spacing: 2px;
            margin-bottom: 2em;
        }

        td {
            .square-text {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding-right: 1em;
            }
        }
    }

    .input-percentage {
        input::-webkit-inner-spin-button,
        input::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        input {
            -moz-appearance: textfield;
            padding-right: 1.8em;
        }
    }
`;

const ThresoldsWrapper = styled(Wrapper)`
    margin-top: 50px;
    margin-right: 50px;

    .label {
        padding-right: 1em;
    }

    .value {
        width: 6em;
        height: 2em;
        border-radius: 5px;
        margin-right: 1em;
        margin-left: 6em;
    }
`;

const AppraiserScoreConfigForm = () => {
    const { t } = useTranslation();

    const { selectedClusterConfig, onChangeSelectedClusterConfig } =
        useExpertizeScoreContext();

    const values = useMemo(() => {
        return {
            confirmationReactivity: {
                Threshold:
                    selectedClusterConfig?.confirmationReactivityThreshold || 0,
                Weight:
                    selectedClusterConfig?.confirmationReactivityWeight || 0,
            },
            accessReactivity: {
                Threshold:
                    selectedClusterConfig?.accessReactivityThreshold || 0,
                Weight: selectedClusterConfig?.accessReactivityWeight || 0,
            },
            accessesCount: {
                Threshold: selectedClusterConfig?.accessesCountThreshold || 0,
                Weight: selectedClusterConfig?.accessesCountWeight || 0,
            },
            noAccessesCount: {
                Threshold: selectedClusterConfig?.noAccessesCountThreshold || 0,
                Weight: selectedClusterConfig?.noAccessesCountWeight || 0,
            },
            agreementsCount: {
                Threshold: selectedClusterConfig?.agreementsCountThreshold || 0,
                Weight: selectedClusterConfig?.agreementsCountWeight || 0,
            },
            rejectedCount: {
                Threshold: selectedClusterConfig?.rejectedCountThreshold || 0,
                Weight: selectedClusterConfig?.rejectedCountWeight || 0,
            },
            accountingVerifyCount: {
                Threshold:
                    selectedClusterConfig?.accountingVerifyCountThreshold || 0,
                Weight: selectedClusterConfig?.accountingVerifyCountWeight || 0,
            },
            meanAppraisalaverageAmount: {
                Threshold:
                    selectedClusterConfig?.meanAppraisalaverageAmountThreshold ||
                    0,
                Weight:
                    selectedClusterConfig?.meanAppraisalaverageAmountWeight ||
                    0,
            },
        };
    }, [selectedClusterConfig]);

    return (
        <ThresoldsWrapper>
            <div className="content">
                <table>
                    <thead>
                        <tr>
                            <th></th>
                            <th>
                                {t("expert-score-configure-threshold-label")}
                            </th>
                            <th>{t("expert-score-configure-weight-label")}</th>
                        </tr>
                    </thead>

                    <tbody>
                        {[
                            "confirmationReactivity",
                            "accessReactivity",
                            "accessesCount",
                            "noAccessesCount",
                            "agreementsCount",
                            "rejectedCount",
                            "accountingVerifyCount",
                            "meanAppraisalaverageAmount",
                        ].map((clusterConfigType) => (
                            <tr key={clusterConfigType}>
                                <td>
                                    <div className="label">
                                        {t(
                                            `expert-score-configure-threshold-weight-field-${clusterConfigType}-label`
                                        )}
                                    </div>
                                </td>

                                <td className="value">
                                    <ExpertizerScoreInputText
                                        value={
                                            values[
                                                clusterConfigType as keyof typeof values
                                            ]?.Threshold
                                        }
                                        onChange={(value) =>
                                            onChangeSelectedClusterConfig(
                                                `${clusterConfigType}Threshold`,
                                                value
                                            )
                                        }
                                    />
                                </td>

                                <td className="value">
                                    <ExpertizerScoreInputText
                                        value={
                                            values[
                                                clusterConfigType as keyof typeof values
                                            ]?.Weight
                                        }
                                        onChange={(value) =>
                                            onChangeSelectedClusterConfig(
                                                `${clusterConfigType}Weight`,
                                                value
                                            )
                                        }
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </ThresoldsWrapper>
    );
};

export default AppraiserScoreConfigForm;
