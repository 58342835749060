import { useDispatch, useSelector } from "react-redux";
import AppraiserDetailsPresentational, {
  LoadingStatus,
} from "./AppraiserDetailsPresentational";
import { useEffect, useState } from "react";
import { RootState } from "../../../../redux/store";
import {
  AddAppraiserApiResponse,
  AppraiserManagementData,
  AppraiserUser,
  DistrictAppraiserStatus,
  DistrictAppraiserStatuses,
  SetCoverageAreasApiResponse,
  useAddAppraiserMutation,
  useLazyGetAppraiserManagementQuery,
  useLazyGetAppraiserQuery,
  useSetAppraiserStatusOnDistrictsMutation,
  useSetCoverageAreasMutation,
  useUpdateAppraiserRatesMutation,
} from "../../../../redux/apiSpecifications/apiFesf";
import { useAuthorization } from "../../../../hooks/useAuthorization";
import {
  AppraiserRateData,
  addAppraiser,
  clearPendingChanges,
  forceUpdate,
  setAppraiserDefaultStatusValues,
  setAppraiserError,
  setAppraiserMainData,
  setAppraiserManagementData,
  setAppraiserRatesData,
  setAppraiserStatus,
} from "../../../../redux/features/appraisersSlice";
import moment from "moment";
import { DateFormat, maxDate } from "../../../../config/const";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { SerializedError } from "@reduxjs/toolkit";

interface IAppraiserDetailsProps {
  idAppraiser: number;
  defaultTab?: string;
  tabKey?: string;
  defaultAgendaDate?: string;
  defaultAgendaStatus?: string;
  defaultAgendaReason?: string;
  defaultAgendaProvince?: string;
}

const AppraiserDetails = (props: IAppraiserDetailsProps) => {
  const {
    idAppraiser,
    defaultTab,
    defaultAgendaDate,
    defaultAgendaStatus,
    defaultAgendaReason,
    defaultAgendaProvince,
  } = props;

  const { getAuthorization } = useAuthorization();

  const dispatch = useDispatch();

  const activeRole = useSelector((state: RootState) => state.user.activeRole);
  const appraiserData = useSelector((state: RootState) =>
    state.appraisers.appraisers.find((a) => a.id === props.idAppraiser)
  );

  const [loadAppraiser] = useLazyGetAppraiserQuery();
  const [saveAppraiser] = useAddAppraiserMutation();
  const [saveCoverage] = useSetCoverageAreasMutation();
  const [saveTenantRates] = useUpdateAppraiserRatesMutation();
  const [saveAppraiserStatuses] = useSetAppraiserStatusOnDistrictsMutation();
  const [loadAppraiserManagement] = useLazyGetAppraiserManagementQuery();

  useEffect(() => {
    if (!idAppraiser || idAppraiser < 1) return;

    dispatch(
      addAppraiser({
        id: idAppraiser,
        defaultSettings: {
          tab: defaultTab,
          agendaDate: defaultAgendaDate,
          agendaStatus: defaultAgendaStatus,
          agendaReason: defaultAgendaReason,
          agendaProvince: defaultAgendaProvince,
        },
      })
    );

    (async () => {
      const response = await loadAppraiser({
        authorization: await getAuthorization(),
        id: idAppraiser,
      });

      if (response.isSuccess && response.data) {
        dispatch(
          setAppraiserMainData({ appraiser: response.data as AppraiserUser })
        );

        // clear eventual fiscal code auto set to changed
        setTimeout(() => {
          dispatch(clearPendingChanges(idAppraiser));
        }, 200);
      } else {
        dispatch(
          setAppraiserError({
            id: idAppraiser,
            message: "Errore caricamento dettagli perito",
          })
        );
      }
    })();
  }, [idAppraiser]);

  const handleSaveSubject = async () => {
    // we need to strip negative id before to send appraiser
    const updatedAppraiser = appraiserData?.mainData;
    const newAppraiser: AppraiserUser = {
      ...updatedAppraiser,
      managementTools: updatedAppraiser?.managementTools?.map((mt) => ({
        ...mt,
        id: mt?.id && mt.id > 0 ? mt.id : 0,
      })),
      trustCompanies: updatedAppraiser?.trustCompanies?.map((tc) => ({
        ...tc,
        id: tc?.id && tc.id > 0 ? tc.id : 0,
      })),
      availableManagementTools: [],
      availableTrustCompanies: [],
    };

    const response = await saveAppraiser({
      authorization: await getAuthorization(),
      appraiserUser: newAppraiser,
    });

    const _appraiser = (
      response as {
        data: AddAppraiserApiResponse;
      }
    ).data as AppraiserUser;

    if (_appraiser && _appraiser?.id) {
      dispatch(
        setAppraiserMainData({ appraiser: _appraiser, isUpdate: false })
      );
      dispatch(clearPendingChanges(idAppraiser));
    } else {
      dispatch(
        setAppraiserError({
          id: idAppraiser,
          message: "Errore caricamento dettagli perito",
        })
      );
    }
  };

  const handleSaveManagement = async () => {
    const managementData = appraiserData?.managementData;

    const districtCoverages = managementData?.coverage!.districtCoverages?.map(
      (dc) => ({
        districtCode: dc.districtCode,
        districtMaxPracticeCountDaily: dc.districtMaxPracticeCountDaily,
        districtMaxPracticeCountMonthly: dc.districtMaxPracticeCountMonthly,
        hasAreaCoverageParams: dc.hasAreaCoverageParams,
        validityStartDate: dc.validityStartDate || moment().format(DateFormat),
        validityEndDate: dc.validityEndDate || maxDate,
        isTest: dc.isTest,
        areaCoverages: !dc.hasAreaCoverageParams
          ? []
          : dc.areaCoverages?.map((ac) => ({
              ...ac,
              areaMaxPracticeCountDaily: undefined,
              areaMaxPracticeCountMonthly: undefined,
            })),
      })
    );

    const response = await saveCoverage({
      authorization: await getAuthorization(),
      coverage: {
        coverageTypeCodes: managementData?.coverage?.coverageTypeCodes,
        districtCoverages,
        coverageLeaveLossPerc: managementData?.coverage?.coverageLeaveLossPerc,
      },
      id: idAppraiser!,
    });

    const correctResponse = response as {
      data: SetCoverageAreasApiResponse;
    };

    if (correctResponse.data) {
      dispatch(clearPendingChanges(idAppraiser));

      const responseAppraiser = await loadAppraiserManagement({
        authorization: await getAuthorization(),
        id: idAppraiser,
      });

      if (responseAppraiser.isSuccess && responseAppraiser.data) {
        dispatch(
          setAppraiserManagementData({
            id: idAppraiser,
            data: responseAppraiser.data as AppraiserManagementData,
            isUpdate: false,
          })
        );
      } else {
        dispatch(
          setAppraiserError({
            id: idAppraiser || 0,
            message: "Errore caricamento dettagli perito",
          })
        );
      }

      dispatch(
        setAppraiserStatus({
          id: idAppraiser,
          status: "idle",
        })
      );
    } else {
      dispatch(
        setAppraiserError({
          id: idAppraiser || 0,
          message: "Errore caricamento dettagli perito",
        })
      );
    }
  };

  const handleSaveRates = async () => {
    const appraiserRates = appraiserData?.ratesData;

    const tenantsFiltered =
      appraiserRates
        ?.filter((ar) => ar.modified)
        .map((ar) => ar.appraiserData)
        .flat() || [];

    const tenantsToSave = tenantsFiltered.map((appraiserRate) => {
      return {
        ...appraiserRate,
        ratesByType: appraiserRate.ratesByType?.map(
          (rate) => {
            return {
              ...rate,
              practiceTypeBaseRateAmount: Number(
                rate.practiceTypeBaseRateAmount
                    ?.toString()
                    .replace(",", ".")
              ),
            }
          }
        )
      };
    });

    const result = await saveTenantRates({
      authorization: await getAuthorization(),
      appraiserRates: {
        appraiserRates: tenantsToSave,
      },
      id: idAppraiser!,
    });

    if (result) {
      // reset modified
      const newAppraiserRates = appraiserRates?.map(
        (ar) =>
          ({
            modified: false,
            appraiserData: ar.appraiserData,
          } as AppraiserRateData)
      );
      dispatch(
        setAppraiserRatesData({
          id: appraiserData?.id!,
          ratesData: newAppraiserRates || [],
          isUpdate: false,
        })
      );
      dispatch(clearPendingChanges(idAppraiser));
    } else {
      dispatch(
        setAppraiserError({
          id: idAppraiser || 0,
          message: "Errore salvataggio tariffe",
        })
      );
    }
  };

  const handleSaveAgenda = async () => {
    const defaultStatusValues = appraiserData?.myAgenda?.defaultStatusValues;

    const { provinces, range, status, statusCode } =
      appraiserData?.myAgenda?.activeStatusValues || {};

    dispatch(setAppraiserStatus({ id: idAppraiser!, status: "saving" }));

    let type = "";
    let suspensionReason = "";
    let originalSuspensionReason = "";
    let appraiserStatusCode = "";

    switch (status) {
      case "add-leave":
        type = "N";
        suspensionReason = "FGE";
        appraiserStatusCode = "F";
        break;
      case "modify-leave":
        type = "U";
        suspensionReason = "FGE";
        appraiserStatusCode = "F";
        break;
      case "remove-leave":
        type = "D";
        suspensionReason = "";
        appraiserStatusCode = "F";
        break;
      case "add-saint":
        type = "N";
        suspensionReason = "FSP";
        appraiserStatusCode = "F";
        break;
      case "modify-saint":
        type = "U";
        suspensionReason = "FSP";
        appraiserStatusCode = "F";
        break;
      case "remove-saint":
        type = "D";
        suspensionReason = "FSP";
        appraiserStatusCode = "F";
        break;
      case "add-illness":
        type = "N";
        suspensionReason = "SMP";
        appraiserStatusCode = "S";
        break;
      case "modify-illness":
        type = "U";
        suspensionReason = "SMP";
        appraiserStatusCode = "S";
        break;
      case "remove-illnes":
        type = "D";
        suspensionReason = "";
        appraiserStatusCode = "S";
        break;
      case "add-suspension":
        type = "N";
        originalSuspensionReason = defaultStatusValues?.statusCode!;
        suspensionReason = statusCode!;
        appraiserStatusCode = "S";
        break;
      case "modify-suspension":
        type = "U";
        appraiserStatusCode = "S";
        originalSuspensionReason = defaultStatusValues?.statusCode!;
        suspensionReason = statusCode!;
        break;
      case "remove-suspension":
        type = "D";
        appraiserStatusCode = "S";

        originalSuspensionReason = defaultStatusValues?.statusCode!;
        suspensionReason = statusCode!;
        break;
      case "add-blocked":
        type = "N";
        suspensionReason = statusCode!;
        appraiserStatusCode = "B";
        break;
      case "modify-blocked":
        type = "U";
        suspensionReason = statusCode!;
        appraiserStatusCode = "B";
        break;
      case "remove-blocked":
        type = "D";
        suspensionReason = statusCode!;
        appraiserStatusCode = "B";
        break;
      case "add-closed":
        type = "N";
        appraiserStatusCode = "C";
        break;
      case "modify-closed":
        type = "U";
        appraiserStatusCode = "C";
        break;
      case "remove-closed":
        type = "D";
        appraiserStatusCode = "C";
        break;
    }

    let originalDistrictAppraiserStatuses: DistrictAppraiserStatus[] = [];
    let newDistrictAppraiserStatuses: DistrictAppraiserStatus[] = [];

    // if its a change (modify or remove) we need to send the original values
    if (
      status === "modify-leave" ||
      status === "modify-saint" ||
      status === "modify-illness" ||
      status === "modify-suspension" ||
      status === "modify-blocked" ||
      status === "modify-closed" ||
      status === "remove-leave" ||
      status === "remove-saint" ||
      status === "remove-illnes" ||
      status === "remove-suspension" ||
      status === "remove-blocked" ||
      status === "remove-closed"
    ) {
      const validityEndDate = ["modify-closed", "remove-closed"].includes(
        status || ""
      )
        ? ""
        : defaultStatusValues?.range?.[1];

      originalDistrictAppraiserStatuses =
        defaultStatusValues!.provinces?.map(
          (p) =>
            ({
              districtCode: p,
              validityStartDate: defaultStatusValues?.range?.[0] || "",
              validityEndDate, //: defaultStatusValues?.range?.[1] || "",
              suspensionReason: originalSuspensionReason,
              appraiserStatusCode,
              description: "",
            } as DistrictAppraiserStatus)
        ) || [];
    }

    // we have the new values only if its not a remove action
    if (
      status !== "remove-leave" &&
      status !== "remove-saint" &&
      status !== "remove-illnes" &&
      status !== "remove-suspension" &&
      status !== "remove-blocked" &&
      status !== "remove-closed"
    ) {
      newDistrictAppraiserStatuses = provinces!
        .filter((p) => p.selected)
        .map((p) => ({
          districtCode: p.districtCode?.toUpperCase() || "",
          validityStartDate: range![0] || "",
          validityEndDate:
            suspensionReason === "FSP" ? range![0] || "" : range![1] || "",
          suspensionReason,
          reasonCode: statusCode,
          appraiserStatusCode,
          description: "",
        }));
    }

    const districtAppraiserStatuses: DistrictAppraiserStatuses = {
      originalDistrictAppraiserStatuses,
      newDistrictAppraiserStatuses,
    };

    const response = await saveAppraiserStatuses({
      authorization: await getAuthorization(),
      appraiserid: idAppraiser!,
      districtAppraiserStatuses,
    });

    const errorResponse = response as {
      error: FetchBaseQueryError | SerializedError;
    };

    if (errorResponse?.error) {
      dispatch(
        setAppraiserError({
          id: idAppraiser!,
          message: "Si è verificato un errore durante il salvataggio",
        })
      );
    } else {
      dispatch(clearPendingChanges(idAppraiser));
    }
  };

  const handleSave = async () => {
    dispatch(
      setAppraiserStatus({
        id: idAppraiser,
        status: "saving",
      })
    );

    for (var pendingChange of appraiserData?.pendingChanges || []) {
      if (pendingChange === "subject") {
        await handleSaveSubject();
      }

      if (pendingChange === "management") {
        await handleSaveManagement();
        dispatch(forceUpdate({ id: idAppraiser, agenda: true }));
      }

      if (pendingChange === "rates") {
        await handleSaveRates();
      }

      if (pendingChange === "agenda") {
        await handleSaveAgenda();

        dispatch(forceUpdate({ id: idAppraiser, agenda: true }));
      }
    }

    //dispatch(clearPendingChanges(idAppraiser));
    dispatch(
      setAppraiserDefaultStatusValues({
        id: idAppraiser,
        defaultProvinces: [],
        selectedDayDate: appraiserData?.myAgenda?.selectedDay?.date || "",
        status: "",
        statusCode: "",
        hasPendingChanges: false,
        pendingChanges: "",
      })
    );
  };

  return (
    <AppraiserDetailsPresentational
      idAppraiser={idAppraiser}
      onSave={handleSave}
    />
  );
};

export default AppraiserDetails;
